import React, { useEffect, useRef, useState } from 'react'
import "../Styles/CustomerData.css";
import CustomerDetail from './CustomerDetail'
import NavMain from '../components/NavMain';
import ToastComponent from '../components/ToastComponent';
// import NavMain from '../components/NavMain';

function CustomerMasterData() {  
  const[masterData ,setMasterdata]=useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const toastRef = useRef();
  // const fetchCustomerSearch = async (searchText) => {
  //   try {
  //     const response = await fetch(`https://fn-hampi-clientsvc-dev.azurewebsites.net/api/GetAllClient?SearchByText=${searchText}`);
  //     if (response.ok) {
  //       const data = await response.json();
  //       const filteredData = data.filter(employee => employee.Name.toLowerCase().includes(searchText.toLowerCase()));
  //       setMasterdata(filteredData);
  //     } else {
  //       console.error('Failed to fetch data');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  

  // useEffect(() => {
  //   fetchCustomerSearch(searchQuery);
  // }, [searchQuery]);

  // useEffect(() => {
  //   setFilteredData(masterData?.filter(employee => 
  //     employee.Name.toLowerCase().includes(searchQuery.toLowerCase())
  //   ));
  // }, [searchQuery, masterData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <div className='customer-page'>
                      <ToastComponent ref={toastRef} timeout={4000} />
        <CustomerDetail setFilteredData={setFilteredData} />
    
    </div>
  )
}

export default CustomerMasterData
