// import * as React from "react";
import React, { useContext, useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import { useNavigate } from "react-router-dom";
const options = ["Add Holidays", "Remove Holidays"];

const ITEM_HEIGHT = 48;

export default function HolidayMenu({
  handleEditClick,
  openModal,
  handleDeleteHoliday,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [selectedHolidaysToDelete, setSelectedHolidaysToDelete] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { token } = useContext(AuthContext);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const handleMenuClick = (option) => {
    handleClose(); // Close the menu when an option is clicked
    if (option === "Add Holidays") {
      openModal(); // Open the modal for adding holidays
    } else if (option === "Remove Holidays") {
      handleEditClick(); // Trigger the edit click action
    }
  };

  const handleCheckboxChange = (index) => {
    if (selectedHolidaysToDelete.includes(index)) {
      setSelectedHolidaysToDelete(
        selectedHolidaysToDelete.filter((item) => item !== index)
      );
    } else {
      setSelectedHolidaysToDelete([...selectedHolidaysToDelete, index]);
    }
  };

  const handleDeleteSelectedHolidays = () => {
    // Call the API to delete selected holidays
    selectedHolidaysToDelete.forEach((index) => {
      const holidayIdToDelete = holidays[index].id; // Assuming the holiday object has an 'id' property
      // Call the API to delete the holiday with the specified ID
      fetch(`${clientApiUrl}/DeleteHoliday?id=${holidayIdToDelete}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response?.status === 401) {
            navigate("/");
          }
          if (response.ok) {
            // Remove the deleted holiday from the holidays array
            setHolidays(holidays.filter((holiday, i) => i !== index));
            // Clear the selectedHolidaysToDelete array
            setSelectedHolidaysToDelete([]);
          } else {
            console.error("Failed to delete holiday");
          }
        })
        .catch((error) => {
          console.error("Error deleting holiday:", error);
        });
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="holiday-menu-button"
        aria-controls={open ? "holiday-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="holiday-menu"
        MenuListProps={{
          "aria-labelledby": "holiday-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuClick(option)}
            style={{ display: "flex", alignItems: "center" }}
          >
            {option === "Add Holidays" ? (
              <AddIcon sx={{ color: "#50145A", mr: 1 }} />
            ) : (
              <DeleteIcon sx={{ color: "#50145A", mr: 1 }} />
            )}
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
