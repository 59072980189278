
import {useState }from 'react'
import logo123 from "../Images/Logo.png"
import "../Styles/Modal.css";
import "../Styles/SignUpPage.css";
import BasicInfo from "../components/BasicInfo";
import Roles from "../components/Roles";
import "../Styles/common.css";
import Organisation from "../components/Organisation";
import "../Styles/Nav.css";
import   { Bell, DotOutline, FileArrowUp ,PlusCircle ,CaretDown ,CaretDoubleRight ,Briefcase, User, UsersThree, XCircle }  from "@phosphor-icons/react";
 
function Nav() {
  const [, setSelectedFile] = useState(null);
  const [isPrimaryDropdownOpen, setIsPrimaryDropdownOpen] = useState(false);
  const [isSecondaryDropdownOpen, setIsSecondaryDropdownOpen] = useState(false);
  const [isthirdDropdownOpen, setIsthirdDropdownOpen] = useState(false);
  const [isbellDropdownOpen, setIsbellDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const togglePrimaryDropdown = () => {
    setIsPrimaryDropdownOpen(!isPrimaryDropdownOpen);
    setIsSecondaryDropdownOpen(false); 
  };
 
  const toggleSecondaryDropdown = () => {
    setIsSecondaryDropdownOpen(!isSecondaryDropdownOpen);
  };
  const togglethirdDropdown = () => {
    setIsthirdDropdownOpen(!isthirdDropdownOpen);
  };
  const togglebellDropdown = () => {
    setIsbellDropdownOpen(!isbellDropdownOpen);
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
   
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleNextButtonClick = () => {
    const nextTab = activeTab + 1;
    setActiveTab(nextTab >= 0 && nextTab <= 2 ? nextTab : 0);
  };
  return (
    <main className='main'>
 
      <nav className='flex justify-between px-9 items-center  h-22 '>
        <section className='flex items-center gap-5 relative'>
 
       
          <img className=' w-16 h-8 ' loading="lazy" src={logo123} alt="" />
 
          <div  className='flex justify-between  gap-3  px-6 items-center py-6'>
            <div className=' text-purple-950 font-bold text-l font-poppins sm:text-lg'>USER SETUP & ROLES  </div>
           
            <button onClick={togglePrimaryDropdown}>
              <CaretDown size={16} color="#3d025f" weight="fill" />
            </button>    
         
          </div>
          {isPrimaryDropdownOpen && (
            <div className='absolute top-3/4 left-72 mt-1 w-72 bg-white rounded-lg shadow-lg'>
              <ul>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>Time Report</li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointerw-full whitespace-no-wrap  flex' onClick={toggleSecondaryDropdown}>
                  Admin Activities  
                  <div className='caret_d'> <CaretDoubleRight size={15} /></div>
                 
                 
                  {isSecondaryDropdownOpen && (
                      <ul className='Second-Cd'>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>Customer Master Data</li>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>View Force Data Import</li>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>User Setup and Roles</li>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>Activities And Tasks</li>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>Budget And Forecast</li>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>Period Open/Close</li>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>Month Roll Forward</li>
                        <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>Lookup Information</li>
                       
                      </ul>
                    )}
                  </li>
 
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointerw-full whitespace-no-wrap'>Reporting Activities</li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointerw-full whitespace-no-wrap'>Voucher  Processing</li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointerw-full whitespace-no-wrap'>Management Processing</li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointerw-full whitespace-no-wrap'>Quality Control Framework</li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointerw-full whitespace-no-wrap'>Annual Accounts And Tax Reports</li>
                <li className='px-4 py-2 hover:bg-gray-100 cursor-pointerw-full whitespace-no-wrap'>Log Out</li>
              </ul>
            </div>
          )} 
        </section>
        <section className='flex items-center '>
        <div className='rr'>
        <Bell size={18} className='bell1' color="#3d025f" weight="fill" />
        <DotOutline size={52} className='be' color="#0fdf0c"  weight="fill" />
        <button onClick={togglebellDropdown}>
              <CaretDown size={16} color="#3d025f" weight="fill" />
          </button>  
        </div>
        {isbellDropdownOpen && (
        <div className="bell-cd">
          <ul>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
          </ul>
        </div>
      )}
          <div className='flex justify-between gap-2 px-6 items-center py-6'>
          <User size={20} color="#3d025f" weight="fill" />
          <div className=' w-24 h-6  text-purple-950 text-l font-semibold font-poppins'>Param Reddy</div>
         <button onClick={togglethirdDropdown}>
              <CaretDown size={16} color="#3d025f" weight="fill" />
          </button>  
          </div>
         {isthirdDropdownOpen && (
        <div className="third-cd">
          <ul>
            <li className="py-2 px-4 hover:bg-gray-100">Notification</li>
            <li className="py-2 px-4 hover:bg-gray-100">Settings</li>
            <li className="py-2 px-4 hover:bg-gray-100">Log Out</li>
          </ul>
        </div>
      )}
    </section>  
      </nav>
       <nav className='flex justify-between px-8 pb-4 items-center  border-b shadow-lg'>
        <section className='flex items-center gap-6'>
         <div className='titel11'>
        <input type="text" className='title111' id="searchInput" placeholder= "Search by employee name/Employee ID" />
        </div>
   </section>
  <section className='flex items-center gap-4'>  
        <button className='font-poppins w-36 h-9 flex items-center justify-center mx-auto text-white rounded-lg bg-purple-950'>
          <PlusCircle size={20} color="#FFFFFF" weight="fill" className="mr-2" />
          <span className="inline-block align-middle aaa"  onClick={openModal}>ADD NEW TEAM</span>
        </button>  
  <label htmlFor="file-upload" className='   font-poppins   w-52 h-9 flex items-center justify-center mx-auto text-white rounded-lg cursor-pointer'>
          <div className='eee'>
          <FileArrowUp size={20} color="#3d025f" weight='fill' className="mr-2" />
          <div><span className="inline-block align-middle title0  ">SELECT FILES TO UPLOAD</span></div>
          </div>
      <input
        id="file-upload"
        type="file"
        className="hidden"
        onChange={handleFileChange}
      />
    </label>
        </section>  
        {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="modalLayout">
            <div className="modalLayout_contact">
              <div className="modalLayoutMain_contact">
                <div className="modalStyling">
                  <div className="flex justify-between">
                    <div className="font-poppins text-lg font-semibold leading-36 text-left text-custom-purple">
                      {" "}
                      + ADD NEW USER
                    </div>
                    <XCircle
                      onClick={closeModal}
                      size={32}
                      weight="fill"
                      className="text-rgba-243-237-255"
                    />
                  </div>
                  <div class="flex gap-4 mt-3 flex-col ">
                    <div className="modal_buttons_section">
                      <button
                        className={`modal_btns ${
                          activeTab === 0 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(0)}
                      >
                        <User weight="fill" />
                        BASIC Information
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(1)}
                      >
                        <Briefcase weight="fill" />
                        Roles
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(2)}
                      >
                        <UsersThree weight="fill" />
                        ORGANISATION
                      </button>
                    </div>

                    <div>
                      {activeTab === 0 && (
                        <div>
                          <BasicInfo />
                        </div>
                      )}
                      {activeTab === 1 && (
                        <div>
                          <Roles />
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div>
                          <Organisation />
                        </div>
                      )}
                    </div>
                    <div className="next_btn_field">
                      <button className="common_btn"    onClick={handleNextButtonClick}>Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      </nav>
 </main>
     
  )
}
 
export default Nav

 