import React, { useState } from 'react'
import "../Styles/UserDetailsHead.css"
import "../Styles/LookUp.css"
import {  PencilSimpleLine ,Briefcase,UsersThree,User, ArrowLeft} from '@phosphor-icons/react'
import BasicInfo from './BasicInfo';
import Roles from './Roles';
import Organisation from './Organisation';
import BasicInfoEdit from './BasicInfoEdit';
import RolesEdit from './RolesEdit';
import OrganisationEdit from './OrganisationEdit';
import LookUpActivity from '../pages/LookUpActivity';
import LookUpDepartment from '../pages/LookUpDepartment';
import LookUpCalender from '../pages/LookUpCalender';

function LookUpTabs(props) {
  
    const [activeTab, setActiveTab] = useState(0);
  
    const handleTabClick = (index) => {
      setActiveTab(index);
    };
    const handleNextButtonClick = () => {
      const nextTab = activeTab + 1;
      setActiveTab(nextTab >= 0 && nextTab <= 2 ? nextTab : 0);
    };
  return (
    <div className='Look_head'>
        <div className='LookUp_head_container'>
    
        <div className='userDetials_head_field'>
                    <div className="modal_buttons_section">
                      <button
                        className={`modal_btns ${
                          activeTab === 0 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(0)}
                      >
                     ACTIVITIES AND TASKS
                    
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(1)}
                      >
                       DEPARTMENTS
                        
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(2)}
                      >
                       WORK DAY CALENDER
                        
                      </button>
                    </div>

                        </div>
       </div>
        
        <div className='tabs_container_lookup'>
                      {activeTab === 0 && (
                        <div >
                       <LookUpActivity/>
                        </div>
                      )}
                      {activeTab === 1 && (
                        <div >
            
                          <LookUpDepartment/>
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div >
                         
                          <LookUpCalender />
                        </div>
                      )}

                    {/* <div className="next_btn_field">
                      <button className="common_btn"    onClick={handleNextButtonClick}>Next</button>
                    </div> */}
                    </div>
                    
        
    </div>
  )
}

export default LookUpTabs