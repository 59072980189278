import React from 'react'
import "../Styles/UserSetupAndRoles.css";
import HampiNavbar1 from '../components/HampiNavbar1'
// import CustomerTeam1 from './CustomerTeam1'
import HampiNavbarNew from '../components/HampiNavbarNew';
import MappedMenu from '../components/MappedMenu';

function UserSetupAndRoles() {
  return (
    <div className='Register-M'>
      <HampiNavbarNew />
      <HampiNavbar1  />
      {/* <MappedMenu /> */}
    </div>
  )
}

export default UserSetupAndRoles
