import React from "react";
import Mergenew from "../pages/Mergenew";
import ViewForceSupUser from "./ViewForceSupUser";

function ViewForceMain() {
  const userRole = "Manager";

  let layoutComponent;
  if (
    userRole === "Manager" ||
    userRole === "ProcessLead" ||
    userRole === "DepartmentLead"
  ) {
    layoutComponent = <ViewForceSupUser />;
  } else if (userRole === "Admin") {
    layoutComponent = <Mergenew />;
  } else if (userRole === "consultant") {
    layoutComponent = <Mergenew />;
  }

  return (
    <>
      {layoutComponent}
    </>
  );
}

export default ViewForceMain;
