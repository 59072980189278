import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
/*Css file */
import "../Styles/Dashboard.css";
/*Components */
import GraphPage from "./GraphPage";
import MultipleCircularProgress from "../components/MultipleCircularProgress";
import DashboardGraph3 from "./Dashboard_graph_3";
import DashboardGraph4 from "./Dashboard_graph_4";
import DashboardGraph5 from "./Dashboard_graph_5";
import DashboardGraph6 from "./Dashboard_graph_6";
import DashboardGraph7 from "./Dashboard_graph_7";
import DashBoard8 from "./DashBoard8";
import DashboardTopCopm from "./DashboardTopCopm";
function DashBoardQaLayout() {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const options = ["Option 1", "Option 2", "Option 3"];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };
  return (
    <div>
      <div className="main-dda">
        <DashboardTopCopm />
        <div className="main-dd-row">
          <div className="main-dd-row1">
            <div>{<GraphPage />}</div>
            <div>{<MultipleCircularProgress />}</div>
            <div></div>
          </div>
          <div className="main-dd-row2">
            <div>{<DashboardGraph4 />}</div>
            <div>{<DashboardGraph5 />}</div>
            <div>{<DashboardGraph7 />}</div>
          </div>
        </div>
        {<DashBoard8 />}
      </div>
    </div>
  );
}

export default DashBoardQaLayout;
