import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import "../Styles/Capacity.css";
import NavSub from "../components/NavSub";
import { AuthContext } from "../context/AuthContext";
import { addMonths, format } from "date-fns";
import { UserContext } from "../context/UserContext";
import ToastComponent from "../components/ToastComponent";
import { userApiUrl, taskApiUrl } from "../utils/GetUrl";
function Capacity(props) {
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const [userMap, setUserMap] = useState({});
  const { token, logout } = useContext(AuthContext);
  const { userData, usersList } = useContext(AuthContext);
  const [teamEmids, setTeamEmids] = useState([]);
  const { teamNames } = useContext(UserContext);
  const [apiData, setApiData] = useState([]);
  const [budgetByEmpId, setBudgetByEmpId] = useState({});
  const [forecast, setForecast] = useState([]);
  useEffect(() => {
    const map = userData?.reduce((acc, user) => {
      acc[user.EM_ID] = user;
      return acc;
    }, {});
    setUserMap(map);
  }, [userData]);
  const getSubteams = () => {
    const subteams = {};
    employeeData
      .filter(
        (emp) =>
          !selectedTeam || parseFloat(emp.STM_Team) === parseFloat(selectedTeam)
      )
      .forEach((emp) => {
        const subteamId = emp.STM_Subteam;
        const subteamName =
          teamNames.find((team) => team.id.toString() === subteamId)?.name ||
          "Unknown";
        if (!subteams[subteamName]) {
          subteams[subteamName] = [];
        }
        subteams[subteamName].push(emp);
      });
    return subteams;
  };
  const subteams = getSubteams();
  const handleChange = (event) => {
    const selectedTeamId = event.target.value;
    setSelectedTeam(selectedTeamId);
    const selectedTeamData = teamNames.find(
      (team) => team.id === selectedTeamId
    );
    setTeamEmids(selectedTeamData ? selectedTeamData.emids : usersList);
  };
  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);
    setSelectedYear(newYear);
  };
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from({ length: currentYear - 1999 }, (_, i) =>
      (currentYear - i).toString()
    );
    setYears(yearList);
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      const emids = employeeData
        .filter((emp) => emp.STM_Team.toString() === selectedTeam.toString())
        .map((emp) => emp.EM_ID);
      setTeamEmids(emids);
    }
  }, [selectedTeam, employeeData]);
  const processApiData = () => {
    const monthToEmpMap = {};

    apiData.forEach((item) => {
      const month = parseInt(item.CreatedMonth, 10);
      const empId = item.AssginedTo;
      const totalTime = parseInt(item.TotalTime, 10);

      if (!monthToEmpMap[empId]) {
        monthToEmpMap[empId] = {};
      }

      if (!monthToEmpMap[empId][month]) {
        monthToEmpMap[empId][month] = 0;
      }

      monthToEmpMap[empId][month] += totalTime;
    });

    return monthToEmpMap;
  };
  const monthToEmpMap = processApiData();
  const toastRef = useRef();
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from({ length: currentYear - 1999 }, (_, i) =>
      (currentYear - i).toString()
    );
    setYears(yearList);
  }, []);
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(`${userApiUrl}/GetTeams?`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const groupedTeams = data.reduce((acc, team) => {
          const id = team.SuperTeamId;
          if (!acc[id]) {
            acc[id] = [];
          }
          acc[id].push(team);
          return acc;
        }, {});

        const filtered = Object.values(groupedTeams);
        setTeamsData(filtered);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token) {
      fetchTeams();
    }
  }, [token]);
  useEffect(() => {
    const getMonthName = (monthNumber) => {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[monthNumber - 1];
    };
    const monthNames = Array.from({ length: 12 }, (_, index) => {
      const month = index + 1;
      return `${getMonthName(month)} ${selectedYear}`;
    });
    setMonths(monthNames);
  }, [selectedYear]);

  const fetchData = async () => {
    try {
      if (!token) return;
      const formattedEmids = teamEmids.map((emid) => `'${emid}'`).join(",");
      const url = `${taskApiUrl}/GetCapacityUtilisation?AssginedToEmids=${encodeURIComponent(
        formattedEmids
      )}&CreatedYear=${encodeURIComponent(selectedYear)}`;
      const response = await fetch(url, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setApiData(data);
      const budgetMap = data.reduce((acc, item) => {
        acc[item.AssginedTo] =
          (acc[item.AssginedTo] || 0) + parseFloat(item.TotalTime);
        return acc;
      }, {});
      setBudgetByEmpId(budgetMap);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, selectedYear, teamEmids]);

  useEffect(() => {
    if (!teamEmids?.length > 0) {
      setTeamEmids(usersList);
    }
  }, []);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await fetch(`${userApiUrl}/GetAllEmp`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        setEmployeeData(data);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [token]);

  useEffect(() => {
    const fetchBudgetData = async () => {
      try {
        const response = await fetch(
          `${userApiUrl}/GetLatestBudget?Year=${selectedYear}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (!response.ok) {
          throw new Error("Failed to fetch budget data");
        }
        const data = await response.json();
        //console.log("Fetched Budget Data:", data);
        setBudgetData(data);
      } catch (error) {
        console.error("Error fetching budget data:", error);
      }
    };
    fetchBudgetData(selectedYear);
  }, [token, selectedYear]);

  const Forecastmonth = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const getMonthName = (date) => format(date, "MMM");
  const getForecastMonths = (year) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Decs",
    ];
    const forecastMonths = [];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth();

    for (let i = 0; i < 6; i++) {
      const monthIndex = (currentMonthIndex + i) % 12;
      const yearOffset = Math.floor((currentMonthIndex + i) / 12);
      const forecastYear = parseInt(year) + yearOffset; // Ensure year is a number
      forecastMonths.push(`${monthNames[monthIndex]} ${forecastYear}`);
    }

    return forecastMonths;
  };

  const forecastMonths = getForecastMonths();
  const [displayForecastMonths, setDisplayForecastMonths] = useState([]);
  const calculateForecasts = useCallback((budgetData, monthToEmpMap, year) => {
    const currentMonthIndex = new Date().getMonth();
    const months = getForecastMonths(year).map((month) =>
      month.split(" ")[0].toLowerCase()
    );
    const forecastData = budgetData.map((budget) => {
      const empId = budget.empid;
      const previousMonthsCount = 4;
      let totalCapacity = 0;
      for (let i = 0; i < previousMonthsCount; i++) {
        const monthIndex = (currentMonthIndex - i - 1 + 12) % 12;
        totalCapacity += monthToEmpMap[empId]?.[monthIndex] || 0;
      }
      const averageCapacity = totalCapacity / previousMonthsCount;

      const forecasts = months.slice(0, 6).map((month, index) => {
        const monthIndex = (currentMonthIndex + index) % 12;
        const monthBudget = budget[month] || 0;

        if (index === 0) {
          const prevMonthIndex = (currentMonthIndex - 0 + 12) % 12;
          const prevMonthCapacity =
            monthToEmpMap[empId]?.[prevMonthIndex + 1] || 0;
          //console.log(prevMonthCapacity,prevMonthIndex);
          //console.log(monthToEmpMap[empId],[prevMonthIndex + 1]);
          return (monthBudget - prevMonthCapacity).toFixed(2);
        } else {
          return (monthBudget - averageCapacity).toFixed(2);
        }
      });

      return {
        empId,
        username: budget.username,
        forecast: forecasts.map((val) => parseFloat(val)),
      };
    });
    setForecast((prevForecast) => {
      const isEqual =
        JSON.stringify(prevForecast) === JSON.stringify(forecastData);
      return isEqual ? prevForecast : forecastData;
    });
  }, []);

  useEffect(() => {
    if (
      budgetData.length &&
      Object.keys(monthToEmpMap).length > 0 &&
      selectedYear
    ) {
      //console.log(budgetData, monthToEmpMap, selectedYear)
      calculateForecasts(budgetData, monthToEmpMap, selectedYear);
    }
  }, [budgetData, monthToEmpMap, selectedYear]);

  const getTotalBudget = (empid) => {
    const employeeBudget = budgetData.find((item) => item.empid === empid);
    return employeeBudget ? employeeBudget.ytd : 0;
  };
  const calculateCapacityUtilizationYTD = (
    actualHoursYTD,
    budgetedHoursYTD
  ) => {
    if (actualHoursYTD === 0) return 0;
    return (actualHoursYTD / budgetedHoursYTD) * 100;
  };
  const calculateTotalHours = (empId) => {
    const months = Object.keys(monthToEmpMap[empId] || {});
    return months.reduce(
      (sum, month) => sum + monthToEmpMap[empId][parseInt(month, 10)],
      0
    );
  };
  const getActualHoursMTD = (empId, monthToEmpMap) => {
    const currentMonthIndex = new Date().getMonth();
    if (currentMonthIndex >= 0 && currentMonthIndex < months.length) {
      return monthToEmpMap[empId]?.[currentMonthIndex + 1] || 0;
    }
    return 0;
  };
  const getBudgetedHoursMTD = (empid, budgetData) => {
    const currentMonthIndex = new Date().getMonth();
    const monthKeys = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "decs",
    ];
    const currentMonthKey = monthKeys[currentMonthIndex];
    const employeeBudget = budgetData?.find((item) => item.empid === empid);
    return employeeBudget ? employeeBudget[currentMonthKey] || 0 : 0;
  };
  const calculateCapacityUtilizationMTD = (actualHoursMTD, budgetHoursMTD) => {
    return budgetHoursMTD === 0 ? 0 : (actualHoursMTD / budgetHoursMTD) * 100;
  };
  const getNextSixMonths = (startMonth) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Decs",
    ];
    const currentYear = new Date().getFullYear();
    const currentMonthIndex = months.indexOf(startMonth);
    let result = [];
    let monthIndex = currentMonthIndex;
    let year = currentYear;

    for (let i = 0; i < 6; i++) {
      result.push(`${months[monthIndex]} ${year}`);
      monthIndex++;
      if (monthIndex > 11) {
        monthIndex = 0; // Reset to January
        year++; // Move to the next year
      }
    }

    return result;
  };

  useEffect(() => {
    const updatedForecastMonths = getForecastMonths(selectedYear);
    setDisplayForecastMonths(updatedForecastMonths);
  }, [selectedYear]);

  useEffect(() => {
    if (employeeData.length > 0 && selectedTeam === null) {
      getSubteams();
    }
  }, [employeeData]);
  return (
    <div>
      <NavSub />
      <div className="Capacity_layout">
        <ToastComponent ref={toastRef} timeout={4000} />

        <div className="Capacity_head">
          <select
            id="team-select"
            value={selectedTeam || ""}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select a team
            </option>
            {teamsData[0]
              ?.filter((team) => team?.status === true && team?.type === "T")
              ?.map((data) => (
                <option key={data.id} value={data.id}>
                  {data.name}
                </option>
              ))}
          </select>
          {/* <select
            id="year-select"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select> */}
        </div>
        <div>
          <div className="capacity_table_container">
            {Object.keys(subteams).map((subteamName) => {
              let totalYTDHours = 0;
              let totalBudgetYTD = 0;
              let totalUtilizationYTD = 0;
              let totalMTDHours = 0;
              let totalBudgetMTD = 0;
              let totalUtilizationMTD = 0;
              const monthlyTotals = new Array(months.length).fill(0);
              const forecastTotals = new Array(6).fill(0);

              return (
                <div key={subteamName}>
                  <div className="Capacity_head">
                    <p className="team_name">{subteamName}</p>
                  </div>
                  <div className="scroll_container">
                    <div className="ytd_hours_container">
                      <div
                        className="ytd_hours_title"
                        style={{ minWidth: "300px" }}
                      >
                        YTD Hours
                      </div>
                      <div
                        className="ytd_hours_title"
                        style={{ minWidth: "300px" }}
                      >
                        MTD Hours
                      </div>
                      <div
                        className="ytd_hours_title"
                        style={{ minWidth: "570px" }}
                      >
                        Forecast Capacity for Next 6 Months
                      </div>
                    </div>
                    <div className="cc-tt123">
                      <div className="capacity_table_column">Members</div>
                      <div className="capacity_table_column">Team</div>
                      {months.map((month, index) => (
                        <div className="capacity_table_column" key={index}>
                          {month}
                        </div>
                      ))}
                      <div className="capacity_table_column">
                        Actual Hours YTD
                      </div>
                      <div className="capacity_table_column">
                        Budget Hours YTD
                      </div>
                      <div className="capacity_table_column">
                        Utilization YTD
                      </div>
                      <div className="capacity_table_column2">
                        Actual Hours MTD
                      </div>
                      <div className="capacity_table_column2">
                        Budget Hours MTD
                      </div>
                      <div className="capacity_table_column2">
                        Utilization MTD
                      </div>
                      {displayForecastMonths.map((month, index) => (
                        <div className="capacity_table_column" key={index}>
                          AVAILABLE CAPACITY -{month}
                        </div>
                      ))}
                    </div>
                    {subteams[subteamName].map((emp, index) => {
                      const empId = emp.EM_ID;
                      const empid = emp.EM_EmpID;
                      const totalHours = calculateTotalHours(empId);
                      const totalBudget = getTotalBudget(empid);
                      const utilizationYTD = calculateCapacityUtilizationYTD(
                        totalHours,
                        totalBudget
                      );
                      const actualHoursMTD = getActualHoursMTD(
                        empId,
                        monthToEmpMap
                      );
                      const budgetHoursMTD = getBudgetedHoursMTD(
                        empid,
                        budgetData
                      );
                      const utilizationMTD = calculateCapacityUtilizationMTD(
                        actualHoursMTD,
                        budgetHoursMTD
                      );

                      totalYTDHours += totalHours;
                      totalBudgetYTD += totalBudget;
                      totalUtilizationYTD += utilizationYTD;
                      totalMTDHours += actualHoursMTD;
                      totalBudgetMTD += budgetHoursMTD;
                      totalUtilizationMTD += utilizationMTD;

                      months.forEach((_, index) => {
                        monthlyTotals[index] +=
                          monthToEmpMap[empId]?.[index + 1] || 0;
                      });

                      const forecastCapacity =
                        forecast.find((f) => f.empId === empid)?.forecast ||
                        new Array(6).fill(0);
                      forecastCapacity.forEach((value, index) => {
                        forecastTotals[index] += value;
                      });
                      const backgroundColor =
                        index % 2 === 0 ? "#f0f0f0" : "#ffffff"; // Gray for even rows, white for odd rows
                      return (
                        <div
                          key={empId}
                          className="capacity_table_row"
                          style={{ backgroundColor }}
                        >
                          <div className="capacity_table_column">
                            {emp.EM_FirstName} {emp.EM_LastName}
                          </div>
                          <div className="capacity_table_column">
                            {subteamName}
                          </div>
                          {months.map((month, index) => (
                            <div className="capacity_table_column" key={index}>
                              {monthToEmpMap[empId]?.[index + 1] || 0}
                            </div>
                          ))}
                          <div className="capacity_table_column">
                            {totalHours}
                          </div>
                          <div className="capacity_table_column">
                            {totalBudget}
                          </div>
                          <div className="capacity_table_column">
                            {utilizationYTD.toFixed(2)}%
                          </div>
                          <div className="capacity_table_column2">
                            {actualHoursMTD}
                          </div>
                          <div className="capacity_table_column2">
                            {budgetHoursMTD}
                          </div>
                          <div className="capacity_table_column2">
                            {utilizationMTD.toFixed(2)}%
                          </div>

                          {forecastCapacity.map((value, index) => (
                            <div className="capacity_table_column" key={index}>
                              {value}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                    <div className="cc-tt">
                      <div
                        className="capacity_table_column"
                        style={{ minWidth: "120px" }}
                      >
                        Totals
                      </div>
                      <div
                        className="capacity_table_column"
                        style={{ minWidth: "120px" }}
                      ></div>
                      {monthlyTotals.map((total, index) => (
                        <div className="capacity_table_column" key={index}>
                          {total || 0}
                        </div>
                      ))}
                      <div className="capacity_table_column">
                        {totalYTDHours || 0}
                      </div>
                      <div className="capacity_table_column">
                        {totalBudgetYTD || 0}
                      </div>
                      <div className="capacity_table_column">
                        {totalUtilizationYTD.toFixed(2) || 0}
                      </div>
                      <div className="capacity_table_column2">
                        {totalMTDHours || 0}
                      </div>
                      <div className="capacity_table_column2">
                        {totalBudgetMTD.toFixed(1) || 0}
                      </div>
                      <div className="capacity_table_column2">
                        {totalUtilizationMTD.toFixed(1) || 0}
                      </div>
                      {forecastTotals.map((total, index) => (
                        <div className="capacity_table_column" key={index}>
                          {total || 0}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Capacity;
