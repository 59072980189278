import React from 'react'
import "../Styles/MasterDataTab3Content.css";

function MasterDataTab3Content() {
  return (
    <div>
         <div className='main-div'>
        <div className='mainD'>
         <div className='ContainerD'>
            <div className='title-tab4'>
            TIME ESTIMATION OF VOUCHERS (Min)
            </div>
               <div className='main-user-infoD'>
                    <div className='Customer-detail-row1'>

                         <div className='user-textfield-fields'>
                             <div className='labelM'>Supplier Invoice - Non EHF</div>
                             <div><input className='input123D' type='text' id="fullName" name="customername" placeholder=''/></div> 
                         </div>
                         <div className='user-textfield-fields'>
                             <div className='labelM'>Supplier Invoice - EHF</div>
                             <div><input className='input123D' type='text' id="" name='' placeholder=""/></div>
                         </div>
                         <div className='user-textfield-fields'>
                             <div className='labelM'>Supplier Invoice - EHF(VIC,AI)</div>
                             <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                         </div>
                     </div>
                     <div className='Customer-detail-row2'>

                        <div className='user-textfield-fields'>
                            <div className='labelM'>Payment register</div>
                            <div><input className='input123D' type='text' id="" name='' /></div>
                        </div>
                        <div className='user-textfield-fields'>
                            <div className='labelM'>Advanced voucher/ Manuel journal</div>
                            <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                        </div>
                        <div className='user-textfield-fields'>
                            <div className='labelM'>Customs Declaration</div>
                            <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                        </div>
                    </div>  
                 </div>            
          </div> 
     </div>
     
     
   </div> 
   <div className='flex float-right gap-1'>
    <div className='modal_button_fieldmd23'><button className='common-btnmd' >CANCEL</button></div> 
    <div className='modal_button_fieldmd23'><button className='common_btnmd' >NEXT</button></div>   
    </div>

    </div>
   
   
  
   
     
  )
}

export default MasterDataTab3Content
