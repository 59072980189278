import React from 'react'
import "../Styles/CustomerDetail1.css";
import { Eye } from '@phosphor-icons/react';

function CustomerDetail1() {
  return (
    <div className='mainC11m'>
    <div className='T-1m'>
        <div className='S-00'>
            <div className='Layersc1'>
                <div className='Content-211'>                       
                    <div className="form-container1cm">
                        <div className="form-group11">
                            <label className='label-1m' htmlFor="employeeId">Customer Name</label>
                            <div className='input-div11m'>Sr.Associate</div>
                        </div>
                        <div className="form-group1">
                            <label className='label-21m' htmlFor="employeeName">Project Code</label>
                            <div className='input-div111cm'>0113</div>
                        </div>
                        <div className="form-group2">
                            <label className='label-21' htmlFor="email">Out Sourcing Sub Processor</label>
                            <div className='input-div111cm'>Synergy</div>
                        </div>
                        <div className="form-group3">
                            <label className='label-21' htmlFor="role">Out Sourcing Start Date</label>
                            <div className='input-div111cm'>01/01/2025</div>
                        </div>
                        <div className="form-group4">
                            <label className='label-21' htmlFor="subTeam">Out Sourcing End Date</label>
                            <div className='input-div111cm'>01/01/2025</div>
                        </div>

                        <button className="view-button1m">
                            <div><Eye size={16} color="#3d025f" weight="fill" /></div>
                            <div className='btn-text1231m' >VIEW</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default CustomerDetail1;
