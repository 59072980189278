import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { AuthContext } from "./AuthContext";
import { UserContext } from "./UserContext";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
const NotificationContext = createContext();
export const useNotifications = () => useContext(NotificationContext);
export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { userData, token, logout } = useContext(AuthContext);
  const { usersList } = useContext(UserContext);
  const toastRef = useRef();
  const user = userData?.[0];
  const fetchNotifications = async () => {
    try {
      const userEmpID = user?.EM_ID;
      const response = await fetch(
        `${notificationApiUrl}/GetNotificationMessage?FirstCount=1&LastCount=10&Userid=${userEmpID}&sendto=${userEmpID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
      } else {
        console.error("Failed to fetch notifications:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  useEffect(() => {
    if (user && token) {
      fetchNotifications();
    }
  }, [user, token]);

  const postNotification = async (message) => {
    try {
      const drmID = "6";
      const filteredUsers = usersList?.filter((user) => user.DRM_ID === drmID);
      const sendTo = filteredUsers.map((user) => user.EM_ID).filter(Boolean);

      const response = await fetch(`${notificationApiUrl}/PostNotification`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message,
          createdby: user?.EM_ID || "Unknown",
          sendTo: sendTo,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        await fetchNotifications();
        if (toastRef.current) {
          toastRef.current.showToast(
            "Notification sent successfully.",
            "success"
          );
        }
      } else {
        const result = await response.text();
        if (toastRef.current) {
          toastRef.current.showToast(
            `Failed to send notification: ${result}`,
            "error"
          );
        }
      }
    } catch (error) {
      console.error("Error sending notification:", error);
      if (toastRef.current) {
        toastRef.current.showToast("Failed to send notification", "error");
      }
    }
  };
  return (
    <NotificationContext.Provider
      value={{ notifications, fetchNotifications, postNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
