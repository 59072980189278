import React from 'react'
import Nav from '../components/Nav'
import UserDetailsHead from '../components/UserDetailsHead'

function UserDetailEdit() {
  return (
    <div>
        <Nav />
        <UserDetailsHead 
      Title="USER DETAILS"
      />
    </div>
  )
}

export default UserDetailEdit