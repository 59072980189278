import React from 'react'
import NavSub from '../components/NavSub'
import AddClientTabs from '../components/AddClientTabs'

function AddClient() {
  return (
    <div className='customer-page2'>
    <NavSub />
    <AddClientTabs/>
  </div>
  )
}

export default AddClient