import React from "react";
import "../Styles/SignUpPage.css"
import "../Styles/Info.css"
import "react-datepicker/dist/react-datepicker.css";

function BasicInfoEdit() {
  return (
    <div>
      <form class="flex flex-col my-4 mx-auto gap-4">
        <div class="flex gap-4 w-full">
          <div className="textfield_area">
          <div className='Roles_row__label'>Employee First Name</div>
            <div class="w-fill h-hug  gap-8">
              <input
                type="text"
                placeholder="Employee First Name"
                className='textfield_tab'
                // class="bg-custom-gray w-400 h-10 px-8 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
              />
            </div>
          </div>
          <div className="textfield_area">
          <div className='Roles_row__label'>Employee Last Name</div>
            <div>
              <input
                type="text"
                placeholder="Employee Last Name"
                className='textfield_tab'
                // class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
              />
            </div>
          </div>
        </div>
        <div>
        <div className='Roles_row__label'>Employee ID</div>
          <div>
            <input
              type="text"
              placeholder="Employee ID"
              className='textfield_tab_Id'
            //   class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            />
          </div>
        </div>
        <div class="flex gap-4">
        <div className="textfield_area">
          <div className='Roles_row__label'>Joining Date</div>
          <div>
            <input type='date' className="textfield_tab"/>
</div>

          </div>
          <div className="textfield_area">
          <div className='Roles_row__label'>Termination Date</div>
            <div>
            <input type='date' className="textfield_tab" />
            </div>
          </div>
        </div>
        <div class="flex gap-4">
        <div className="textfield_area">
          <div className='Roles_row__label'>User Name*</div>
            <div>
            <input
              type="text"
              placeholder="User Name*"
              className='textfield_tab'
            //   class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            />
            </div>
          </div>
          <div className="textfield_area">
          <div className='Roles_row__label'>Domain</div>
            <div>
            <input
              type="text"
              placeholder="Domain"
              className='textfield_tab'
            //   class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            />
            </div>
          </div>
        </div>
      </form>
      <div class="flex flex-col text-custom-gray-text mt-4 gap-1">
        <div>*Automatically Set Password</div>
        <div>Require this user to change password when they first login</div>
      </div>
     
    </div>
  );
}

export default BasicInfoEdit;
