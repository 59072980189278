
import React from 'react'
import "../Styles/STRActivity.css";
import NavSub from '../components/NavSub'
import STRtabs from '../components/STRtabs'
import STRNav from '../components/STRNav';
import FooterSTR from '../components/FooterSTR';
 
function STRActivity() {
  return (
    <div className='STR-file-design'>
        <NavSub />
         <STRNav />
         {/* <STRtabs /> */}
         {/* <FooterSTR /> */}
      
    </div>
  )
}
 
export default STRActivity
 