import React, { useState } from 'react'
import "../Styles/Modal.css"
import { Briefcase, User, UsersThree, XCircle } from '@phosphor-icons/react';
import BasicInfo from '../components/BasicInfo';
import Roles from '../components/Roles';

function SignUp() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };
  return (
    <div>
    <div class='flex gap-4'> 
            <button class='bg-custom-purple text-white px-8 py-4 w-189 rounded-md font-poppins text-base font-medium leading-27 tracking-normal text-left'>Select files to upload</button>
            <button class='bg-custom-purple text-white px-8 py-4 w-189 rounded-md font-poppins text-base font-medium leading-27 tracking-normal text-left' onClick={openModal}>+ Add new User</button>

    </div>
   
    {isModalOpen && (
   <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className='modalLayout'>
            <div className='modalLayout_contact'>
                <div className='modalLayoutMain_contact'>
                    <div className='modalStyling'>
                        <div className='flex justify-between'>
                        <div className="font-poppins text-lg font-semibold leading-36 text-left text-custom-purple"> + ADD NEW USER</div>
                       <XCircle onClick={closeModal} size={32}/>
                        </div>
                        <div class='flex gap-4 mt-3 flex-col ' >
                        <div class='flex flex-col sm:flex-col sm:w-80 gap-4 mt-6'>
    <button class='bg-custom-gray text-custom-gray-text px-4 py-2 w-full sm:w-209 h-42  rounded-md font-poppins text-base font-medium leading-27 tracking-normal text-left flex items-center gap-2' onClick={() => handleTabClick(0)}><User />BASIC Information</button>
    <button class='bg-custom-gray text-custom-gray-text px-4 py-2 w-full sm:w-209 h-42  rounded-md font-poppins text-base font-medium leading-27 tracking-normal text-left flex items-center  gap-2' onClick={() => handleTabClick(1)}><Briefcase />Roles</button>
    <button class='bg-custom-gray text-custom-gray-text px-4 py-2 w-full sm:w-209 h-42  rounded-md font-poppins text-base font-medium leading-27 tracking-normal text-left flex items-center  gap-2' onClick={() => handleTabClick(2)}><UsersThree />ORGANISATION</button>
</div>

    <div >
                {activeTab === 0 && 
                <div>
                    <BasicInfo />
                </div>
                }
                {activeTab === 1 && 
                <div>
                    <Roles />
                </div>}
                {activeTab === 2 && <div>ORGANISATION content</div>}
            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)}

    </div>
    
  ) 
}

export default SignUp