import React, { useState, useEffect, useRef } from 'react';
import "../Styles/Footerweek.css";
import { Info } from '@mui/icons-material';

function Footerweek() {
    const [isInfoMenuOpen, setIsInfoMenuOpen] = useState(false);
    const infoMenuRef = useRef(null);


    const toggleInfoMenu = () => {
        setIsInfoMenuOpen(!isInfoMenuOpen);
      };
    
      const closeInfoMenu = () => {
        setIsInfoMenuOpen(false);
      };

       // Close the menu when clicking outside or scrolling
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (infoMenuRef.current && !infoMenuRef.current.contains(event.target)) {
        closeInfoMenu();
      }
    };

    const handleScroll = () => {
      closeInfoMenu();
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className='footer-container-w'>
        <div className='footer-design'>
            
            <div className='footer-title'>TOTAL TIME

            <button onClick={toggleInfoMenu}>
                      <Info  className='icon-footer' weight='duotone' color='gray' />
                      
                    </button>
                    </div>
                    {isInfoMenuOpen && (

                      <div className="overlay-footer"  onClick={closeInfoMenu} ref={infoMenuRef}>
                        <div className="info-menu-footer">
                          <ul>
                            <li>Total Time : 22</li>
                            <li>Total Difference : 2</li>
                            
                          </ul>
                        </div>
                      </div>
                      )}

            
        </div>
      
    </div>
  )
}

export default Footerweek;
