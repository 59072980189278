import React, { useContext, useEffect, useRef } from "react";
// import NavChild from '../components/NavChild';
import "../Styles/VoucherEntry.css";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { useState } from "react";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import { CircularProgress } from "@mui/material";
import HorizontalDateCalender from "./HorizontalDateCalender";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import NavSub from "../components/NavSub";
import VoucherEntrynewMUI from "../components/VoucherEntrynewMUI";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../context/AuthContext";
import VoucherReviewMui from "../components/VoucherReviewMui";
import VocherReviewBottm from "../components/VocherReviewBottm";
import { UserContext } from "../context/UserContext";
import VoucherReviewMUI from "../components/VoucherReviewMui";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import ToastComponent from "../components/ToastComponent";
function ClientListDropdown({
  showOptions,
  setShowOptions,
  selectedOption,
  clients,
  handleOptionClick,
}) {
  return (
    <div className="dropdown-container-str">
      <div
        className="Title-STR-main2"
        onClick={() => setShowOptions(!showOptions)}
      >
        {selectedOption?.Name || " Customer Name "}
        <FontAwesomeIcon
          className="str-caret-icon"
          icon={showOptions ? faCaretUp : faCaretDown}
          onClick={() => setShowOptions(!showOptions)}
        />
      </div>
      {showOptions && (
        <div className="dropdown-proper-alligned">
          {clients?.map((option) => (
            <div
              key={option.Id}
              className="dropdown-proper-alligned-options"
              onClick={() => handleOptionClick(option)}
            >
              {option?.Name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function VoucherReview() {
  const todaysDate = new Date();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [clients, setClients] = useState([]);
  const [selectedYear, setSelectedYear] = useState(todaysDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(
    todaysDate.toLocaleString("default", { month: "long" })
  );
  const [selectedDay, setSelectedDay] = useState(todaysDate.getDate());
  const { userData, token, logout } = useContext(AuthContext);
  const [selectedMasterData, setSelectedMasterData] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const { usersList } = useContext(UserContext);
  const dropdownsearchRef = useRef(null);
  const [selectedAccountResponsible, setSelectedAccountResponsible] = useState(
    []
  );
  const [accountResponsible, setAccountResponsible] = useState([]);
  const [accountResponsible2, setAccountResponsible2] = useState([]);
  const [accSearch, setAccSearch] = useState("");
  const [isAccountResponsibleOpen, setIsAccountResponsibleOpen] =
    useState(false);
  const [selectedPrepareName, setSelectedPrepareName] = useState(null);

  const [selectedVouchersOnDate, setSelectedVouchersOnDate] = useState([]);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedPrepareName(null); // Reset preparer name when customer changes
    setSelectedAccountResponsible([]); // Clear selected responsible accounts
    setClients([]); // Optionally clear clients
    setShowOptions(false);
  };
  const toastRef = useRef();
  const fetchActivityList = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllActivity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setActivityList(
          data?.filter(
            (item) => item?.AL_Type === "Vouchers" && item?.AL_Status === "1"
          )
        );
      }
    } catch (error) {
      ////console.log(error);
    }
  };
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const fetchCustomer = async () => {
    //console.log(selectedPrepareName);
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?Page=${page}&ItemsPerPage=20&SubOwnerEMID=${
          selectedPrepareName?.EM_ID
            ? selectedPrepareName?.EM_ID
            : userData?.[0]?.EM_ID
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data?.length) {
          setClients([...clients, ...data]);
          //console.log([...clients, data]);
          setPage(page + 1);
        } else {
          return;
        }
      }
    } catch (error) {
      ////console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, [userData, page, selectedPrepareName]);

  useEffect(() => {
    fetchActivityList();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const teamData = [
    {
      viewForceCode: "0201 voucher",
      AccountingSystem: "172 stavenger triple",
      AccountStatus: "Active",
    },
  ];

  function getDateInReqdFormat(day, month, year) {
    const monthNames = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };

    const monthNumber = monthNames[month];

    const date = new Date(year, monthNumber, day);

    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const formattedDay = String(date.getDate()).padStart(2, "0");
    const formattedYear = date.getFullYear();

    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
  }

  const fetchSelectedMasterData = async () => {
    if (!selectedOption?.Id) {
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(
        `${clientApiUrl}/GetAllClient?id=${selectedOption?.Id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setSelectedMasterData(data);
      }
    } catch (e) {
      //console.log(e);
    }
    setLoading(false);
  };

  const fetchRelatedVouchersOnDate = async (id) => {
    if (!id) {
      return;
    }
    try {
      const response = await fetch(
        `${taskApiUrl}/GetAllTask?createdDate=${getDateInReqdFormat(
          selectedDay,
          selectedMonth,
          selectedYear
        )}&assignedTo=${id}${
          selectedPrepareName ? "" : `&clientId=${selectedOption?.Id}`
        }&ActivityType=Vouchers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setSelectedVouchersOnDate(data);
      }
    } catch {}
  };

  useEffect(() => {
    //console.log(selectedDay, selectedMonth, selectedYear);
    //console.log(selectedPrepareName);
    if (!selectedPrepareName?.EM_ID) {
      fetchRelatedVouchersOnDate(userData?.[0]?.EM_ID);
    } else {
      fetchRelatedVouchersOnDate(selectedPrepareName?.EM_ID);
    }
  }, [
    selectedDay,
    selectedMonth,
    selectedYear,
    selectedOption,
    selectedPrepareName,
  ]);

  useEffect(() => {
    fetchSelectedMasterData();
  }, [selectedOption]);

  //VC

  const handleAccountingResponsibleClick = () => {
    setSelectedOption({});
    setShowOptions(true);
    setIsAccountResponsibleOpen((prevState) => !prevState);
  };

  const handleCheckboxAccountResponsibleChange = (acc) => {
    setSelectedOption({});

    //console.log("abc", acc);
    if (selectedPrepareName === acc) {
      setSelectedPrepareName({});
      setSelectedAccountResponsible([]);
      return;
    }
    setPage(1);
    setClients([]);
    setSelectedPrepareName(acc);
    setSelectedAccountResponsible([acc]);
  };

  useEffect(() => {
    setAccountResponsible(usersList);
    setAccountResponsible2(usersList);
  }, [usersList]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownsearchRef.current &&
        !dropdownsearchRef.current.contains(event.target)
      ) {
        setIsAccountResponsibleOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownsearchRef]);

  return (
    <div className="VoucherEntry">
      <NavSub />
      <ToastComponent ref={toastRef} timeout={4000} />
      <div className="Voucher-acc">
        {/* <div className='Voucher-cust-name'>Customer Name   <CaretDown size={18} className='icon-VE' color="#3d025f" weight="fill" /></div> */}
        <div className="new-dd-for-vr">
          <ClientListDropdown
            selectedOption={selectedOption}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            clients={clients}
            handleOptionClick={handleOptionClick}
          />
          <div className="Title-STR-main2">
            {selectedPrepareName?.EM_FirstName || "Preparer Name"}
          </div>
          <FontAwesomeIcon
            className="str-caret-icon"
            icon={isAccountResponsibleOpen ? faCaretUp : faCaretDown}
            onClick={handleAccountingResponsibleClick}
          />
          {isAccountResponsibleOpen && (
            <div ref={dropdownsearchRef} className="search-drop-designnew">
              <div className="search-drop-page">
                <div className="main-suggestion">
                  <div className="input-suggestion">
                    <div className="icon-search-phospher">
                      <MagnifyingGlass
                        size={20}
                        color="#ada4a4"
                        weight="fill"
                      />
                    </div>
                    <input
                      type="text"
                      value={accSearch}
                      className="input-suggestion-search"
                      onChange={(e) => {
                        const value = e.target.value.toLowerCase();
                        setAccSearch(value);
                        setAccountResponsible2(
                          accountResponsible.filter((acc) =>
                            acc.EM_FirstName.toLowerCase().includes(value)
                          )
                        );
                      }}
                      placeholder="Start typing..."
                    />
                  </div>
                  <div
                    style={{ overflow: "scroll", maxHeight: "500px" }}
                    tabIndex={1}
                  >
                    {accountResponsible2.map((acc) => (
                      <div key={acc.EM_ID} className="suggestion-list-li">
                        <input
                            type="radio"
                            className="radio-searchbox"
                            style={{
                              accentColor: 'rgb(80, 20, 90)', 
                              width: '18px',
                              height: '18px',
                            }}
                          checked={selectedAccountResponsible.some(
                            (item) => item.EM_FirstName === acc.EM_FirstName
                          )}
                          onChange={() =>
                            handleCheckboxAccountResponsibleChange(acc)
                          }
                        />
                        <label className="li-content">{acc.EM_FirstName}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="Voucher-status">
          {selectedMasterData?.map((item, index) => (
            <div className="Acc-detail">
              <div
                className="Voucher-props"
                style={{
                  borderRight: "1px solid rgba(80, 20, 90, 0.50)",
                  paddingRight: "16px",
                }}
              >
                View Force code : {"0201 Vouchers"}
              </div>
              <div className="Voucher-props" style={{ padding: "0px 16px" }}>
                AccountingSystem : {item.ERPSystem ? item.ERPSystem : "N/A"}
              </div>
              <div
                className="Voucher-props"
                style={{
                  borderLeft: "1px solid rgba(80, 20, 90, 0.50)",
                  paddingLeft: "16px",
                }}
              >
                Account Status : {item.Status}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ------------------- Information ---------------------------------------------- */}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <CircularProgress style={{ color: "purple" }} width={40} />
        </div>
      )}
      {!loading && (
        <div className="ve-info-main">
          <div className="ve-info-main-bg">
            <div className="ve-info-main-head">
              <div className="Voucher-cust-name">Information</div>
              {isVisible ? (
                <CaretUp
                  size={18}
                  className="icon-VE"
                  color="#3d025f"
                  weight="fill"
                  onClick={toggleVisibility}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <CaretDown
                  size={18}
                  className="icon-VE"
                  color="#3d025f"
                  weight="fill"
                  onClick={toggleVisibility}
                  style={{ cursor: "pointer" }}
                />
              )}
            </div>
            {isVisible && (
              <div className="vocher-entry-info-main">
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Header Information :
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      Client Name | Supplier Name | Invoice Name | Invoice Date
                      & Due Date | KID CID | Invoice Amount & Currency
                    </div>
                  </div>
                </div>
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Subject Information :
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      GL Account | VAT Code & Amount | Periodization | Project /
                      Department | Re Invoicing
                    </div>
                  </div>
                </div>
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Remittance :
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      Bank Account | Payment Type | Credit Note Adjustment |
                      Attestation | Foreign Payment | Auto Pay
                    </div>
                  </div>
                </div>
                <div className="vocher-entry-info">
                  <div className="vocher-entry-info-content">
                    <div className="vocher-entry-info-content-heading">
                      Instructions :
                    </div>
                    <div className="vocher-entry-info-content-sub-heading">
                      RAM | DOCN | PTNB | PTNP
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* ------------------- CALENDER ---------------------------------------------- */}
          {(selectedMasterData?.length > 0 || selectedPrepareName?.EM_ID) && (
            <div>
              <HorizontalDateCalender
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            </div>
          )}

          {/* ---------------------VOUCHER ENTRY TABLE----------------------------------------- */}

          {/* <VoucherEntrynewfirst /> */}

          {(selectedMasterData?.length > 0 || selectedPrepareName?.EM_ID) && (
            <VoucherReviewMUI
              selectedOption={selectedOption}
              data={selectedVouchersOnDate}
              prepareName={selectedPrepareName}
              activityList={activityList}
              selectedDay={selectedDay}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              selectedMasterData={selectedMasterData}
              fetchRelatedVouchersOnDate={fetchRelatedVouchersOnDate}
              getDateInReqdFormat={getDateInReqdFormat}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default VoucherReview;
