import React, { useState, useContext, useEffect } from "react";
import "../Styles/Dashboard_graph4.css";
import "../Styles/Dashboard.css";
import { MenuItem } from "@mui/material";
import { Select } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function Dashboard_graph_4() {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const { teamsData, usersList } = useContext(UserContext);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString());

  const [controlData, setControlData] = useState([]);
  const [filteredControlData, setFilteredControlData] = useState({
    completed: 0,
    pending: 0,
    notUpdated: 0,
  });

  // const fetchControlData = async (monthString) => {
  //   const [year, month] = monthString.split("-");
  //   const lastDay = new Date(year, month, 0).getDate();
  //   const firstDay = "01";

  //   const formattedDateRange1 = `${year}-${month}-${firstDay}`;
  //   const formattedDateRange2 = `${year}-${month}-${lastDay
  //     .toString()
  //     .padStart(2, "0")}`;

  //   try {
  //     const response = await fetch(
  //       `https://fn-hampi-taskmanager.azurewebsites.net/api/GetControlPointMapping?DateRange1=${formattedDateRange1}&DateRange2=${formattedDateRange2}&Emids=${filteredEmployeeList}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     if (response?.status === 401) {
  //       navigate("/");
  //     }
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();
  //     console.log("Fetched control data:", data);
  //     setControlData(data);
  //     const completed = data?.filter(
  //       (item) => item.Status === "Completed"
  //     ).length;
  //     const pending = data?.filter((item) => item.Status === "pending").length;
  //     const notUpdated = data?.filter(
  //       (item) => item.Status === "Not Updated"
  //     ).length;

  //     setFilteredControlData({ completed, pending, notUpdated });
  //   } catch (error) {
  //     console.error("Error fetching control data:", error);
  //   }
  // };

  const fetchControlData = async (monthString) => {
    const [year, month] = monthString.split("-"); // Split the monthString into year and month

    const lastDay = new Date(year, month, 0).getDate(); // Last day of the month
    const firstDay = "01"; // First day of the month

    const formattedDateRange1 = `${year}-${month}-${firstDay}`;
    const formattedDateRange2 = `${year}-${month}-${lastDay
      .toString()
      .padStart(2, "0")}`; // Ensure two digits for day

    // Get previous month's year and month
    const prevMonth = month === "01" ? 12 : Number(month) - 1;
    const prevYear = month === "01" ? Number(year) - 1 : year;
    const prevLastDay = new Date(prevYear, prevMonth, 0).getDate();

    const prevFormattedDateRange1 = `${prevYear}-${String(prevMonth).padStart(
      2,
      "0"
    )}-01`;
    const prevFormattedDateRange2 = `${prevYear}-${String(prevMonth).padStart(
      2,
      "0"
    )}-${prevLastDay.toString().padStart(2, "0")}`;

    try {
      // Fetch current month's data
      const currentMonthResponse = await fetch(
        `${taskApiUrl}/GetControlPointMapping?DateRange1=${formattedDateRange1}&DateRange2=${formattedDateRange2}&Emids=${filteredEmployeeList}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (currentMonthResponse?.status === 401) {
        navigate("/");
      }
      if (!currentMonthResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const currentMonthData = await currentMonthResponse.json();
      console.log("Fetched current month control data:", currentMonthData);

      // Fetch previous month's data
      const prevMonthResponse = await fetch(
        `${taskApiUrl}/GetControlPointMapping?DateRange1=${prevFormattedDateRange1}&DateRange2=${prevFormattedDateRange2}&Emids=${filteredEmployeeList}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (prevMonthResponse?.status === 401) {
        navigate("/");
      }
      if (!prevMonthResponse.ok) {
        throw new Error("Network response was not ok");
      }
      const prevMonthData = await prevMonthResponse.json();
      console.log("Fetched previous month control data:", prevMonthData);

      // Process current month data for pending
      const pending = currentMonthData?.filter(
        (item) => (item.Status === "P" && item.Frequency=='Daily')
      ).length;

      // Process previous month data for not updated
      const notUpdated = prevMonthData?.filter(
        (item) =>  (item.Status === "P" && item.Frequency=='Daily')
      ).length;

      // No change in completed field calculation
      const completed = currentMonthData?.filter(
        (item) => (item.Status === "C" && item.Frequency=='Daily')
      ).length;

      // Update the state with the computed values
      setFilteredControlData({ completed, pending, notUpdated });
    } catch (error) {
      console.error("Error fetching control data:", error);
    }
  };

 
  
  

  useEffect(() => {
    fetchControlData(selectedMonth);
  }, [token, filteredEmployeeList, selectedMonth]);

  function handleMonthChange(monthString) {
    //console.log(monthString);
    setSelectedMonth(monthString);
  }
  const currentDate = new Date();
  const currentMonthYear = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}`;

  const handleTeamChange = (e) => {
    const value = e.target.value;
    setSelectedTeam(value);
    //console.log(value);
  };

  useEffect(() => {
    const filteredEmployees = usersList?.filter(
      (user) => user?.STM_Team == selectedTeam
    );
    //console.log(filteredEmployees);
    setFilteredEmployeeList(
      filteredEmployees?.map((item) => `'${item["EM_ID"]}'`).join(",")
    );
  }, [selectedTeam]);

  return (
    <div className="g4-main">
      <div className="g4-main-drop-div">
        <div className="g4-head">Control points</div>
        <div className="dropdown-g4-side-right">
          <div>
            <input
              type="month"
              value={selectedMonth.slice(0, 7)}
              onChange={(e) => handleMonthChange(e.target.value)}
              className="calstyle-for-dashboard"
            />
          </div>
          <Select
            fullWidth
            value={selectedTeam}
            onChange={handleTeamChange}
            displayEmpty
          >
            <MenuItem value="">Teams</MenuItem>
            {teamsData[0]
              ?.filter((team) => team?.status === true && team?.type === "T")
              ?.map((data) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.name}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>
      <div className="g4-subhead">Live status of control points</div>
      <div className="g4-cards-div">
        <div className="g4-card1-dash">
          <div className="g4-card1-dash-c1">Completed :</div>
          <div className="g4-card1-dash-c2">
            {filteredControlData.completed}
          </div>
        </div>
        <div className="g4-card2-dash">
          <div className="g4-card1-dash-c1">Pending :</div>
          <div className="g4-card1-dash-c3">{filteredControlData.pending}</div>
        </div>
        <div className="g4-card3-dash">
          <div className="g4-card1-dash-c1">OverDue :</div>
          <div className="g4-card1-dash-c4">
            {filteredControlData.notUpdated}
          </div>
        </div>
      </div>
      <div className="g4-dash-footer">
        <div className="g4-dash-footer-data">
          Total count and time utilized Count :
          <span className="g4-dash-footer-spam">
            {filteredControlData.completed +
              filteredControlData.pending +
              filteredControlData.notUpdated}{" "}
            {/* | Time : 111 */}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Dashboard_graph_4;
