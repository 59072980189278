import React from 'react'
import "../Styles/MasterDataTab4Content.css";


function MasterDataTab4Content() {
  return (
    <div>
        <div className='main-div'>
    <div className='mainD'>
     <div className='ContainerD'>
        <div className='title-tab4'>
        TIME ESTIMATION OF REPORTING ACTIVITIES (Min)
        </div>
           <div className='main-user-infoD'>
                <div className='Customer-detail-row1'>

                     <div className='user-textfield-fields'>
                         <div className='labelM'>Bank reconciliation - Postings</div>
                         <div><input className='input123D' type='text' id="fullName" name="customername" placeholder=''/></div> 
                     </div>
                     <div className='user-textfield-fields'>
                         <div className='labelM'>Customer/ supplier ledger</div>
                         <div><input className='input123D' type='text' id="" name='' placeholder=""/></div>
                     </div>
                     <div className='user-textfield-fields'>
                         <div className='labelM'>VAT Reporting & Customs declaration</div>
                         <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                     </div>
                 </div>
                 <div className='Customer-detail-row1'>

                    <div className='user-textfield-fields'>
                        <div className='labelM'>Reconciliation TAX AGA</div>
                        <div><input className='input123D' type='text' id="" name='' /></div>
                    </div>
                    <div className='user-textfield-fields'>
                        <div className='labelM'>Prepare reporting</div>
                        <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                    </div>
                    <div className='user-textfield-fields'>
                        <div className='labelM'>Depreciation / Monthly journals</div>
                        <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                    </div>
                </div>  
                <div className='Customer-detail-row1'>

                    <div className='user-textfield-fields'>
                        <div className='labelM'>Month end tasks(Clearing & Others)</div>
                        <div><input className='input123D' type='text' id="" name='' /></div>
                    </div>
                    <div className='user-textfield-fields'>
                        <div className='labelM'>Review of PL - BS Accounts</div>
                        <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                    </div>
                    <div className='user-textfield-fields'>
                        <div className='labelM'>Balance recons</div>
                        <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                    </div>
                </div>  
                <div className='Customer-detail-row1'>

                    <div className='user-textfield-fields'>
                        <div className='labelM'>Consulting- Administration</div>
                        <div><input className='input123D' type='text' id="" name='' /></div>
                    </div>
                    
                </div>  

             </div>            
      </div> 
 </div>


</div>   
<div className='flex float-right gap-1'>
    <div className='modal_button_fieldmd23'><button className='common-btnmd' >CANCEL</button></div> 
    <div className='modal_button_fieldmd23'><button className='common_btnmd' >NEXT</button></div>   
    </div>
 

    </div>
    
  )
}

export default MasterDataTab4Content
