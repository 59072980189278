import React, { useState } from 'react'
import "../Styles/UserDetailsHead.css"
import {  PencilSimpleLine ,Briefcase,UsersThree,User, ArrowLeft} from '@phosphor-icons/react'
import BasicInfo from './BasicInfo';
import Roles from './Roles';
import Organisation from './Organisation';
import BasicInfoEdit from './BasicInfoEdit';
import RolesEdit from './RolesEdit';
import OrganisationEdit from './OrganisationEdit';

function UserDetailsHead(props) {
  
    const [activeTab, setActiveTab] = useState(0);
  
    const handleTabClick = (index) => {
      setActiveTab(index);
    };
    const handleNextButtonClick = () => {
      const nextTab = activeTab + 1;
      setActiveTab(nextTab >= 0 && nextTab <= 2 ? nextTab : 0);
    };
  return (
    <div className='user_details_head'>
        <div className='user_details_head_container'>
        <div className='User_details_content'>
            <div className='UserDetailsHead_title'><ArrowLeft weight='fill'/>{props.Title}</div>
            <div><PencilSimpleLine weight='fill'/></div>
        </div>
        <div className='userDetials_head_field'>
                    <div className="modal_buttons_section">
                      <button
                        className={`modal_btns ${
                          activeTab === 0 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(0)}
                      >
                        <User weight="fill" />
                        Basic Information
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(1)}
                      >
                        <Briefcase weight="fill" />
                        Roles
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(2)}
                      >
                        <UsersThree weight="fill" />
                        ORGANISATION
                      </button>
                    </div>

                        </div>
       </div>
        
        <div className='tabs_container'>
                      {activeTab === 0 && (
                        <div className='tab_layout'>
                          <BasicInfoEdit />
                        </div>
                      )}
                      {activeTab === 1 && (
                        <div className='tab_layout'>
                          <RolesEdit/>
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div className='tab_layout'>
                          <OrganisationEdit />
                        </div>
                      )}

                    <div className="next_btn_field">
                      <button className="common_btn"    onClick={handleNextButtonClick}>Next</button>
                    </div>
                    </div>
                    
        
    </div>
  )
}

export default UserDetailsHead