import React, { useContext, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
const Notification = () => {
  const { token, logout } = useContext(AuthContext);
  const { connectionId, setConnectionId } = useContext(AuthContext);

  const hubUrl = `${notificationApiUrl}/`;
  let connection;

  connection = new signalR.HubConnectionBuilder()
    .withUrl(hubUrl, {
      accessTokenFactory: () => token,
    })
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection.start();

  const subscribeToEvent = (eventName, callback) => {
    //console.log(connection);
    setConnectionId(connection);
    if (connection) {
      connection.on(eventName, callback);
    }

    connection.onclose(() => {
      //console.log("SignalR Connection Closed!");
    });
  };

  useEffect(() => {
    subscribeToEvent("MessageReceived", (message) => {
      //console.log(message);
    });

    return () => {};
  }, []);

  return (
    <div>
      Anand
      <Link to="/userSetupAndRoles">go</Link>
    </div>
  );
};

export default Notification;
