import React from 'react'
import "../Styles/MasterDataTab2Content.css";

function MasterDataTab2Content() {
  return (
    <div>
    <div className='main-div2'>
      <div className='mainD2'>
       <div className='ContainerD2'>
        <div className='title-2'>Dates</div>
             <div className='main-user-infoD'>
                   <div className='Customer-detail-row1'>

                       <div className='user-textfield-fields'>
                           <div className='labelM'>Out Sourcing Start Date</div>
                           <div><input className='input123D' type='text' id="fullName" name="customername" placeholder=''/></div> 
                       </div>
                       <div className='user-textfield-fields'>
                           <div className='labelM'>Out Sourcing End Date</div>
                           <div><input className='input123D' type='text' id="" name='' placeholder=""/></div>
                       </div>
                       <div className='user-textfield-fields'>
                           <div className='labelM'>Customer Reporting Date</div>
                           <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                       </div>
                   </div>

                   <div className='Customer-detail-row2'>

                       <div className='user-textfield-fields'>
                           <div className='labelM'>Frequency Report To Customer</div>
                           <div><input className='input123D' type='text' id="" name='' /></div>
                       </div>
                       <div className='user-textfield-fields'>
                           <div className='labelM'>Frequency Voucher Processing</div>
                           <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                       </div>
                       
                   </div>  
                   <div className='Customer-detail-row2'>

                       <div className='user-textfield-fields'>
                           <div className='labelM'>Accounting Info Updates</div>
                           <div><input className='input123D' type='text' id="" name='' /></div>
                       </div>
                       <div className='user-textfield-fields'>
                           <div className='labelM'>Frequency Voucher Processing</div>
                           <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                       </div>
                       
                   </div>  
               </div>            
       </div> 
   </div>

 </div>  


<div className='main-div21'>
  <div className='mainD'>
      <div className='ContainerD21'>
      <div className='title-2'>Roles</div>
          <div className='main-user-infoD21'>
              <div className='Customer-detail-row11'>

                  <div className='user-textfield-fields1a'>
                      <div className='labelM'>Department</div>
                      <div><input className='input123D1' type='text' id="fullName" name="customername" placeholder=''/></div> 
                  </div>
                  <div className='user-textfield-fields1a'>
                      <div className='labelM'>Department Head</div>
                      <div><input className='input123D1' type='text' id="" name='' placeholder=""/></div>
                  </div>
              
              </div>
              <div className='Customer-detail-row21'>

                  <div className='user-textfield-fields'>
                      <div className='labelM'>View Account Owner</div>
                      <div><input className='input123D1' type='text' id="" name='' /></div>
                  </div>
                  <div className='user-textfield-fields'>
                      <div className='labelM'>View Team Lead</div>
                      <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                  </div>
                  <div className='user-textfield-fields'>
                      <div className='labelM'>Synergy Accounting Responsible</div>
                      <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                  </div>
              </div>  
              <div className='Customer-detail-row331'>

                  <div className='user-textfield-fields1'>
                      <div className='labelM'>Synergy Accounting Consultant</div>
                      <div><input className='input123D1' type='text' id="" name='' /></div>
                  </div>
                  <div className='user-textfield-fields1'>
                      <div className='labelM'>Synergy Process Lead</div>
                      <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                  </div>
                  <div className='user-textfield-fields1'>
                      <div className='labelM'>Synergy Department Lead</div>
                      <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                  </div>

               
              </div>  
          </div>            
      </div> 
      </div>  
      {/* <div className='modal_button_fieldmd23'><button className='common_btnmd' >NEXT</button></div>  */}
     
  </div>
  <div className='flex float-right gap-1'>
    <div className='modal_button_fieldmd23'><button className='common-btnmd' >CANCEL</button></div> 
    <div className='modal_button_fieldmd23'><button className='common_btnmd' >NEXT</button></div>   
    </div>

 
</div>
  )
}

export default MasterDataTab2Content
