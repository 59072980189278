import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/CustomerMasterTabs.css";
import { ArrowLeft, PencilSimpleLine, Trash } from "@phosphor-icons/react";
import { useNavigate, useParams } from "react-router-dom";
import "../Styles/User.css";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import "../Styles/common.css";
import BasicInfoDetail from "../components/BasicInfoDetail";
import RolesDetails from "../components/RolesDetails";
import OrganisationDetail from "../components/OrganisationDetail";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import ToastComponent from "../components/ToastComponent";
function UserDeatilScreen() {
  const params = useParams();
  const id = params.id;
  const isNew = id === "new" ? true : false;
  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [empLoading, setEmpLoading] = useState(false);
  const { userData, token, getUserInfo } = useContext(AuthContext);
  const { fetchData, usersList } = useContext(UserContext);
  const toastRef = useRef();
  const { handleAuthExpiry, logout } = useContext(AuthContext);

  const handleTabClick = (index) => {
    // !editMode && setActiveTab(index);
    if (index < activeTab) {
      setActiveTab(index);
      return;
    } else {
      !editMode && setActiveTab(index);
    }
  };
  const handleNextButtonClick = () => {
    const nextTab = activeTab + 1;
    setErrors(false);
    if (activeTab === 0) {
      if (
        !empData[0]?.EM_FirstName ||
        !empData[0]?.EM_EmpID ||
        !empData[0]?.EM_JoiningDate ||
        !empData[0]?.EM_EmailId?.split("@")[0]
      ) {
        setErrors(true);
        return;
      } else {
        setActiveTab(activeTab + 1);
        setErrors(false);
      }
    }
    if (activeTab === 1) {
      if (
        !empData[0]?.EM_Designation ||
        !empData[0]?.DRM_ID ||
        !empData[0]?.STM_Team ||
        !empData[0]?.STM_Subteam
      ) {
        setErrors(true);
        return;
      } else {
        setActiveTab(activeTab + 1);
        setErrors(false);
      }
    }
    if (activeTab === 2) {
      if (
        !empData[0]?.STM_QC ||
        !empData[0]?.STM_PL ||
        !empData[0]?.STM_DM_Head ||
        !empData[0]?.STM_Manager
      ) {
        setErrors(true);
        return;
      } else {
        if (!isNew) {
          updateDetails();
        } else {
          addNewUser();
        }
        setErrors(false);
      }
    }
  };

  const handleEditClick = () => {
    setActiveTab(0);
    setEditMode(!editMode);
  };
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };
  const [empData, setEmpData] = useState([]);

  const fetchEmpData = async () => {
    setEmpLoading(true);
    try {
      const response = await fetch(`${userApiUrl}/GetAllEmp?EMID=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // //console.log(data);
      setEmpLoading(false);

      setEmpData(data);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  const updateDetails = async () => {
    if (
      !empData[0]?.STM_QC ||
      !empData[0]?.STM_PL ||
      !empData[0]?.STM_Manager ||
      !empData[0]?.STM_DM_Head
    ) {
      return;
    }
    setLoading(true);
    try {
      const emailExists = usersList
        .filter((u) => u.EM_ID !== empData[0].EM_ID)
        ?.some(
          (user) =>
            user.EM_EmailId.toLowerCase() ===
            empData[0].EM_EmailId.toLowerCase()
        );
      const empIdExists = usersList
        .filter((u) => u.EM_ID !== empData[0].EM_ID)
        ?.some(
          (user) =>
            user.EM_EmpID.toLowerCase() === empData[0].EM_EmpID.toLowerCase()
        );
      const nameExists = usersList
        .filter((u) => u.EM_ID !== empData[0].EM_ID)
        ?.some(
          (user) =>
            (user.EM_FirstName + " " + user.EM_LastName).toLowerCase() ===
            (
              empData[0].EM_FirstName +
              " " +
              empData[0].EM_LastName
            ).toLowerCase()
        );

      if (emailExists) {
        toastRef.current.showToast(
          "Error: Email already exists in the users list.",
          "error"
        );
        setLoading(false);

        return;
      }
      if (empIdExists) {
        toastRef.current.showToast(
          "Error: Employee ID already exists in the users list.",
          "error"
        );
        setLoading(false);

        return;
      }
      if (nameExists) {
        toastRef.current.showToast(
          "Error: Name already exists in the users list.",
          "error"
        );
        setLoading(false);

        return;
      }
      const response = await fetch(`${userApiUrl}/UpdateEmp`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ModifiedById: userData?.[0]?.EM_ID ? userData?.[0]?.EM_ID : "",
          employeeId: empData[0]?.EM_EmpID?.trim(),
          firstName: empData[0]?.EM_FirstName,
          lastName: empData[0]?.EM_LastName,
          joiningDate: empData[0]?.EM_JoiningDate,
          terminationDate: empData[0]?.EM_TerminationDate,
          email: empData[0]?.EM_EmailId?.split("@")?.[0] + "@viewsynergy.com",
          designationId: parseFloat(empData[0]?.EM_Designation),
          departmentId: parseFloat(empData[0]?.EM_Department),
          UserID: empData[0]?.EM_ID,
          roleId: [empData[0]?.DRM_ID],
          teamId: parseFloat(empData[0]?.STM_Team),
          subTeamId: parseFloat(empData[0]?.STM_Subteam),
          qualityConsultantId: empData[0]?.STM_QC,
          processLeadId: empData[0]?.STM_PL,
          departmentLeadId: empData[0]?.STM_DM_Head,
          managerId: empData[0]?.STM_Manager,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      setLoading(false);
      if (data) {
        toastRef.current.showToast("Updated Successfully", "success");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        fetchData();
        getUserInfo(userData?.[0]?.EM_ID, token);
        navigate("/UserSetupAndRoles");
      } else {
        toastRef.current.showToast(
          "Something went wrong. Please try again",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error Updating User Data :", error.message); // Log the error message
      setLoading(false);
    }
  };
  const resetPassword = async () => {
    try {
      const response = await fetch(`${userApiUrl}/UpdateEmp`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ModifiedById: userData?.[0]?.EM_ID ? userData?.[0]?.EM_ID : "",
          password: "password123",
          employeeId: empData[0]?.EM_EmpID?.trim(),
          firstName: empData[0]?.EM_FirstName,
          lastName: empData[0]?.EM_LastName,
          joiningDate: empData[0]?.EM_JoiningDate,
          terminationDate: empData[0]?.EM_TerminationDate,
          email: empData[0]?.EM_EmailId?.split("@")?.[0] + "@viewsynergy.com",
          designationId: parseFloat(empData[0]?.EM_Designation),
          departmentId: parseFloat(empData[0]?.EM_Department),
          UserID: empData[0]?.EM_ID,
          roleId: [empData[0]?.DRM_ID],
          teamId: parseFloat(empData[0]?.STM_Team),
          subTeamId: parseFloat(empData[0]?.STM_Subteam),
          qualityConsultantId: empData[0]?.STM_QC,
          processLeadId: empData[0]?.STM_PL,
          departmentLeadId: empData[0]?.STM_DM_Head,
          managerId: empData[0]?.STM_Manager,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      // const handleAuthError = handleAuthExpiry(response);
      // if (handleAuthError === true) {
      //   toastRef.current.showToast("Your Session has expired", "error");
      //   logout();
      //   return false;
      // }
      const data = await response.json();
      setLoading(false);
      if (data) {
        toastRef.current.showToast("Updated Successfully", "success");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // fetchData();
        // getUserInfo(userData?.[0]?.EM_ID, token);
        // navigate("/UserSetupAndRoles");
      } else {
        toastRef.current.showToast(
          "Something went wrong. Please try again",
          "error"
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error Updating User Data :", error.message); // Log the error message
      setLoading(false);
    }
  };
  const addNewUser = async () => {
    if (
      !empData[0]?.STM_QC ||
      !empData[0]?.STM_PL ||
      !empData[0]?.STM_Manager ||
      !empData[0]?.STM_DM_Head
    ) {
      return;
    }
    setLoading(true);
    try {
      const emailExists = usersList
        .filter((u) => u.EM_ID !== empData[0].EM_ID)
        ?.some(
          (user) =>
            user.EM_EmailId.toLowerCase() ===
            empData[0].EM_EmailId.toLowerCase()
        );
      const empIdExists = usersList
        .filter((u) => u.EM_ID !== empData[0].EM_ID)
        ?.some(
          (user) =>
            user.EM_EmpID.toLowerCase() === empData[0].EM_EmpID.toLowerCase()
        );
      const nameExists = usersList
        .filter((u) => u.EM_ID !== empData[0].EM_ID)
        ?.some(
          (user) =>
            (user.EM_FirstName + " " + user.EM_LastName).toLowerCase() ===
            (
              empData[0].EM_FirstName +
              " " +
              empData[0].EM_LastName
            ).toLowerCase()
        );

      if (emailExists) {
        toastRef.current.showToast(
          "Error: Email already exists in the users list.",
          "error"
        );
        setLoading(false);

        return;
      }
      if (empIdExists) {
        toastRef.current.showToast(
          "Error: Employee ID already exists in the users list.",
          "error"
        );
        setLoading(false);

        return;
      }
      if (nameExists) {
        toastRef.current.showToast(
          "Error: Name already exists in the users list.",
          "error"
        );
        setLoading(false);

        return;
      }
      const response = await fetch(`${userApiUrl}/CreateEmp?`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          createdByEmpId: userData?.[0]?.EM_ID ? userData?.[0]?.EM_ID : "",
          employeeId: empData[0]?.EM_EmpID?.trim(),
          firstName: empData[0]?.EM_FirstName,
          lastName: empData[0]?.EM_LastName ? empData[0]?.EM_LastName : " ",
          joiningDate: empData[0]?.EM_JoiningDate,
          // "terminationDate": user?.EM_TerminationDate,
          email: empData[0]?.EM_EmailId?.split("@")?.[0] + "@viewsynergy.com",
          passWord: "password123",
          designationId: parseFloat(empData[0]?.EM_Designation),
          departmentId: 9,
          // "UserID": empData[0]?.EM_ID,
          roleId: [empData[0]?.DRM_ID],
          teamId: parseFloat(empData[0]?.STM_Team),
          subTeamId: parseFloat(empData[0]?.STM_Subteam),
          qualityConsultantId: empData[0]?.STM_QC,
          processLeadId: empData[0]?.STM_PL,
          departmentLeadId: empData[0]?.STM_DM_Head,
          managerId: empData[0]?.STM_Manager,
        }),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      setLoading(false);
      //console.log(data);
      if (data) {
        toastRef.current.showToast("User Added Successfully", "success");
        await new Promise((resolve) => setTimeout(resolve, 2000));

        fetchData();
        navigate("/UserSetupAndRoles");
      } else {
        setLoading(false);

        toastRef.current.showToast(
          "Something went wrong. Please try again",
          "error"
        );
      }
    } catch (error) {
      console.error("Error Updating User Data :", error.message); // Log the error message
      setLoading(false);
    }
  };

  const [teamsData, setTeamsData] = useState([]);
  const [teamNames, setTeamNames] = useState([]);
  // const [filteredTeams, setFilteredTeams] = useState([])
  const fetchTeams = async () => {
    setEmpLoading(true);
    try {
      const response = await fetch(`${userApiUrl}/GetTeams?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      // //console.log(data);
      const groupedTeams = data?.reduce((acc, team) => {
        const id = team.SuperTeamId;
        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(team);
        return acc;
      }, {});

      const filtered = Object.values(groupedTeams);
      setTeamsData(filtered);
      setTeamNames(filtered[0]);
      setEmpLoading(false);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEmpData();
    fetchTeams(token);
  }, [token]);

  useEffect(() => {
    //console.log("triggered on change", empData);
  }, [empData]);

  const [roles, setRoles] = useState();
  const [designation, setDesignation] = useState();
  const [department, setDepartment] = useState();

  const fetchRoles = async () => {
    setEmpLoading(true);
    try {
      const response = await fetch(`${userApiUrl}/GetRole?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      //console.log(data);
      setRoles(data);
      setEmpLoading(false);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };
  const fetchDepartments = async () => {
    setEmpLoading(true);

    try {
      const response = await fetch(
        `${userApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      // //console.log(data);
      setDepartment(data);
      setEmpLoading(false);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchDepartments();
  }, [token]);

  return (
    <>
      <ToastComponent ref={toastRef} timeout={4000} />

      {empLoading ? (
        <div
          style={{ width: "100%", margin: "100px 400px", minWidth: "100vh" }}
        >
          <CircularProgress style={{ color: "purple" }} />
        </div>
      ) : (
        <div className="UserDeatil_screen_layout">
          <div className="Container-nav1t">
            <div className="modal_buttons_section1tt">
              <div className="flex gap-4 C-Title">
                <div
                  className="back"
                  onClick={() => {
                    navigate("/UserSetupAndRoles");
                  }}
                >
                  <ArrowLeft size={20} color="#50145A" weight="fill" />
                </div>
                {!isNew && <div>USER DETAILS</div>}
                {isNew && <div>CREATE NEW USER</div>}
              </div>

              {!isNew && (
                <div className="flex gap-4">
                  <div>
                    <PencilSimpleLine
                      size={20}
                      color="#50145A"
                      weight="fill"
                      onClick={handleEditClick}
                    />
                  </div>
                  {/* <div><Trash size={20} color="#50145A" weight="fill"  onClick={handleDeleteClick}/></div> */}
                </div>
              )}
            </div>
            <div class="flex gap-4 flex-row justify-between">
              <div className="modal_buttons_section1t">
                <button
                  className={`modal_btns1t ${activeTab === 0 ? "active" : ""}`}
                  onClick={() => handleTabClick(0)}
                >
                  Basic Information
                </button>
                <button
                  className={`modal_btns2t ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => handleTabClick(1)}
                >
                  Roles
                </button>
                <button
                  className={`modal_btns3t ${activeTab === 2 ? "active" : ""}`}
                  onClick={() => handleTabClick(2)}
                >
                  Organisation
                </button>
              </div>
              {userData?.[0]?.DR_Name == "Admin" && (
                <button
                  className="common_btn"
                  onClick={() => {
                    resetPassword();
                  }}
                >
                  Reset Password
                </button>
              )}
            </div>
          </div>
          <div className="tabs_user_details_layout">
            {activeTab === 0 && (
              <div>
                <BasicInfoDetail
                  isNew={isNew}
                  errors={errors}
                  empData={empData}
                  setEmpData={setEmpData}
                  edit={editMode}
                  handleEditClick={handleEditClick}
                  teamsData={teamsData}
                  teamNames={teamNames}
                />
              </div>
            )}
            {activeTab === 1 && (
              <div>
                <RolesDetails
                  isNew={isNew}
                  errors={errors}
                  roles={roles}
                  empData={empData}
                  setEmpData={setEmpData}
                  edit={editMode}
                  handleEditClick={handleEditClick}
                  teamsData={teamsData}
                  teamNames={teamNames}
                />
              </div>
            )}
            {activeTab === 2 && (
              <div>
                {" "}
                <OrganisationDetail
                  loading={loading}
                  isNew={isNew}
                  errors={errors}
                  empData={empData}
                  setEmpData={setEmpData}
                  edit={editMode}
                  handleEditClick={handleEditClick}
                  teamsData={teamsData}
                  teamNames={teamNames}
                />{" "}
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "24px",
              }}
            >
              {loading && <CircularProgress style={{ color: "purple" }} />}
              {!loading && activeTab !== 2 && (
                <button className="common_btn" onClick={handleNextButtonClick}>
                  Next
                </button>
              )}
              {!loading && activeTab === 2 && !isNew && (
                <button className="common_btn" onClick={handleNextButtonClick}>
                  Update Details
                </button>
              )}
              {!loading && activeTab === 2 && isNew && (
                <button className="common_btn" onClick={handleNextButtonClick}>
                  Submit
                </button>
              )}
            </div>
          </div>
          {showDeleteModal && (
            <div className="modal-overlay">
              <div className="delete-modal">
                <p style={{ fontSize: "16px", color: "#000", fontWeight: 500 }}>
                  Are you sure you want to delete this user?
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#676767",
                    fontWeight: 400,
                  }}
                >
                  Once deleted, cannot be retrieved back
                </p>
                <div className="sure_btns">
                  <button className="sure_ok" onClick={handleConfirmDelete}>
                    Ok
                  </button>
                  <button className="sure_cancel" onClick={handleCancelDelete}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default UserDeatilScreen;
