import React from 'react'
import "../Styles/MasterDataTab1Content1.css";

function MasterDataTab1Content() {
  return (

    <div>
      <div className='main-div'>
        <div className='mainD'>
         <div className='ContainerD'>
               <div className='main-user-infoD'>
                     <div className='Customer-detail-row1'>

                         <div className='user-textfield-fields'>
                             <div className='labelM'>Customer Name</div>
                             <div><input className='input123D' type='text' id="fullName" name="customername" placeholder=''/></div> 
                         </div>
                         <div className='user-textfield-fields'>
                             <div className='labelM'>Project Code </div>
                             <div><input className='input123D' type='text' id="" name='' placeholder=""/></div>
                         </div>
                         <div className='user-textfield-fields'>
                             <div className='labelM'>Project Name </div>
                             <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                         </div>
                     </div>

                     <div className='Customer-detail-row2'>

                         <div className='user-textfield-fields'>
                             <div className='labelM'>Org Num</div>
                             <div><input className='input123D' type='text' id="" name='' /></div>
                         </div>
                         <div className='user-textfield-fields'>
                             <div className='labelM'>Customer Type</div>
                             <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                         </div>
                         <div className='user-textfield-fields'>
                             <div className='labelM'>Outsourcing Sub Processor </div>
                             <div><input className='input123D' type='text' id="" name='' placeholder=''/></div>
                         </div>
                     </div>  
                 </div>            
         </div> 
     </div>

   </div>  



<div className='main-div1'>
    <div className='mainD'>
        <div className='ContainerD1'>
            <div className='main-user-infoD1'>
                <div className='Customer-detail-row11'>

                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Cost Center</div>
                        <div><input className='input123D1' type='text' id="fullName" name="customername" placeholder=''/></div> 
                    </div>
                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Account Owner</div>
                        <div><input className='input123D1' type='text' id="" name='' placeholder=""/></div>
                    </div>
                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Authorised Accountant </div>
                        <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                    </div>
                </div>
                <div className='Customer-detail-row21'>

                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Country</div>
                        <div><input className='input123D1' type='text' id="" name='' /></div>
                    </div>
                    <div className='user-textfield-fields1'>
                        <div className='labelM'>ERP System</div>
                        <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                    </div>
                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Industry</div>
                        <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                    </div>
                </div>  
                <div className='Customer-detail-row21'>

                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Price Aggrement</div>
                        <div><input className='input123D1' type='text' id="" name='' /></div>
                    </div>
                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Parent Account</div>
                        <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                    </div>
                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Bank Remittance Type</div>
                        <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                    </div>

                    <div className='Customer-detail-row21'>

                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Account Status</div>
                        <div><input className='input123D1' type='text' id="" name='' /></div>
                    </div>
                    <div className='user-textfield-fields1'>
                        <div className='labelM'>Termination Date </div>
                        <div><input className='input123D1' type='text' id="" name='' placeholder=''/></div>
                    </div>
                  
                    </div>  
                </div>  
            </div>            
        </div> 
        </div>  
       
    </div>

    <div className='flex float-right gap-1'>
    <div className='modal_button_fieldmd23'><button className='common-btnmd' >CANCEL</button></div> 
    <div className='modal_button_fieldmd23'><button className='common_btnmd' >NEXT</button></div>   
    </div>

 
   
</div>

  )
}

export default MasterDataTab1Content















































































































  

    