import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
/*Css file */
import "../Styles/Dashboard.css";
/*Components */
import GraphPage from "./GraphPage";
import MultipleCircularProgress from "../components/MultipleCircularProgress";
import DashboardGraph5 from "./Dashboard_graph_5";
import DashBoard8 from "./DashBoard8";
import DashboardTopCopm from "./DashboardTopCopm";

function DashBoardConsultantsLayout() {
  return (
    <div>
      <div className="main-dda">
        <div className="main-dd-row">
          <div className="main-dd-row1">
            <DashboardTopCopm />
            <div>{<DashboardGraph5 />}</div>
            <div>{<MultipleCircularProgress />}</div>
          </div>
          <div className="main-dd-row2">
            <div>{<GraphPage />}</div>
            <div>{<DashBoard8 />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoardConsultantsLayout;
