import React, { useState } from 'react'
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../Styles/Roles.css"

function Roles() {
    const [status, setStatus] = useState(10);
    const handleChange = (event) => {
        setStatus(event.target.value);
      };
  return (
    <div className='Roles_layout'>
        <div className='Roles_row_fiels'>
            <div className='Roles_row'>
                <div className='Roles_row__label'>Designation</div>
                <div className='select_box'>
                <Box sx={{ minWidth:  600}}>
                    <FormControl fullWidth >
                      <Select
                        style={{ background: "white" }}
                        value={status}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Choose Designation</MenuItem>
                        <MenuItem value={20}>Scheduled</MenuItem>
                        <MenuItem value={30}>Active</MenuItem>
                        <MenuItem value={40}>Expired</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
            </div>
            <div className='Roles_row'>
                <div  className='Roles_row__label'>Role</div>
                <div className='select_box'>
                <Box sx={{ minWidth:  600}}>
                    <FormControl fullWidth >
                      <Select
                        style={{ background: "white" }}
                        value={status}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Choose Role</MenuItem>
                        <MenuItem value={20}>Scheduled</MenuItem>
                        <MenuItem value={30}>Active</MenuItem>
                        <MenuItem value={40}>Expired</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
            </div>
        </div>
        <div className='Roles_row_fiels'>
        <div className='Roles_row'>
                <div  className='Roles_row__label'>Team</div>
                <div className='select_box'>
                <Box sx={{ minWidth:  600}}>
                    <FormControl fullWidth >
                      <Select
                        style={{ background: "white" }}
                        value={status}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Choose Team</MenuItem>
                        <MenuItem value={20}>Scheduled</MenuItem>
                        <MenuItem value={30}>Active</MenuItem>
                        <MenuItem value={40}>Expired</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
            </div>
            <div className='Roles_row'>
                <div  className='Roles_row__label'>Sub Team</div>
                <div className='select_box'>
                <Box sx={{ minWidth:  600}}>
                    <FormControl fullWidth >
                      <Select
                        style={{ background: "white" }}
                        value={status}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Choose Sub Team</MenuItem>
                        <MenuItem value={20}>Scheduled</MenuItem>
                        <MenuItem value={30}>Active</MenuItem>
                        <MenuItem value={40}>Expired</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Roles