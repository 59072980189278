import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import NavSub from "../components/NavSub";
import ToastComponent from "../components/ToastComponent";
import "../Styles/Capacity.css";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
const Capacity2 = () => {
  const { usersList, teamNames } = useContext(UserContext);
  const { token, userData, logout } = useContext(AuthContext);
  const toastRef = useRef();
  const [budgetData, setBudgetData] = useState([]);
  const [budgetPrevYear, setBudgetPrevYear] = useState([]);
  const [budgetNextYear, setBudgetNextYear] = useState([]);
  console.log(teamNames?.filter((t) => t.status == true));
  const [capacityData, setCapacityData] = useState([]);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 for 1-12
  const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1; // If Jan, go to Dec
  const twoMonthsAgo =
    currentMonth <= 2 ? 12 + (currentMonth - 2) : currentMonth - 2; // Two months before current month
  const getCapacityByUserAndMonth = (user, monthNumber) => {
    const userCapacity = capacityData.find(
      (data) =>
        data.AssginedTo === user.EM_ID &&
        parseInt(data.CreatedMonth) === monthNumber
    );
    return userCapacity ? parseInt(userCapacity.TotalTime) || 0 : 0;
  };

  const calculateTotalCapacity = (user) => {
    const months = Array.from({ length: 12 }, (_, index) => index + 1); // [1, 2, ..., 12]
    return months.reduce(
      (total, monthNumber) =>
        total + getCapacityByUserAndMonth(user, monthNumber),
      0
    );
  };

  // const getYTDForUser = (user) => {
  //   const userBudget = budgetData.find((data) => data.empid === user.EM_EmpID);
  //   console.log(budgetData);
  //   return userBudget ? parseInt(userBudget.ytd) || 0 : 0;
  // };
  const getYTDForUser = (user) => {
    // Find the user's budget data
    const userBudget = budgetData.find((data) => data.empid === user.EM_EmpID);
    
    if (!userBudget) return 0; // Return 0 if user data is not found
  
    // Get the current month (1 for January, 2 for February, etc.)
    const currentMonth = new Date().getMonth() + 1;
  
    // Sum the budget values from January to the current month
    let ytdValue = 0;
    for (let month = 1; month <= currentMonth; month++) {
      const monthName = new Date(0, month - 1).toLocaleString('default', { month: 'short' }).toLowerCase();
      ytdValue += userBudget[monthName] || 0;
    }
  
    return ytdValue;
  };
  
  const getMTDForUser = (user) => {
    return getCapacityByUserAndMonth(user, previousMonth);
  };

  const getBudgetedMtdForUser = (user) => {
    const currentMonth = new Date().getMonth();
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1; // If Jan (0), previous month is Dec (11)
    const monthNames = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "decs",
    ];
    const previousMonthName = monthNames[previousMonth];
    const budgetDataForUser = budgetData.find(
      (data) => data.empid === user.EM_EmpID
    );
    return budgetDataForUser
      ? parseInt(budgetDataForUser[previousMonthName]) || 0
      : 0;
  };

  const getForecastMtdForUser = (user, month, budgetPrevYear) => {
    const months = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "decs",
    ];
    const currentMonthIndex = new Date().getMonth(); // 0 (Jan) to 11 (Dec)
    const monthIndex = months.indexOf(month.toLowerCase());
    if (monthIndex < 0) {
      return 0;
    }
    const userBudget = budgetData?.find(
      (budget) => budget.empid === user.EM_EmpID
    );
    const userBudgetNextYear = budgetNextYear?.find(
      (budget) => budget.empid === user.EM_EmpID
    ); // Dummy next year budget data
    if (!userBudget && !userBudgetNextYear) {
      return 0; // Return 0 if no matching budget data is found for the user
    }
    const isNextYearMonth = monthIndex < currentMonthIndex;
    const budgetForMonth = isNextYearMonth
      ? userBudgetNextYear
        ? userBudgetNextYear[months[monthIndex]]
        : 0
      : userBudget
      ? userBudget[months[monthIndex]]
      : 0;
    if (monthIndex === currentMonthIndex) {
      const previousMonthIndex =
        currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
      const previousCapacity = getCapacityByUserAndMonth(
        user,
        previousMonthIndex + 1
      );
      return (budgetForMonth || 0) - previousCapacity;
    }
    const capacitiesUntilMonth = [];
    for (let i = 0; i <= 11; i++) {
      // Loop through all months of the year
      const capacity = getCapacityByUserAndMonth(user, i + 1); // Get capacity for the month
      if (capacity) {
        capacitiesUntilMonth.push(capacity);
      }
    }
    const averageCapacity =
      capacitiesUntilMonth.length > 0
        ? capacitiesUntilMonth.reduce((acc, cap) => acc + cap, 0) /
          capacitiesUntilMonth.length
        : 0;
    if (currentMonthIndex === 0) {
      return budgetForMonth || 0;
    }
    return parseFloat((budgetForMonth || 0) - averageCapacity).toFixed(1);
  };

  function getNextSixMonths() {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Decs",
    ];
    const currentDate = new Date();
    const currentMonthIndex = currentDate.getMonth(); // 0 (Jan) to 11 (Dec)
    const nextSixMonths = [];
    for (let i = 0; i < 6; i++) {
      const monthIndex = (currentMonthIndex + i) % 12;
      nextSixMonths.push(monthNames[monthIndex]);
    }
    return nextSixMonths;
  }
  const nextSixMonths = getNextSixMonths();
  const tableStyle = {
    borderCollapse: "collapse",
    // width: '100%',
    marginTop: "20px",
    marginBottom: "20px",
    width: "fit-content",
  };

  const thTdStyle = {
    padding: "20px",
    backgroundColor: "white",
    textAlign: "right",
    width: "fit-content",
  };
  const firstThStyle = {
    ...thTdStyle,
    borderTopLeftRadius: "10px",
  };

  const lastThStyle = {
    ...thTdStyle,
    borderTopRightRadius: "10px",
  };

  const thTdLeftAlignStyle = {
    ...thTdStyle,
    textAlign: "left",
  };

  const trStyle = {
    borderTop: "1px solid #ddd",
    padding: "20px",
  };

  const tdPaddingStyle = {
    padding: "20px",
    textAlign: "right",
    borderRight: "1px solid #D9D9D9",
    width: "100px",
  };

  const fetchBudgetData = async () => {
    try {
      const response = await fetch(
        `${userApiUrl}/GetLatestBudget?Year=${currentYear}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error(
          `Failed to fetch budget data. Status: ${response.status}`
        );
      }

      const data = await response.json();
      setBudgetData(data);
      setBudgetNextYear(data);
    } catch (error) {
      console.error("Error fetching budget data:", error);
    }
  };
  const currentYear = new Date().getFullYear().toString();
  const fetchPrevBudgetData = async () => {
    try {
      const response = await fetch(
        `${userApiUrl}/GetLatestBudget?Year=${currentYear}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error(
          `Failed to fetch budget data. Status: ${response.status}`
        );
      }
      const data = await response.json();
      setBudgetPrevYear(data);
    } catch (error) {
      console.error("Error fetching budget data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const url = `${taskApiUrl}/GetCapacityUtilisation?CreatedYear=${currentYear}`;
      const response = await fetch(url, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setCapacityData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchBudgetData();
    fetchPrevBudgetData();
    fetchData();
  }, []);

  const [selectedTeam, setSelectedTeam] = useState(null);
  const teams = teamNames.filter(
    (team) =>
      team.superTeamId === 0 && team?.status == true && team?.type == "T"
  );
  const subteams = selectedTeam
    ? teamNames.filter(
        (team) => team.superTeamId === selectedTeam && team?.status == true
      )
    : teamNames.filter(
        (team) => team.superTeamId !== 0 && team?.status == true
      );
  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value ? Number(event.target.value) : null);
  };
  const calculateSubteamTotals = (subteamId) => {
    const filteredUsers = usersList.filter(
      (user) => Number(user.STM_Subteam) === subteamId
    );

    // Initialize totals object
    const totals = {
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 0,
      oct: 0,
      nov: 0,
      dec: 0,
      actualHoursYTD: 0,
      budgetHoursYTD: 0,
      utilizationYTD: 0,
      actualHoursMTD: 0,
      budgetHoursMTD: 0,
      utilizationMTD: 0,
    };

    filteredUsers.forEach((user) => {
      // Accumulate monthly capacities
      for (let month = 1; month <= 12; month++) {
        const monthName = new Date(0, month - 1)
          .toLocaleString("default", { month: "short" })
          .toLowerCase();
        totals[monthName] += getCapacityByUserAndMonth(user, month);
      }

      // Accumulate YTD and MTD values
      totals.actualHoursYTD += calculateTotalCapacity(user);
      totals.budgetHoursYTD += getYTDForUser(user);
      totals.actualHoursMTD += getMTDForUser(user);
      totals.budgetHoursMTD += getBudgetedMtdForUser(user);
    });

    // Calculate utilization
    totals.utilizationYTD =
      totals.budgetHoursYTD > 0
        ? ((totals.actualHoursYTD / totals.budgetHoursYTD) * 100).toFixed(0)
        : 0;
    totals.utilizationMTD =
      totals.budgetHoursMTD > 0
        ? ((totals.actualHoursMTD / totals.budgetHoursMTD) * 100).toFixed(0)
        : 0;

    return totals;
  };
  const [subteamTotals, setSubteamTotals] = useState({
    jan: 0,
    feb: 0,
    mar: 0,
    apr: 0,
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 0,
    oct: 0,
    nov: 0,
    dec: 0,
    actualHoursYTD: 0,
    budgetHoursYTD: 0,
    utilizationYTD: 0,
    actualHoursMTD: 0,
    budgetHoursMTD: 0,
    utilizationMTD: 0,
  });

  useEffect(() => {
    if (selectedTeam) {
      setSubteamTotals(calculateSubteamTotals(selectedTeam));
    }
  }, [selectedTeam, usersList, capacityData, budgetData]);
  const totalRowStyle = {
    backgroundColor: "#f2f2f2",
    fontWeight: "bold",
  };

  const totalCellStyle = {
    padding: "20px",
    textAlign: "right",
    borderRight: "1px solid #D9D9D9",
    width: "100px",
  };

  return (
    <div>
      <NavSub />
      <div className="Capacity_layout">
        <ToastComponent ref={toastRef} timeout={4000} />
        <div className="Capacity_head">
          <select onChange={handleTeamChange} value={selectedTeam || ""}>
            <option value="">All Team</option>
            {teams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
        {subteams.map((subteam) => (
          <>
            <div className="Capacity_head">
              <h1 className="team_name">{subteam.name}</h1>
            </div>

            <table style={tableStyle}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colSpan="3" className="YTD-hours-table-styling ">
                    YTD Hours
                  </th>
                  <th colSpan="3" className="YTD-hours-table-styling">
                    MTD Hours
                  </th>
                  <th
                    colSpan={nextSixMonths.length}
                    className="YTD-hours-table-styling "
                  >
                    Forecast Capacity for Next 6 Months
                  </th>
                </tr>
                <div style={{ margin: "10px" }}></div>
                <tr>
                  <th style={firstThStyle}>Name</th>
                  <th style={{ ...thTdStyle, textAlign: "left" }}>Team</th>
                  <th style={thTdStyle}>Jan</th>
                  <th style={thTdStyle}>Feb</th>
                  <th style={thTdStyle}>Mar</th>
                  <th style={thTdStyle}>Apr</th>
                  <th style={thTdStyle}>May</th>
                  <th style={thTdStyle}>Jun</th>
                  <th style={thTdStyle}>Jul</th>
                  <th style={thTdStyle}>Aug</th>
                  <th style={thTdStyle}>Sep</th>
                  <th style={thTdStyle}>Oct</th>
                  <th style={thTdStyle}>Nov</th>
                  <th style={thTdStyle}>Dec</th>
                  <th style={thTdStyle}>Actual Hours YTD</th>
                  <th style={thTdStyle}>Budget Hours YTD</th>
                  <th style={thTdStyle}>Utilization YTD</th>
                  <th style={thTdStyle}>Actual Hours MTD</th>
                  <th style={thTdStyle}>Budget Hours MTD</th>
                  <th style={thTdStyle}>Utilization MTD</th>
                  {nextSixMonths.map((month) => (
                    <th key={month} style={thTdStyle}>
                      {month}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {usersList
                  .filter((user) => Number(user.STM_Subteam) === subteam.id)
                  .map((user, index) => {
                    const resolvedTeamName = teamNames?.find(
                      (team) => team?.id === parseFloat(user?.STM_Team)
                    )?.name;

                    return (
                      <tr
                        key={user.EM_ID}
                        style={{
                          ...trStyle,
                          backgroundColor:
                            index % 2 === 0 ? "#f9f9f9" : "white",
                        }}
                      >
                        <td style={{ ...tdPaddingStyle, textAlign: "left" }}>
                          {user.EM_FirstName} {user.EM_LastName}
                        </td>
                        <td style={{ ...tdPaddingStyle, textAlign: "left" }}>
                          {resolvedTeamName}
                        </td>
                        {Array.from(
                          { length: 12 },
                          (_, month) => month + 1
                        ).map((month) => (
                          <td key={month} style={tdPaddingStyle}>
                            {Number(
                              getCapacityByUserAndMonth(user, month)
                            )?.toFixed(2) || "0.00"}
                          </td>
                        ))}
                        <td style={tdPaddingStyle}>
                          {Number(calculateTotalCapacity(user))?.toFixed(2) ||
                            "0.00"}
                        </td>
                        <td style={tdPaddingStyle}>
                          {Number(getYTDForUser(user))?.toFixed(2) || "0.00"}
                        </td>
                        <td style={tdPaddingStyle}>
                          {Number(getYTDForUser(user)) > 0
                            ? (
                                (Number(calculateTotalCapacity(user)) /
                                  Number(getYTDForUser(user))) *
                                100
                              ).toFixed(0)
                            : "0"}
                          %
                        </td>
                        <td style={tdPaddingStyle}>
                          {Number(getMTDForUser(user))?.toFixed(2) || "0.00"}
                        </td>
                        <td style={tdPaddingStyle}>
                          {Number(getBudgetedMtdForUser(user))?.toFixed(2) ||
                            "0.00"}
                        </td>
                        <td style={tdPaddingStyle}>
                          {Number(getBudgetedMtdForUser(user)) > 0
                            ? (
                                (Number(getMTDForUser(user)) /
                                  Number(getBudgetedMtdForUser(user))) *
                                100
                              ).toFixed(0)
                            : "0"}
                          %
                        </td>
                        {nextSixMonths.map((month) => (
                          <td key={month} style={tdPaddingStyle}>
                            {Number(
                              getForecastMtdForUser(user, month)
                            )?.toFixed(2) || "0.00"}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                <tr
                  style={{
                    ...trStyle,
                    backgroundColor: "#50145a",
                    fontWeight: "700",
                    color: "white",
                  }}
                >
                  <td style={tdPaddingStyle}>Total</td>
                  <td style={tdPaddingStyle}></td>
                  {Array.from({ length: 12 }, (_, month) => month + 1).map(
                    (month) => {
                      const total = usersList
                        .filter(
                          (user) => Number(user.STM_Subteam) === subteam.id
                        )
                        .reduce(
                          (sum, user) =>
                            sum +
                              Number(getCapacityByUserAndMonth(user, month)) ||
                            0,
                          0
                        );

                      return (
                        <td key={month} style={tdPaddingStyle}>
                          {total.toFixed(2)}
                        </td>
                      );
                    }
                  )}
                  <td style={tdPaddingStyle}>
                    {usersList
                      .filter((user) => Number(user.STM_Subteam) === subteam.id)
                      .reduce(
                        (sum, user) =>
                          sum + Number(calculateTotalCapacity(user)) || 0,
                        0
                      )
                      .toFixed(2)}
                  </td>
                  <td style={tdPaddingStyle}>
                    {usersList
                      .filter((user) => Number(user.STM_Subteam) === subteam.id)
                      .reduce(
                        (sum, user) => sum + Number(getYTDForUser(user)) || 0,
                        0
                      )
                      .toFixed(2)}
                  </td>
                  <td style={tdPaddingStyle}>
                    {usersList
                      .filter((user) => Number(user.STM_Subteam) === subteam.id)
                      .reduce((sum, user) => {
                        const utilization =
                          Number(getYTDForUser(user)) > 0
                            ? (
                                (Number(calculateTotalCapacity(user)) /
                                  Number(getYTDForUser(user))) *
                                100
                              ).toFixed(0)
                            : "0";
                        return sum + Number(utilization) || 0;
                      }, 0)
                      .toFixed(0)}
                    %
                  </td>
                  <td style={tdPaddingStyle}>
                    {usersList
                      .filter((user) => Number(user.STM_Subteam) === subteam.id)
                      .reduce(
                        (sum, user) => sum + Number(getMTDForUser(user)) || 0,
                        0
                      )
                      .toFixed(2)}
                  </td>
                  <td style={tdPaddingStyle}>
                    {usersList
                      .filter((user) => Number(user.STM_Subteam) === subteam.id)
                      .reduce(
                        (sum, user) =>
                          sum + Number(getBudgetedMtdForUser(user)) || 0,
                        0
                      )
                      .toFixed(2)}
                  </td>
                  <td style={tdPaddingStyle}>
                    {(() => {
                      const totalBudgetedMTD = usersList
                        .filter(
                          (user) => Number(user.STM_Subteam) === subteam.id
                        )
                        .reduce(
                          (sum, user) =>
                            sum + Number(getBudgetedMtdForUser(user)) || 0,
                          0
                        );
                      const totalMTD = usersList
                        .filter(
                          (user) => Number(user.STM_Subteam) === subteam.id
                        )
                        .reduce(
                          (sum, user) => sum + Number(getMTDForUser(user)) || 0,
                          0
                        );

                      return totalBudgetedMTD > 0
                        ? ((totalMTD / totalBudgetedMTD) * 100).toFixed(0)
                        : "0";
                    })()}
                    %
                  </td>
                  {nextSixMonths.map((month) => (
                    <td key={month} style={tdPaddingStyle}>
                      {Number(
                        usersList
                          .filter(
                            (user) => Number(user.STM_Subteam) === subteam.id
                          )
                          .reduce(
                            (sum, user) =>
                              sum +
                                Number(getForecastMtdForUser(user, month)) || 0,
                            0
                          )
                      ).toFixed(2)}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </>
        ))}
      </div>
    </div>
  );
};
export default Capacity2;
