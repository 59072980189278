import React from "react";
import LookUpTabs from "../components/LookUpTabs";
import CustomerMasterNav2 from "./CustomerMasterNav2";
import NavSub from "../components/NavSub";
import Dashboard from "./Dashboard";

function LookUpPage() {
  return (
    <div>
      {/*
     
       <Dashboard />
      
          
     */}
      <NavSub />
      <LookUpTabs />
    </div>
  );
}

export default LookUpPage;
