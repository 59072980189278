import React from "react";
import "../Styles/SignUpPage.css"
import "react-datepicker/dist/react-datepicker.css";
function BasicInfo() {
  return (
    <div>
    <form class="flex flex-col my-4 mx-auto " style={{display:"flex",flexDirection:"column",gap:"24px"}}>
        <div class="flex gap-4">
          <div>
          <div className='Roles_row__label'>Employee Full Name *</div>
            <div class="w-fill h-hug  gap-8">
              <input
                type="text"
                placeholder="Enter Full Name"
                className='textfield_organisation_deatils1'
                // class="bg-custom-gray w-400 h-10 px-8 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
              />
            </div>
          </div>
          <div>
          <div className='Roles_row__label'>Employee Id *</div>
            <div class="w-fill h-hug  gap-8">
              <input
                type="text"
                placeholder="Enter Employee ID"
                className='textfield_organisation_deatils1'
                // class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
              />
            </div>
          </div>
        </div>
       
        <div class="flex gap-4">
          <div>
          <div className='Roles_row__label'>Joining Date</div>
          <div>  <input type='date'                className='textfield_organisation_deatils1'
/></div>  
           </div>
          <div>
          <div className='Roles_row__label'>Termination Date</div>
            <div><input type='date'                className='textfield_organisation_deatils1'
 /></div>
          </div>
        </div>
        <div class="flex gap-4">
          <div>
          <div className='Roles_row__label'>User Name*</div>
            <div>
            <input
              type="text"
              placeholder="Enter User Name"
              className='textfield_organisation_deatils1'

            //   class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            />
            </div>
          </div>
          <div>
          <div className='Roles_row__label'>Domain</div>
            <div>
            <input
              type="text"
              placeholder="Domain"
              className='textfield_organisation_deatils1'

            //   class="bg-custom-gray w-full h-10 px-4 py-4 rounded-lg font-poppins text-base font-normal leading-27 text-left text-custom-gray-border"
            />
            </div>
          </div>
        </div>
      </form>
      <div  style={{color:"#8C8C8C", fontFamily: "Poppins", fontSize: "14px", fontStyle: "normal", fontWeight: 500 ,lineHeight: "normal",letterSpacing:"0.02em" ,display:"flex",flexDirection:"column",gap:"8px"}} >
        <div>*Automatically Set Password</div>
        <div>Require this user to change password when they first login</div>
      </div>
    </div>
  );
}
export default BasicInfo;
