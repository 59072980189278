import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import "../Styles/Roles.css";
import { Box } from "@mui/system";
import { FormControl } from "@mui/base";
import { MenuItem, Select, TextField } from "@mui/material";

function RolesDetails({
  edit,
  roles,
  isNew,
  empData,
  setEmpData,
  teamNames,
  teamsData,
  errors,
}) {
  const [employeeData, setEmployeeData] = useState(empData[0]);
  // //console.log(roles, empData, teamsData);

  const [selectedTeam, setSelectedTeam] = useState(empData[0]?.STM_Team);

  const [selectedRole, setSelectedRole] = useState(empData[0]?.DRM_ID);
  const [selectedDesignation, setSelectedDesignation] = useState(
    empData[0]?.EM_Designation
  );
  const [selectedSubTeam, setSelectedSubTeam] = useState(
    empData[0]?.STM_Subteam
  );
  const editMode = isNew ? true : edit;

  // //console.log(selectedTeam)
  const subteam = teamsData[0]?.filter((team) => team?.superTeamId === 2);
  // //console.log(subteam)

  return (
    <div className="Roles_layout">
      <div className="Roles_row_fiels">
        <div className="Roles_row">
          <div className="Roles_row__label">
            Designation <span className="span-icon-star">*</span>
          </div>
          <div className="select_box_Detail">
            {/* <input 
                      type="text"
                      placeholder="Sr. Associate"
                      className={`textfield_organisation_deatils ${editMode ? 'enabled' : ''}`}
                      disabled={!editMode}
                      style={{ cursor: editMode ? 'text' : 'not-allowed' }}
                      value={employeeData?.EM_Designation}

                  /> */}
            <Box sx={{ minWidth:  600}}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  select
                  className="textfield_organisation_deatils1"
                  disabled={!editMode}
                  // style={{ background: "white" }}
                  value={selectedDesignation}
                  displayEmpty
                  onChange={(e) => {
                    setSelectedDesignation(e.target.value);
                    const updatedEmpData = {
                      ...empData,
                      0: {
                        ...empData["0"],
                        EM_Designation: parseInt(e.target.value, 10),
                      },
                    };
                    setEmpData(updatedEmpData);
                  }}
                >
                  {roles
                    ?.filter((role) => role.Type !== "R")
                    .map((data) => (
                      <MenuItem key={data.Id} value={data.Id}>
                        {data.Name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Box>
          </div>
          {!selectedDesignation && errors && (
            <div style={{ color: "red" }}>Designation is required</div>
          )}
        </div>
        <div className="Roles_row">
          <div className="Roles_row__label">
            Role <span className="span-icon-star">*</span>
          </div>
          <div className="select_box_Detail">
            {/* <input 
                      type="text"
                      placeholder="Consultant"
                      className={`textfield_organisation_deatils ${editMode ? 'enabled' : ''}`}
                      disabled={!editMode}
                      style={{ cursor: editMode ? 'text' : 'not-allowed' }}
                  /> */}
            <Box sx={{ minWidth:  600}}>
              <FormControl fullWidth>
                <TextField
                  select
                  className="textfield_organisation_deatils1"
                  fullWidth
                  disabled={!editMode}
                  // style={{ background: "white" }}
                  value={selectedRole}
                  displayEmpty
                  onChange={(e) => {
                    setSelectedRole(e.target.value);
                    const updatedEmpData = {
                      ...empData,
                      0: {
                        ...empData["0"],
                        DRM_ID: e.target.value,
                      },
                    };
                    setEmpData(updatedEmpData);
                  }}
                >
                  {roles
                    ?.filter((role) => role.Type === "R")
                    .map((data) => (
                      <MenuItem key={data.Id} value={data.Id}>
                        {data.Name}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Box>
            {!selectedRole && errors && (
              <div style={{ color: "red" }}>Role is required</div>
            )}
          </div>
        </div>
      </div>
      <div className="Roles_row_fiels">
        <div className="Roles_row">
          <div className="Roles_row__label">
            Team <span className="span-icon-star">*</span>
          </div>
          <div className="select_box_Detail">
            {/* <input 
                      type="text"
                      placeholder="Team 5"
                     className={`textfield_organisation_deatils ${editMode ? 'enabled' : ''}`}
                      disabled={!editMode}
                      style={{ cursor: editMode ? 'text' : 'not-allowed' }}
                  /> */}
            <Box sx={{ minWidth:  600}}>
              <FormControl fullWidth>
                <TextField
                  select
                  className="textfield_organisation_deatils1"
                  fullWidth
                  value={selectedTeam}
                  onChange={(e) => {
                    setSelectedTeam(e.target.value);
                    const updatedEmpData = {
                      ...empData,
                      0: {
                        ...empData["0"],
                        STM_Team: e.target.value,
                        STM_Subteam: "",
                      },
                    };

                    setEmpData(updatedEmpData);
                  }}
                >
                  {teamsData[0]
                    ?.filter(
                      (team) => team?.status === true && team?.type == "T"
                    )
                    ?.map((data) => (
                      <MenuItem value={data.id}>{data.name}</MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Box>
            {!selectedTeam && errors && (
              <div style={{ color: "red" }}>Team is required</div>
            )}
          </div>
        </div>
        <div className="Roles_row">
          <div className="Roles_row__label">
            Sub Team <span className="span-icon-star">*</span>
          </div>
          <div className="select_box_Detail">
            {/* <input 
                      type="text"
                      placeholder="5B"
                       className={`textfield_organisation_deatils ${editMode ? 'enabled' : ''}`}
                      disabled={!editMode}
                      style={{ cursor: editMode ? 'text' : 'not-allowed' }}
                  /> */}
            <Box sx={{ minWidth:  600}}>
              <FormControl fullWidth>
                <TextField
                  select
                  className="textfield_organisation_deatils1"
                  fullWidth
                  disabled={!editMode}
                  // style={{ background: "white" }}
                  value={selectedSubTeam}
                  displayEmpty
                  onChange={(e) => {
                    setSelectedSubTeam(e.target.value);
                    const updatedEmpData = {
                      ...empData,
                      0: {
                        ...empData["0"],
                        STM_Subteam: e.target.value,
                      },
                    };

                    setEmpData(updatedEmpData);
                  }}
                >
                  {teamsData[0]
                    ?.filter(
                      (team) =>
                        team.superTeamId == parseFloat(selectedTeam) &&
                        team.status === true
                    )
                    ?.map((data) => (
                      <MenuItem value={data.id}>{data.name}</MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </Box>
            {!selectedSubTeam && errors && (
              <div style={{ color: "red" }}>Sub Team is required</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RolesDetails;
