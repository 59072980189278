import React, { useState, useImperativeHandle, forwardRef } from "react";
import { CheckCircle, XCircle } from '@phosphor-icons/react';
import WarningIcon from '@mui/icons-material/Warning';
import '../Styles/Toast.css';
import { Link } from "react-router-dom";

function ToastComponent({ timeout = 45000 }, ref) {
    const [show, setShow] = useState(false);
    const [toastMsg, setToastMsg] = useState("");
    const [toastLink, setToastLink] = useState("");
    const [iconType, setIconType] = useState("success");

    useImperativeHandle(ref, () => ({
        showToast(msg = "", type = "", customDelay) {
            setShow(true);
            setToastMsg(msg);
            setIconType(type);
            setTimeout(() => {
                setShow(false);
            }, customDelay || timeout);
        },
        showToast2(msg = "", link = "", type = "", customDelay) {
            setShow(true);
            setToastMsg(msg);
            // setToastLink(link)
            setIconType(type);
            setTimeout(() => {
                setShow(false);
            }, customDelay || timeout);
        }
    }));

    const getIcon = () => {
        switch (iconType) {
            case "success":
                return <CheckCircle color="#029F4A" size={24} weight='fill' />;
            case "error":
                return <XCircle color="#FF2121" size={24} weight='fill' />;
            case "warning":
                return <WarningIcon color="yellow" size={24} />;
            default:
                return "";
        }
    };

    return (
        <div>

            <div className={`react-toast ${show ? "show" : ""}`}>
                <div className={`react-toast-container ${iconType}`} style={{maxWidth:'80vw' ,display:"flex",alignItems:"flex-start",justifyContent:"center",gap:"4px"}}>

                    <span role="img" aria-label="Icon" style={{ position: 'relative' }}>
                        {getIcon()}
                    </span>
                    {toastMsg}                     <Link to='/cart'>{toastLink}</Link>

                </div>
            </div>
        </div>
    );
}

export default forwardRef(ToastComponent);
