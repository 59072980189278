import React, { useContext, useState, useEffect } from "react";
import DashBoardLayout from "../components/DashBoardLayout";
import DashBoardQaLayout from "../components/DashBoardQaLayout";
import DashBoardConsultantsLayout from "../components/DashBoardConsultantsLayout";
import NavSub from "../components/NavSub";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";

function Dashboard() {
  const { userData } = useContext(AuthContext);
  const userRole = userData?.[0]?.DR_Name;
  //const userRole = "Admin";
  const { usersList, teamNames } = useContext(UserContext);
  //console.log(usersList);
  //console.log(teamNames);
  let layoutComponent;
  if (
    userRole === "Admin" ||
    userRole === "Managing Director" ||
    userRole === "Process Lead" ||
    userRole === "Department Lead"
  ) {
    layoutComponent = <DashBoardLayout />;
  } else if (userRole === "Quality Consultant") {
    layoutComponent = <DashBoardQaLayout />;
  } else if (userRole === "Consultant") {
    layoutComponent = <DashBoardConsultantsLayout />;
  }

  return (
    <>
      <NavSub />
      {layoutComponent}
    </>
  );
}

export default Dashboard;
