import React, { useContext, useEffect, useState, useRef } from "react";
import "../Styles/WeekelyTimesheet2.css";
import "../Styles/WeekelyTimesheet1.css";
import "../Styles/ContentWeek.css";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "./ToastComponent";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function Weeklytimesheet2History() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token, logout } = useContext(AuthContext);
  const toastRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${taskApiUrl}/GetViewForceDataHistory`, {
          headers: {
            Authorization: `Bearer ${token}`, // Assuming token is defined or passed in
          },
        });
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        setData(
          json.map((item) => ({
            details: {
              label1: item.costCenter,
              label2: item.activity,
              label3: item.fullName,
              label4: item.timeId,
              label5: item.weekNumber.toString(),
              label6: item.dateIncurred,
              label7: item.registeredTime.toString(),
              label8: item.billableTime.toString(),
              label9: item.mainPriceAgreement,
              label10: item.activityPriceAgreement,
              label11: item.mainActivity,
              label12: item.description,
              label13: item.status,
              label14: calculateControlCheck(item),
              label15: item.blobref ? "OK" : "CHECK",
              label16: checkMainPriceAgreement(
                item.mainPriceAgreement,
                item.description
              ),
              label17: check10Hrs(item.registeredTime, item.billableTime),
              label18: checkHourlyRate(
                item.activityPriceAgreement,
                item.description
              ),
              label19: checkRTBT(item.registeredTime, item.billableTime),
              label20: checkMainActivity(item.mainActivity, item.status),
              label21: checkComments(item.description, item.status),
              label22: checkApproval(item.registeredTime, item.billableTime),
            },
          }))
        );
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateControlCheck = (item) => {
    const isOK =
      check10Hrs(item.registeredTime, item.billableTime) === "OK" &&
      checkHourlyRate(item.activityPriceAgreement, item.description) === "OK" &&
      checkRTBT(item.registeredTime, item.billableTime) === "OK" &&
      checkMainActivity(item.mainActivity, item.status) === "OK" &&
      checkComments(item.description, item.status) === "OK" &&
      checkApproval(item.registeredTime, item.billableTime) === "OK" &&
      checkAdhoc(item.description) === "OK";

    return isOK ? "OK" : "CHECK";
  };

  const checkAdhoc = (description) => {
    if (description === "Outsourcing" || description === "Internal") {
      return "OK";
    } else {
      return "CHECK";
    }
  };

  const checkMainPriceAgreement = (mainPriceAgreement, description) => {
    if (
      description &&
      description.includes("0801 Financial Statment and Tax report")
    ) {
      return "OK";
    } else {
      return mainPriceAgreement === "Fixed Price" ? "OK" : "CHECK";
    }
  };

  const check10Hrs = (registeredTime, billableTime) => {
    if (registeredTime > 10 || billableTime > 10) {
      return "CHECK";
    } else {
      return "OK";
    }
  };

  const checkHourlyRate = (activityPriceAgreement, description) => {
    if (
      activityPriceAgreement === "Hourly Rate" &&
      !description.includes("0801 Financial statement and tax report")
    ) {
      return "CHECK";
    } else {
      return "OK";
    }
  };

  const checkRTBT = (registeredTime, billableTime) => {
    if (registeredTime === 0 && billableTime !== 0) {
      return "CHECK";
    } else {
      return "OK";
    }
  };

  const checkMainActivity = (mainActivity, status) => {
    if (!mainActivity) {
      return "OK";
    } else if (mainActivity === "Outsourcing" || mainActivity === "Internal") {
      return "OK";
    } else if (status === "Approved" || status === "Invoiced") {
      return "OK";
    } else {
      return "CHECK";
    }
  };

  const checkComments = (description, status) => {
    if (
      (description === "0406 Consulting - Financial" && status === "") ||
      (description === "0501 Consulting - Administration" && status === "")
    ) {
      return "CHECK";
    } else {
      return "OK";
    }
  };

  const checkApproval = (registeredTime, billableTime) => {
    if (registeredTime === 0 && billableTime !== 0) {
      return "CHECK";
    } else {
      return "OK";
    }
  };

  const EmptyRow = ({ columns, index }) => {
    const className = index % 2 === 0 ? "Content2-week2" : "Content2-week22";
    return (
      <div className={className}>
        <div className="slider2-container">
          {columns.map((column, index) => (
            <div className="Content2-w21" key={index}>
              <div className="Content2-weeklabel11">&nbsp;</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const generateEmptyRows = (numRows, numColumns) => {
    const emptyRows = [];
    for (let i = 0; i < numRows; i++) {
      const emptyDetail = [];
      for (let j = 0; j < numColumns; j++) {
        emptyDetail.push({ label: `Empty-${i}-${j}` });
      }
      emptyRows.push({ details: emptyDetail });
    }
    return emptyRows;
  };

  const numRowsToAdd = 19;
  const numColumns = data[0]?.details.length || 22;
  const emptyRows = generateEmptyRows(numRowsToAdd, numColumns);

  return (
    <div className="main-newcontent">
      <div className="Content-week22">
        <ToastComponent ref={toastRef} timeout={4000} />
        <div className="slider-container22">
          <div className="Content-w21">
            <div className="Content-weeklabel112">COST CENTRE</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">ACTIVITY</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">FULL NAME</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">TIME ID</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">WEEK NO</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">DATE INCURRED</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">REGISTERED TIME</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">BILLABLE TIME</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">MAIN PRICE AGREEMENT</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">ACTIVITY PRICE AGREEMENT</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">MAIN ACTIVITY</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">DESCRIPTION</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">STATUS</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">CONTROL CHECK</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">STR CHECK 1</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">10 HRS CHECK 2</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">HOURLY RATE CHECK 3</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">RT- BT CHECK 4</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">MAIN ACTIVITY CHECK 5</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">COMMENTS CHECK 6</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">APPROVAL CHECK 7</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel11">ADHOC CHECK 8</div>
          </div>
        </div>
        {data.map((item, index) => (
          <div
            key={index}
            className={index % 2 === 0 ? "Content2-week2" : "Content2-week22"}
          >
            <div className="slider2-container">
              {Object.entries(item.details).map(([key, value]) => (
                <div className="Content2-w21" key={key}>
                  <div
                    style={{
                      color:
                        value === "OK"
                          ? "green"
                          : value === "CHECK"
                          ? "orange"
                          : "",
                    }}
                    className="Content2-weeklabel11"
                  >
                    {value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {emptyRows.map((row, index) => (
          <EmptyRow key={index} columns={row.details} index={index} />
        ))}
      </div>
    </div>
  );
}

export default Weeklytimesheet2History;
