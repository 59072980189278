import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
const fetchTeamsData = async (token) => {
  try {
    const response = await fetch(`${userApiUrl}/GetTeams?`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    if (response.status === 401) {
      return "Unauthorised";
    }
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
export const createTeam = async (teamName, subTeams, token) => {
  //console.log(teamName, subTeams)
  try {
    const response = await fetch(`${userApiUrl}/CreateTeam`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Name: teamName.trim(),
        Type: "T",
        SuperTeamId: "0",
      }),
    });
    const data = await response.json();
    if (response.status === 401) {
      return "Unauthorised";
    }
    //console.log(data)
    if (!data) {
      // alert('Could not create Team');
      return false;
    }
    const teams = await fetchTeamsData(token);
    if (subTeams?.length > 0) {
      const subteamsNotCreated = [];

      for (let sub = 0; sub < subTeams.length; sub++) {
        try {
          const response = await fetch(`${userApiUrl}/CreateTeam`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              Name: subTeams[sub].trim(),
              Type: "S",
              SuperTeamId: teams[teams.length - 1]?.id,
            }),
          });

          const subData = await response.json();

          if (!subData) {
            // If subData is falsy, it means the subteam couldn't be created
            subteamsNotCreated.push(subTeams[sub]);
          }
        } catch (error) {
          // Handle any errors that occur during the fetch operation
          console.error("Error creating subteam:", error);
        }
      }

      if (subteamsNotCreated.length > 0) {
        // Alert the subteams that couldn't be created
        alert(
          `The following subteams couldn't be created because they already exist: ${subteamsNotCreated.join(
            ", "
          )}`
        );
      }
    }

    fetchTeamsData(token);
    return data; // Return the created team data
  } catch (error) {
    console.error("Error creating team:", error);
    return null; // Return null or handle error accordingly
  }
};

export const createSubTeam = async (id, teamName, token) => {
  try {
    const response = await fetch(`${userApiUrl}/CreateTeam`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Name: teamName.trim(),
        Type: "S",
        SuperTeamId: id,
      }),
    });
    const data = await response.json();
    if (response.status === 401) {
      return "Unauthorised";
    }
    // //console.log(data);
    return data;
  } catch (error) {
    console.error("Error creating team:", error);
    return null; // Return null or handle error accordingly
  }
};
export const editTeam = async (id, teamName, token) => {
  try {
    const response = await fetch(`${userApiUrl}/UpdateTeam?`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Name: teamName.trim(),
        Type: "T",
        SuperTeamId: "0",
        Id: id,
        Status: 1,
      }),
    });
    const data = await response.json();
    if (response.status === 401) {
      return "Unauthorised";
    }
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error creating team:", error);
    return null; // Return null or handle error accordingly
  }
};

export const deleteTeam = async (id, teamName, token) => {
  try {
    const response = await fetch(`${userApiUrl}/UpdateTeam?`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Name: teamName.trim() + " ",
        Type: "T",
        SuperTeamId: "0",
        Id: id,
        Status: 0,
      }),
    });
    const data = await response.json();
    if (response.status === 401) {
      return "Unauthorised";
    }
    //console.log(data);
    fetchTeamsData(token);
    return data;
  } catch (error) {
    console.error("Error creating team:", error);
    return null; // Return null or handle error accordingly
  }
};

export const deleteSubTeam = async (id, teamId, teamName, token) => {
  try {
    const response = await fetch(`${userApiUrl}/UpdateTeam?`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Name: teamName.trim() + " ",
        Type: "S",
        SuperTeamId: teamId,
        Id: id,
        Status: 0,
      }),
    });
    const data = await response.json();
    if (response.status === 401) {
      return "Unauthorised";
    }
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error creating team:", error);
    return null; // Return null or handle error accordingly
  }
};

export const editSubTeam = async (id, teamId, teamName, token) => {
  try {
    const response = await fetch(`${userApiUrl}/UpdateTeam?`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        Name: teamName.trim(),
        Type: "S",
        SuperTeamId: teamId,
        Id: id,
        Status: 1,
      }),
    });
    const data = await response.json();
    if (response.status === 401) {
      return "Unauthorised";
    }
    //console.log(data);
    return data;
  } catch (error) {
    console.error("Error creating team:", error);
    return null; // Return null or handle error accordingly
  }
};
