import React from 'react'
import "../Styles/Run.css";
// import HampiNavbarNew from '../components/HampiNavbarNew'
// import HampiNavbar1 from '../components/HampiNavbar1'
// import HampiNavbar from '../components/HampiNavbar'
// import Nav from '../components/Nav'
// import NavMain from '../components/NavMain'
import ManagementReports from '../pages/ManagementReports'
// import NavSub from '../components/NavSub'
// import Weeklytimesheet1 from '../components/Weeklytimesheet1'
// import Weeklytimesheet2 from '../components/Weeklytimesheet2'
// import Footerweek from '../components/Footerweek'
// import STRtabs from '../components/STRtabs'
// import STRNav from '../components/STRNav';
// import VoucherEntrynew from '../pages/VoucherEntrynew';
// import VoucherEntrynewfirst from './VoucherEntrynewfirst';
import VoucherEntry from './VoucherEntry';
import Mergenew from './Mergenew';

import VoucherEntryReview from '../pages/VoucherEntryReview';

import { Merge } from '@mui/icons-material';

import STRActivity from '../pages/STRActivity';


function Run() {
  return (
    <div className='Run_Container'>
        {/* <HampiNavbarNew /> */}
        {/* <HampiNavbar1 /> */}
        {/* <HampiNavbar /> */}
        {/* <Nav /> */}
        {/* <NavSub /> */}
        {/* <NavMain /> */}
        {/* <Weeklytimesheet1 /> */}
        {/* <Weeklytimesheet2 /> */}
        {/* <Footerweek /> */}
        {/* <STRNav /> */}
        {/* <STRtabs /> */}
        {/* <VoucherEntrynew /> */}
        {/* <VoucherEntrynewfirst /> */}

         {/* <ManagementReports /> */}
         {/* <Mergenew /> */}

         {/* <VoucherEntryReview /> */}
         {/* <VoucherEntry /> */}

         <STRActivity  /> 


      
    </div>
  )
}

export default Run



// const [primaryDropdownName, setPrimaryDropdownName] = useState('CUSTOMER MASTER DATA');

// const handlesecondaryItemClick = (itemName) => {
//   setPrimaryDropdownName(itemName);
// };