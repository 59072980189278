import React, { useState } from "react";
import "../Styles/Modal.css";
import "../Styles/SignUpPage.css";
import { Briefcase, User, UsersThree, XCircle } from "@phosphor-icons/react";
import BasicInfo from "../components/BasicInfo";
import Roles from "../components/Roles";
import "../Styles/common.css";
import Organisation from "../components/Organisation";
// import Nav from "../components/Nav";
// import HampiNavbarNew from "../components/HampiNavbarNew";
// import HampiNavbar from "../components/HampiNavbar";
import HampiNavbar1 from "./CustomerMasterTabs";
import HampiNavbarNew from "../components/HampiNavbarNew";
import RegisterUserDetails from "./UserSetupAndRoles";

function SignUpPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleNextButtonClick = () => {
    const nextTab = activeTab + 1;
    setActiveTab(nextTab >= 0 && nextTab <= 2 ? nextTab : 0);
  };
  return (
    <div>
       
        <RegisterUserDetails />
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="modalLayout">
            <div className="modalLayout_contact">
              <div className="modalLayoutMain_contact">
                <div className="modalStyling">
                  <div className="flex justify-between">
                    <div className="font-poppins text-lg font-semibold leading-36 text-left text-custom-purple">
                      {" "}
                      + ADD NEW USER
                    </div>
                    <XCircle
                      onClick={closeModal}
                      size={32}
                      weight="fill"
                      className="text-rgba-243-237-255"
                    />
                  </div>
                  <div class="flex gap-4 mt-3 flex-col ">
                    <div className="modal_buttons_section">
                      <button
                        className={`modal_btns ${
                          activeTab === 0 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(0)}
                      >
                        <User weight="fill" />
                        BASIC Information
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 1 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(1)}
                      >
                        <Briefcase weight="fill" />
                        Roles
                      </button>
                      <button
                        className={`modal_btns ${
                          activeTab === 2 ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(2)}
                      >
                        <UsersThree weight="fill" />
                        ORGANISATION
                      </button>
                    </div>

                    <div>
                      {activeTab === 0 && (
                        <div>
                          <BasicInfo />
                        </div>
                      )}
                      {activeTab === 1 && (
                        <div>
                          <Roles />
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div>
                          <Organisation />
                        </div>
                      )}
                    </div>

                    <div className="next_btn_field">
                      <button className="common_btn"    onClick={handleNextButtonClick}>Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
     
    </div>
  );
}

export default SignUpPage;
