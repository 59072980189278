import React from "react";

/*Css file */
import "../Styles/Dashboard.css";
/*Components */
import GraphPage from "./GraphPage";

import MultipleCircularProgress from "../components/MultipleCircularProgress";

import DashboardGraph4 from "./Dashboard_graph_4";
import DashboardGraph5 from "./Dashboard_graph_5";
import DashboardGraph6 from "./Dashboard_graph_6";
import DashboardGraph7 from "./Dashboard_graph_7";
import DashBoard8 from "./DashBoard8";
import DashboardTopCopm from "./DashboardTopCopm";

function DashBoardLayout() {
  return (
    <div>
      <div className="main-dda">
        <DashboardTopCopm />
        <div className="main-dd-row">
          <div className="main-dd-row1">
            <div>{<GraphPage />}</div>
            <div>{<MultipleCircularProgress />}</div>
            <div>{<DashboardGraph7 />}</div>
          </div>
          <div className="main-dd-row2">
            <div>{<DashboardGraph4 />}</div>
            <div>{<DashboardGraph5 />}</div>
            <div>{<DashboardGraph6 />}</div>
            <div>{<DashBoard8 />}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoardLayout;
