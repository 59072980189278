import React, { useContext, useEffect, useRef, useState } from "react";
import "../Styles/CustomerMasterTabs.css";
import {
  ArrowLeft,
  Briefcase,
  PencilSimpleLine,
  PlusCircle,
  Trash,
  User,
  UsersThree,
} from "@phosphor-icons/react";
import CustomerTeam1 from "../pages/CustomerTeam1";
import CustomerMasterData from "./CustomerMasterData";
import { useNavigate, useParams } from "react-router-dom";
// import MasterDataTab4Content from './MasterDataTab4Content';
// import MasterDataTab3Content from './MasterDataTab3Content';
// import MasterDataTab2Content from './MasterDataTab2Content';
// import MasterDataTab1Content from './MasterDataTab1Content';
import MasterData1New from "./MasterData1New";
import MasterData2New from "./MasterData2New";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import MasterData3New from "./MasterData3New";
import MasterData4New from "./MasterData4New";
import { CircularProgress, TextField } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "../components/ToastComponent";
import { Info } from "phosphor-react";
function CustomerMasterTabs() {
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const { id } = useParams();
  const [currentDateTime, setCurrentDateTime] = useState();
  const [customerData, setCustomerData] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [standardTimeData, setStandardTimeData] = useState([]);
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState();
  const fetchProjectList = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllProjectList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setProjectData(data);
      } else {
        console.error("Failed to fetchProjectList data");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    fetchProjectList();
  }, []);
  const getRemittanceTypeName = (id) => {
    const project = projectData?.find(
      (project) => parseInt(project.Id) === parseInt(id)
    );
    //console.log(id);
    return project ? project?.Name : "";
  };
  const handleEditClick = () => {
    handleTabClick(0);
    setIsEditing(!isEditing);
  };
  // const handleNextButtonClick = () => {
  //   const nextTab = activeTab + 1;
  //   setActiveTab(nextTab >= 0 && nextTab <= 3 ? nextTab : 0);
  // };
  const [voucherActivities, setVoucherActivities] = useState([]);
  const handleNextButtonClick = () => {
    let newErrors = {};

    if (activeTab === 0) {
      const requiredFields = [
        "Name",
        "ProjectNumber",
        "TaskName",
        "OrgNum",
        "CostCenter",
        "Status",
      ];
      requiredFields.forEach((field) => {
        if (!customerData[field]) {
          newErrors[field] = `${field} is required`;
        } else if (
          ["ProjectNumber", "OrgNum"].includes(field) &&
          /[^0-9]/.test(customerData[field])
        ) {
          newErrors[field] = `${field} should only contain numbers`;
        }
      });

      if (
        customerData.OutsourcingSubprocessor === "Synergy" &&
        !customerData.RemittanceType
      ) {
        newErrors.RemittanceType =
          "RemittanceType is required when OutsourcingSubprocessor is Synergy";
      }
      if (customerData.OutsourcingSubprocessor === "Others") {
        // No additional validation for "Others"
      } else if (customerData.OutsourcingSubprocessor === "") {
        newErrors.OutsourcingSubprocessor =
          "Outsourcing Sub Processor is required";
      }
    } else if (activeTab === 1) {
      // Validation for tab 1
      if (customerData.OutsourcingSubprocessor === "Synergy") {
        const requiredFields = [
          "OutsourcingStdt",
          "ReportingFrequency",
          "VoucherFrequency",
          "Department",
          "HOD",
          "LeadId",
          "OwnerId",
          "SubLead",
          "SubOwner",
          "SubProcLead",
          "SubQC",
        ];
        requiredFields.forEach((field) => {
          if (!customerData[field]) {
            newErrors[field] = `${field} is required`;
          }
        });
      }
    } else if (activeTab === 2) {
      if (customerData.OutsourcingSubprocessor === "Synergy") {
        customerData.standardActivitiesTime
          .filter(
            (activity) =>
              activity.AL_Type === "Vouchers" && activity.AL_Status === "1"
          )
          .forEach((activity) => {
            const value = String(activity.SATTime || "");
            if (!value.trim()) {
              newErrors[
                `SATTime${activity.SATActivity}`
              ] = `Please enter ${activity.AL_Name}`;
            } else if (!/^\d*$/.test(value)) {
              newErrors[
                `SATTime${activity.SATActivity}`
              ] = `${activity.AL_Name} should only contain numbers`;
            }
          });
      }
    } else if (activeTab === 3) {
      if (customerData.OutsourcingSubprocessor === "Synergy") {
        customerData.standardActivitiesTime
          .filter(
            (activity) =>
              activity.AL_Type === "Reporting Activities" &&
              activity.AL_Status === "1"
          )
          .forEach((activity) => {
            const value = String(activity.SATTime || "");
            if (!value.trim()) {
              newErrors[
                `SATTime${activity.SATActivity}`
              ] = `Please enter ${activity.AL_Name}`;
            } else if (!/^\d*$/.test(value)) {
              newErrors[
                `SATTime${activity.SATActivity}`
              ] = `${activity.AL_Name} should only contain numbers`;
            }
          });
      }
    }

    // Update state with errors or move to the next tab
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setActiveTab(activeTab + 1);
    }
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setCustomerData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  const determineAccountStatus = (terminationDate) => {
    if (!terminationDate) {
      return "Active";
    } else {
      const today = new Date();
      const termination = new Date(terminationDate);
      const threeMonthsAfterTermination = new Date(
        termination.getFullYear(),
        termination.getMonth() + 3,
        termination.getDate()
      );

      if (today < threeMonthsAfterTermination) {
        return "In Progress";
      } else {
        return "Inactive";
      }
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setCustomerData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  //   const status = determineAccountStatus(value);
  //   setCustomerData((prevData) => ({
  //     ...prevData,
  //     Status: status,
  //   }));
  // };
  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //     if (name.startsWith("SATTime")) {
  //     const activityIndex = parseInt(name.replace("SATTime", ""));
  //     setCustomerData((prevData) => ({
  //       ...prevData,
  //       standardActivitiesTime: prevData.standardActivitiesTime.map((activity, i) => {
  //         if (i === activityIndex + 1) {
  //           return {
  //             ...activity,
  //             SATTime: value
  //           };
  //         }
  //         return activity;
  //       })
  //     }));
  //   } else {
  //     setCustomerData((prevData) => ({
  //       ...prevData,
  //       [name]: value,
  //     }));
  //   }
  //     const status = determineAccountStatus(value);
  //   setCustomerData((prevData) => ({
  //     ...prevData,
  //     Status: status,
  //   }));
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: !value.trim(),
  //   }));
  // };

  const handleInputChangeVoucher = (e, identifier) => {
    const { name, value } = e.target;

    // Update standardActivitiesTime in customerData
    setCustomerData((prevData) => ({
      ...prevData,
      standardActivitiesTime: prevData.standardActivitiesTime.map(
        (activity) => {
          if (activity.SATActivity === identifier) {
            return {
              ...activity,
              SATTime: value, // Update SATTime for the specific activity
            };
          }
          return activity;
        }
      ),
    }));

    // Determine and update status based on value
    const status = determineAccountStatus(value);
    setCustomerData((prevData) => ({
      ...prevData,
      Status: status, // Update status in customerData
    }));

    // Update errors based on validation
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        !value.trim() || /^\d*\.?\d+$/.test(value)
          ? ""
          : "Please enter a valid number", // Adjusted regex to allow decimal numbers
    }));
  };

  const [displayValues, setDisplayValues] = useState({
    DepartmentName: "",
    HODName: "",
  });
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [filteredHODOptions, setFilteredHODOptions] = useState([]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === "OutsourcingSubprocessor" && value === "") {
      newValue = ""; // Set to empty string if "Others" is selected
    }
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // const status = determineAccountStatus(value);
    // setCustomerData((prevData) => ({
    //   ...prevData,
    //   Status: status,
    // }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: !value.trim(),
    }));
    if (name === "CostCenter") {
      const selectedDepartment = department.find((dept) => dept.id === value);
      if (selectedDepartment) {
        setCustomerData((prev) => ({
          ...prev,
          CostCenter: value,
          Department: selectedDepartment.id,
          HOD: selectedDepartment.id,
        }));
        setDisplayValues({
          DepartmentName: selectedDepartment.name,
          HODName: selectedDepartment.owner_EM_ID,
        });
        setFilteredDepartments([selectedDepartment]);
        setFilteredHODOptions([selectedDepartment]);
      } else {
        setCustomerData((prev) => ({
          ...prev,
          CostCenter: value,
          Department: "",
          HOD: "",
        }));
        setDisplayValues({
          DepartmentName: "",
          HODName: "",
        });
        setFilteredDepartments([]);
        setFilteredHODOptions([]);
      }
    } else {
      setCustomerData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleChange = (e) => {
    setSelectedCountry(e.target.value);
    setCustomerData((prevData) => ({
      ...prevData,
      Country: e.target.value,
    }));
  };
  const extractDate = (datetimeString) => {
    const datePart = datetimeString?.split(" ")[0]; // Splitting by space and taking the first part (date)
    return datePart;
  };
  const updateCustomerData = async (id, newData) => {
    try {
      const response = await fetch(`${clientApiUrl}/UpdateClient`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newData),
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      if (data === true) {
        toastRef.current.showToast(
          "Customer data updated successfully",
          "success"
        );
        // Wait for the toast to be displayed
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay if needed
        // Fetch updated customer data
        fetchCustomerData();
        // Navigate to next page
        navigate("/CustomerMasterData");
      } else {
        toastRef.current.showToast("Failed to update customer data", "error");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString();
  };
  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString();
  };
  useEffect(() => {
    fetchCustomerData(id, setCustomerData);
  }, [id, setCustomerData]);
  const { userData } = useContext(AuthContext);
  const user = userData?.[0];
  const handleUpdate = async () => {
    let newErrors = {};

    if (customerData.OutsourcingSubprocessor === "Synergy") {
      customerData.standardActivitiesTime
        .filter(
          (activity) =>
            activity.AL_Type === "Vouchers" && activity.AL_Status === "1"
        )
        .forEach((activity) => {
          const value = String(activity.SATTime || "");
          if (!value.trim()) {
            newErrors[
              `SATTime${activity.SATActivity}`
            ] = `Please enter ${activity.AL_Name}`;
          } else if (!/^\d+(\.\d+)?$/.test(value)) {
            newErrors[
              `SATTime${activity.SATActivity}`
            ] = `${activity.AL_Name} should only contain numbers`;
          }
        });

      customerData.standardActivitiesTime
        .filter(
          (activity) =>
            activity.AL_Type === "Reporting Activities" &&
            activity.AL_Status === "1"
        )
        .forEach((activity) => {
          const value = String(activity.SATTime || "");
          if (!value.trim()) {
            newErrors[
              `SATTime${activity.SATActivity}`
            ] = `Please enter ${activity.AL_Name}`;
          } else if (!/^\d+(\.\d+)?$/.test(value)) {
            newErrors[
              `SATTime${activity.SATActivity}`
            ] = `${activity.AL_Name} should only contain numbers`;
          }
        });
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});

      const updatedData = {
        id: customerData.Id,
        name: customerData.Name,
        custType: customerData.CustType,
        OrgNum: customerData.OrgNum,
        costCenter: customerData.CostCenter,
        OwnerEMId: customerData.OwnerEMId,
        Accountant_EMId: customerData.Accountant_EMId,
        type: customerData.Type,
        Country: customerData.Country,
        ERPSystem: customerData.ERPSystem,
        industry: customerData.Industry,
        priceAgreement: customerData.PriceAgreement,
        parentAccount: customerData.ParentAccount,
        terminationDate: customerData.TerminationDate,
        status: customerData.Status,
        OutsourcingSubprocessor:
          customerData.OutsourcingSubprocessor === "Others"
            ? ""
            : customerData.OutsourcingSubprocessor,
        ProjectId: customerData.Id,
        projectNumber: customerData.ProjectNumber,
        taskName: customerData.TaskName,
        outsourcingStdt: customerData.OutsourcingStdt,
        outsourcingEddt: customerData.OutsourcingEddt,
        reportingFrequency: customerData.ReportingFrequency,
        reportingDt: customerData.ReportingDt,
        remittanceType: customerData.RemittanceType,
        voucherFrequency: customerData.VoucherFrequency,
        department: customerData.Department,
        hod: customerData.HOD,
        ownerId: customerData.OwnerId,
        leadId: customerData.LeadId,
        subOwner: customerData.SubOwner,
        subQC: customerData.SubQC,
        subProcLead: customerData.SubProcLead,
        subLead: customerData.SubLead,
        modifieddt: getCurrentDate(),
        ModifyBy: user?.EM_ID,
        AccountInfoUpdateDate:
          customerData.AccountInfoUpdateDate === "Yes"
            ? getCurrentDateTime()
            : "",
        standardActivitiesTime: customerData.standardActivitiesTime,
      };

      await updateCustomerData(customerData.Id, updatedData);
      await fetchCustomerData(customerData.Id, setCustomerData);
    }
  };

  const fetchCountry = async () => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllCountry`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setCustomerData(data);
        setCountries(data);
      } else {
        console.error("Failed to fetch customer data");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  const getCountryName = (countryId) => {
    const country = countries.find((c) => c.Id === countryId);
    return country ? country.Name : ""; // Return the country name if found, otherwise an empty string
  };

  const mapStandardTimeDataWithNames = (standardTimeData, activityData) => {
    const activityMap = {};
    activityData.forEach((activity) => {
      activityMap[activity.AL_ID] = activity.AL_Name;
    });

    return standardTimeData.map((item) => ({
      ...item,
      AL_Name: activityMap[item.SATActivity],
    }));
  };
  const [roles, setRoles] = useState();
  const [designation, setDesignation] = useState();
  const [department, setDepartment] = useState();
  const [empLoading, setEmpLoading] = useState(false);
  const toastRef = useRef();
  const { token, handleAuthExpiry, logout } = useContext(AuthContext);
  const fetchRoles = async () => {
    setEmpLoading(true);
    try {
      const response = await fetch(`${userApiUrl}/GetRole?`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      //console.log(data);
      setRoles(data);
      setEmpLoading(false);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };
  const fetchDepartments = async () => {
    setEmpLoading(true);

    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllDepartment?Page=1&ItemsPerPage=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      const data = await response.json();
      // //console.log(data);
      setDepartment(data);
      setEmpLoading(false);
    } catch (error) {
      setEmpLoading(false);

      console.error("Error fetching data:", error);
    }
  };

  // const filteredDepartments = department?.filter(
  //   (dept) => dept.id === customerData?.CostCenter
  // );
  // const selectedDepartment = filteredDepartments?.find(
  //   (dept) => dept.id === customerData?.Department
  // );

  // const departmentHead = selectedDepartment?.owner_EM_ID || '';

  useEffect(() => {
    fetchRoles();
    fetchDepartments();
  }, [token]);
  const findCostCenterNameById = (id) => {
    const dept = department?.find((d) => d.id === id);
    return dept ? dept.costCenter : "";
  };

  // Function to find cost center id by name
  const findCostCenterIdByName = (name) => {
    const dept = department?.find((d) => d.costCenter === name);
    return dept ? dept.id : "";
  };
  const costCenterValue = isEditing
    ? customerData?.CostCenter
    : findCostCenterNameById(customerData?.CostCenter);
  const [usersList, setUsersList] = useState();
  const fetchDataEmp = async () => {
    try {
      const response = await fetch(`${userApiUrl}/GetAllEmp`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return false;
      }
      const data = await response.json();
      //console.log(data);
      setUsersList(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataEmp();
  }, [token]);
  const fetchActivity = async () => {
    try {
      const response = await fetch(
        `${clientApiUrl}/GetAllActivity?Page=1&ItemsPerPage=100`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setActivityData(data);
        // Map SATActivity to AL_Name
        const standardTimeWithNames = mapStandardTimeDataWithNames(
          standardTimeData,
          data
        );
        setStandardTimeData(standardTimeWithNames);
      } else {
        console.error("Failed to fetch activity data");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    fetchActivity();
  }, []);
  const activity = activityData?.find((item) => item.Id === id);
  const findALNameBySATActivity = (SATActivity) => {
    const matchingSATActivity = activity.StandardTime.find(
      (sat) => sat.SATActivity === SATActivity
    );
    if (matchingSATActivity) {
      const matchingAL = activityData.find(
        (item) => item.AL_ID === matchingSATActivity.SATActivity.toString()
      );
      return matchingAL ? matchingAL.AL_Name : "";
    }
    return "";
  };
  const fetchCustomerData = async (id, setCustomerData) => {
    try {
      const response = await fetch(`${clientApiUrl}/GetAllClient?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        toastRef.current.showToast("Your Session has expired", "error");
        logout();
        return false;
      }
      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        //console.log(id);
        setCustomerData(data[0]);
        setVoucherActivities(data);
      } else {
        console.error("Failed to fetch customer data");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomerData(id, setCustomerData);
  }, [id, setCustomerData]);

  if (!customerData) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <CircularProgress style={{ color: "#50145A" }} />
      </div>
    );
  }
  const selectedUser = usersList?.find(
    (user) => user.EM_ID === customerData.SubOwner
  );

  const getUserFirstName = (emId) => {
    const user = usersList?.find((user) => user.EM_ID === emId);
    return user ? user.EM_FirstName : "";
  };
  const extractDates = (accountInfo) => {
    return accountInfo
      ? accountInfo
          .split(",")
          .map((dateTime) => new Date(dateTime).toLocaleDateString())
          .join(", ")
      : "";
  };
  // const handleSelectChange = (e) => {
  //   const value = e.target.value;
  //   setCustomerData((prevData) => ({
  //     ...prevData,
  //     AccountInfoUpdateDate: value
  //   }));

  //   if (value === 'Yes') {
  //     const currentDate = new Date().toLocaleString();
  //     setCurrentDateTime(currentDate);
  //   } else {
  //     setCurrentDateTime('');
  //   }
  // };
  const handleSelectChange = (e) => {
    const value = e.target.value;
    setCustomerData((prevData) => ({
      ...prevData,
      AccountInfoUpdateDate: value,
    }));

    if (value === "Yes") {
      const currentDate = new Date().toLocaleString();
      setCurrentDateTime(currentDate);
    } else {
      setCurrentDateTime("");
    }
  };
  const selectedDepartment = department?.find(
    (dept) => dept.id === customerData.Department
  );
  const departmentName = selectedDepartment
    ? selectedDepartment.name
    : displayValues.DepartmentName;
  const HodName = selectedDepartment
    ? selectedDepartment.owner_EM_ID
    : displayValues.HODName;
    // Helper function to find the costCenter name based on the selected CostCenter ID
const selectedCostCenter = department?.find(
  (dept) => dept.id === customerData?.CostCenter
)?.costCenter || "Select Cost Center";

console.log(selectedCostCenter); // This will show "02 Fagteam Xledger Oslo" for id "2"

  return (

    <div className="main1t">
      <ToastComponent ref={toastRef} timeout={4000} />
      <div className="Container-nav1t">
        <div className="modal_buttons_section1tt">
          {!isEditing ? (
            <div className="flex justify-between gap-90% w-full">
              <div className="flex gap-4 C-Title">
                <div
                  onClick={() => {
                    navigate("/CustomerMasterData");
                  }}
                >
                  {" "}
                  <ArrowLeft
                    size={20}
                    color="#50145A"
                    weight="fill"
                    cursor="pointer"
                  />{" "}
                </div>
                <div>CUSTOMER DETAIL</div>
              </div>
              <div>
                {" "}
                <div>
                  <PencilSimpleLine
                    size={20}
                    color="#50145A"
                    weight="fill"
                    onClick={handleEditClick}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-4 C-Title">
              <div
                onClick={() => {
                  navigate("/CustomerMasterData");
                }}
              >
                {" "}
                <ArrowLeft
                  size={20}
                  color="#50145A"
                  weight="fill"
                  cursor="pointer"
                />{" "}
              </div>
              <div>EDIT CUSTOMER DETAIL</div>
            </div>
          )}
        </div>
        <div className="flex-row gap-4">
          <div className="modal_buttons_section1t">
            <button
              className={`modal_btns1t ${activeTab === 0 ? "active" : ""}`}
              onClick={!isEditing ? () => handleTabClick(0) : null}
            >
              Basic Information
            </button>
            <button
              className={`modal_btns1t ${activeTab === 1 ? "active" : ""}`}
              onClick={!isEditing ? () => handleTabClick(1) : null}
            >
              Dates and Roles
            </button>
            <button
              className={`modal_btns1t ${activeTab === 2 ? "active" : ""}`}
              onClick={!isEditing ? () => handleTabClick(2) : null}
            >
              Time estimation for vouchers
            </button>
            <button
              className={`modal_btns1t ${activeTab === 3 ? "active" : ""}`}
              onClick={!isEditing ? () => handleTabClick(3) : null}
            >
              Time estimation for reporting activities
            </button>
          </div>
        </div>
      </div>
      <div className="Tabs-props">
        {activeTab === 0 && (
          <div>
            <form class="flex flex-col w-full h-auto  mx-auto gap-4 bg-white  p-4  rounded-lg">
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Customer Name</div>
                  <div class="w-full h-hug  gap-8">
                    <input
                      type="text"
                      name="Name"
                      placeholder="Enter Customer Name"
                      className="textfield_organisation_deatils1"
                      value={customerData.Name}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                    {errors.Name && (
                      <div className="error-message">{errors.Name}</div>
                    )}
                  </div>
                </div>
                <div>
                  <div className="master-label">Project Code</div>
                  <div>
                    <input
                      name="ProjectNumber"
                      className="textfield_organisation_deatils1"
                      placeholder="Enter Project Number"
                      value={customerData.ProjectNumber}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                  {errors.ProjectNumber && errors && (
                    <span className="error-message">
                      Please enter project Code.
                    </span>
                  )}
                </div>
                <div>
                  <div className="master-label">Task Name</div>
                  <div>
                    <input
                      type="text"
                      name="TaskName"
                      className="textfield_organisation_deatils1"
                      placeholder="Enter Task Name"
                      value={customerData.TaskName}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                      // disabled
                    />
                  </div>
                  {errors.TaskName && errors && (
                    <span className="error-message">
                      Please enter Project Name .
                    </span>
                  )}
                </div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Org Num</div>
                  <div class="w-full h-hug  gap-8">
                    <input
                      type="text"
                      name="OrgNum"
                      className="textfield_organisation_deatils1"
                      placeholder="Enter Org Num"
                      value={customerData.OrgNum}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                  {errors.OrgNum && errors && (
                    <span className="error-message">
                      Please enter an organization number.
                    </span>
                  )}
                </div>
                <div>
                  <div className="master-label">Customer Type</div>
                  <div>
                    <input
                      type="text"
                      name="CustType" // Add name attribute with the corresponding field name
                      className="textfield_organisation_deatils1"
                      placeholder="Enter Customer Type"
                      value={customerData.CustType}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
                {isEditing ? (
                  <div>
                    <div className="master-label">
                      Outsourcing Sub Processor
                    </div>
                    <div>
                      <select
                        className="textfield_organisation_deatils1"
                        value={customerData.OutsourcingSubprocessor || ""}
                        onChange={handleInputChange}
                        name="OutsourcingSubprocessor"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          appearance: "none" /* Hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          cursor: "pointer",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                        disabled={!isEditing}
                      >
                        <option value="">Select Sub Processor</option>
                        <option value="Synergy">Synergy</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    {errors.OutsourcingSubprocessor && (
                      <div className="error-message">
                        {errors.OutsourcingSubprocessor}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className="master-label">
                      Outsourcing Sub Processor
                    </div>
                    <div>
                      <input
                        type="text"
                        name="OutsourcingSubprocessor"
                        className="textfield_organisation_deatils1"
                        value={customerData.OutsourcingSubprocessor || ""}
                        onChange={handleInputChange}
                        placeholder="Enter Outsourcing Sub Processor"
                        disabled={!isEditing}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Type</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Type"
                      name="Type"
                      className="textfield_organisation_deatils1"
                      value={customerData.Type}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
            <form class="flex flex-col  my-4 h-auto  mx-auto gap-4 bg-white w-full p-4 rounded-lg">
              <div class="flex gap-4">
              {!isEditing ? (
  <div>
    <div className="master-label">Cost Center</div>
    <div className="w-full gap-8 h-hug">
      <input
        type="text"
        placeholder="Enter Type"
        name="CostCenter"
        className="textfield_organisation_deatils1"
        value={customerData.CostCenterName}
        onChange={handleInputChange}
        disabled={!isEditing}
        style={{
          cursor: isEditing ? "text" : "not-allowed",
          color: isEditing ? "black" : "#A5A5A5",
        }}
      />
    </div>
  </div>
) : (
  <div>
    <div className="master-label">Cost Center</div>
    <div className="flex flex-col">
      <select
        name="CostCenter"
        className="textfield_organisation_deatils1"
        value={customerData.CostCenter}
        onChange={handleInputChange}
        disabled={!isEditing}
        style={{
          cursor: isEditing ? "text" : "not-allowed",
          color: isEditing ? "black" : "#A5A5A5",
          appearance: "none", /* Hide default arrow */
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")`, /* Custom caret-down icon */
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 8px top 45%",
          paddingRight: "24px",
          backgroundSize: "42px", /* Space for the icon */
        }}
      >
        <option value="">Select Cost Center</option>
        {department?.map((dept) => (
          <option key={dept.id} value={dept.id}>
            {dept.costCenter}
          </option>
        ))}
      </select>
      {errors.CostCenter && (
        <span className="error-message">
          Please select a cost center.
        </span>
      )}
    </div>
  </div>
)}

                <div>
                  <div className="master-label">Account Owner</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter  Account Owner"
                      className="textfield_organisation_deatils1"
                      value={customerData.OwnerEMId}
                      name="OwnerEMId"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Authorised Accountant</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter   Authorised Accountant"
                      className="textfield_organisation_deatils1"
                      value={customerData.Accountant_EMId}
                      name="Accountant_EMId"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
              {!isEditing ? (
  <div>
    <div className="master-label">Country</div>
    <div className="w-full gap-8 h-hug">
      <input
        type="text"
        className="textfield_organisation_deatils1"
        placeholder="Enter Country"
        value={customerData.Country}
        name="Country"
        disabled={!isEditing}
        style={{
          cursor: isEditing ? "text" : "not-allowed",
          color: isEditing ? "black" : "#A5A5A5",
        }}
      />
    </div>
  </div>
) : (
  <div>
    <div className="master-label">Country</div>
    <div className="w-full gap-8 h-hug">
      <select
        className="textfield_organisation_deatils1"
        // Extract the country name if customerData.Country contains " - "
        value={
          customerData?.Country?.includes(" - ")
            ? customerData?.Country?.split(" - ")[1] // Extracts the country name after " - "
            : customerData?.Country // Use the whole value if there's no " - "
        }
        onChange={handleInputChange}
        name="Country"
        style={{
          cursor: isEditing ? "text" : "not-allowed",
          color: isEditing ? "black" : "#A5A5A5",
          appearance: "none", /* This is to hide the default arrow */
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right 8px top 45%",
          paddingRight: "24px",
          backgroundSize: "42px",
        }}
      >
        <option value="">Select Country</option>
        {countries.map((country) => (
          <option key={country.Name} value={country.Name}>
            {country.Name}
          </option>
        ))}
      </select>
    </div>
  </div>
)}


                <div>
                  <div className="master-label">ERP System</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter  ERP System"
                      className="textfield_organisation_deatils1"
                      value={customerData.ERPSystem}
                      name="ERPSystem"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Industry</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Industry"
                      className="textfield_organisation_deatils1"
                      value={customerData.Industry}
                      name="Industry"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">Price Aggrement</div>
                  <div class="w-full h-hug  gap-8">
                    <input
                      type="text"
                      placeholder="Enter  Price Aggrement"
                      className="textfield_organisation_deatils1"
                      value={customerData.PriceAgreement}
                      name="PriceAgreement"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="master-label">Parent Account</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Parent Account"
                      className="textfield_organisation_deatils1"
                      value={customerData.ParentAccount}
                      onChange={handleInputChange}
                      name="ParentAccount"
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
                {!isEditing ? (
                  <div>
                    <div className="master-label">Bank Remittance Type</div>
                    <div>
                      <input
                        value={getRemittanceTypeName(
                          customerData.RemittanceType
                        )}
                        onChange={handleInputChange}
                        name="RemittanceType"
                        className="textfield_organisation_deatils12"
                        disabled={!isEditing}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                        }}
                      ></input>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">Bank Remittance Type</div>

                    <div className="gap-8 w-fill h-hug">
                      <select
                        value={customerData.RemittanceType}
                        onChange={handleInputChange}
                        name="RemittanceType"
                        className="textfield_organisation_deatils12"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value="">Select Bank Remittance Type</option>
                        {projectData
                          ?.filter((project) => project.Type === "R")
                          ?.map((project) => (
                            <option key={project.Id} value={project.Id}>
                              {project.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {customerData.OutsourcingSubprocessor === "Synergy" &&
                      customerData.RemittanceType === "" &&
                      errors && (
                        <span className="error-message">
                          Please select Remittance Type
                        </span>
                      )}
                  </div>
                )}
              </div>

              <div class="flex gap-4">
                {!isEditing ? (
                  <div>
                    <div className="master-label">Account Status</div>
                    <div className="w-full gap-8 h-hug">
                      <input
                        type="text"
                        // placeholder="Active"
                        className="textfield_organisation_deatils1"
                        value={customerData.Status}
                        name="Status"
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">Account Status</div>
                    <div className="flex flex-col w-full gap-0 h-hug">
                      <div>
                        <select
                          value={customerData.Status}
                          name="Status"
                          onChange={handleInputChange}
                          className="textfield_organisation_deatils1"
                          style={{
                            cursor: isEditing ? "text" : "not-allowed",
                            color: isEditing ? "black" : "#A5A5A5",
                            appearance:
                              "none" /* This is to hide the default arrow */,
                            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right 8px top 45%",
                            paddingRight: "24px",
                            backgroundSize: "42px",
                            cursor: "pointer",
                          }}
                        >
                          <option value="">Select</option>
                          <option value="Active">Active</option>
                          <option value="Ongoing Termination">
                            Ongoing Termination
                          </option>
                          <option value="Terminated ">Terminated </option>
                        </select>
                      </div>
                      {errors.Status && errors && (
                        <span className="error-message">
                          Please enter Status.
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {!isEditing ? (
                  <div>
                    <div className="master-label">Termination Date</div>
                    <div>
                      <input
                        type="text"
                        // placeholder="30/9/2022"
                        className="textfield_organisation_deatils1"
                        value={customerData.TerminationDate}
                        disabled={!isEditing}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                        }}
                        onChange={handleInputChange}
                        name="TerminationDate"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">Termination Date</div>
                    <div>
                      <input
                        type="date"
                        className="textfield_organisation_deatils1"
                        value={customerData.TerminationDate}
                        onChange={handleInputChange}
                        name="TerminationDate"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                )}

                <div></div>
              </div>
            </form>
            <div className="flex float-right gap-1">
              {/* <div className='modal_button_fieldmd23'><button className='common-btnmd' >CANCEL</button></div>  */}
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={handleNextButtonClick}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 1 && (
          <div>
            <form class="flex flex-col bg-white w-full mx-auto gap-4 p-4  rounded-lg">
              <div className="masterd-title">DATES</div>
              <div class="flex gap-4">
                {!isEditing ? (
                  <div>
                    <div className="master-label">Out Sourcing Start Date</div>
                    <div class="w-fill h-hug  gap-8">
                      <input
                        type="text"
                        placeholder="1-SEP-2022"
                        className="textfield_organisation_deatils12"
                        value={customerData.OutsourcingStdt}
                        name="OutsourcingStdt"
                        disabled={!isEditing}
                        onChange={handleInputChange}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">Out Sourcing Start Date</div>
                    <div class="w-fill h-hug  gap-8">
                      <input
                        type="date"
                        className="textfield_organisation_deatils12"
                        value={customerData.OutsourcingStdt}
                        onChange={handleInputChange}
                        name="OutsourcingStdt"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                        }}
                      />
                      {errors.OutsourcingStdt && errors && (
                        <span className="error-message">
                          Please select Out Sourcing Start Date.
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {!isEditing ? (
                  <div>
                    <div className="master-label">Out Sourcing End Date</div>
                    <div>
                      <input
                        type="text"
                        placeholder=""
                        className="textfield_organisation_deatils12"
                        value={customerData.OutsourcingEddt}
                        onChange={handleInputChange}
                        name="OutsourcingEddt"
                        disabled={!isEditing}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">Out Sourcing End Date</div>
                    <div>
                      <input
                        type="date"
                        placeholder=""
                        className="textfield_organisation_deatils12"
                        value={customerData.OutsourcingEddt}
                        onChange={handleInputChange}
                        name="OutsourcingEddt"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <div className="master-label">Customer Reporting Date</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter Customer Reporting Date"
                      className="textfield_organisation_deatils12"
                      value={extractDate(customerData.ReportingDt)}
                      name="ReportingDt"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                {!isEditing ? (
                  <div>
                    <div className="master-label">
                      Frequency Report To Customer
                    </div>
                    <div class="w-fill h-hug  gap-8">
                      <select
                        value={customerData.ReportingFrequency}
                        onChange={handleInputChange}
                        name="ReportingFrequency"
                        className="textfield_organisation_deatils12"
                        disabled={!isEditing}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                        }}
                      >
                        <option value="">
                          Select Frequency Report To Customer
                        </option>
                        {projectData
                          ?.filter((project) => project.Type === "C") // Filter projects with Type 'C'
                          .map((project) => (
                            <option key={project.Id} value={project.Id}>
                              {project.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">
                      Frequency Report To Customer
                    </div>
                    <div className="gap-8 w-fill h-hug">
                      <select
                        value={customerData.ReportingFrequency}
                        onChange={handleInputChange}
                        name="ReportingFrequency"
                        className="textfield_organisation_deatils12"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value=""> Frequency Report To Customer</option>
                        {projectData
                          .filter((project) => project.Type === "C") // Filter projects with Type 'C'
                          .map((project) => (
                            <option key={project.Id} value={project.Id}>
                              {project.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {errors.ReportingFrequency && errors && (
                      <span className="error-message">
                        Please select ReportingFrequency.
                      </span>
                    )}
                  </div>
                )}
                {!isEditing ? (
                  <div>
                    <div className="master-label">
                      Frequency Voucher Processing
                    </div>
                    <div>
                      <select
                        value={customerData.VoucherFrequency}
                        onChange={handleInputChange}
                        name="VoucherFrequency"
                        className="textfield_organisation_deatils12"
                        disabled={!isEditing}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                        }}
                      >
                        <option value="">Frequency Voucher Processing</option>
                        {projectData
                          .filter((project) => project.Type === "V")
                          .map((project) => (
                            <option key={project.Id} value={project.Id}>
                              {project.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">
                      Frequency Voucher Processing
                    </div>
                    <div>
                      <select
                        value={customerData.VoucherFrequency}
                        onChange={handleInputChange}
                        name="VoucherFrequency"
                        className="textfield_organisation_deatils12"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          cursor: "pointer",
                          color: isEditing ? "black" : "#A5A5A5",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value="">Frequency Voucher Processing</option>
                        {projectData
                          ?.filter((project) => project.Type === "V")
                          ?.map((project) => (
                            <option key={project.Id} value={project.Id}>
                              {project.Name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {errors.VoucherFrequency && errors && (
                      <span className="error-message">
                        Please select VoucherFrequency
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="flex gap-4">
                {!isEditing ? (
                  <div>
                    <div className="master-label">
                      Accounting Info Updates
                      <div className="gap-8 w-fill h-hug">
                        <div className="info-icon">
                          <i className="fas fa-info-circle">
                            <span className="info-icon">
                              <Info />
                            </span>
                          </i>
                          <div className="hover-content">
                            {customerData?.AccountInfo?.split(",")?.map(
                              (date, index) => (
                                <span key={index}>
                                  {new Date(date).toLocaleDateString()}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gap-8 w-fill h-hug">
                      <input
                        // value={customerData.AccountInfo}
                        placeholder="Select Accounting Info Updates"
                        name="AccountInfo"
                        onChange={handleInputChange}
                        className="textfield_organisation_deatils12"
                        disabled={!isEditing}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">
                      Accounting Info Updates{" "}
                      <div className="gap-8 w-fill h-hug">
                        <div className="info-icon">
                          <i className="fas fa-info-circle">
                            <span className="info-icon">
                              <Info />
                            </span>
                          </i>
                          <div className="hover-content">
                            {customerData?.AccountInfo?.split(",")?.map(
                              (date, index) => (
                                <span key={index}>
                                  {new Date(date).toLocaleDateString()}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="gap-8 w-fill h-hug">
                      <select
                        value={customerData.AccountInfoUpdateDate || ""}
                        onChange={handleSelectChange}
                        name="AccountInfoUpdateDate"
                        className="textfield_organisation_deatils12"
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value="">Select Accounting Info Updates</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                )}
                <div>
                  <div className="master-label"></div>
                  <div>
                    <input
                      type="text"
                      placeholder=""
                      className="mt-6 textfield_organisation_deatils1"
                      value={currentDateTime || customerData.AccountInfo}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                </div>
                <div></div>
                <div></div>
              </div>
            </form>
            <form class="flex flex-col  h-auto bg-white w-full my-4 mx-auto gap-4 p-4  rounded-lg">
              <div className="masterd-title">ROLES</div>
              <div className="flex gap-4">
                <div>
                  <div className="master-label">Department</div>
                  <div className="gap-8 w-fill h-hug">
                    {/* {isEditing ? (
            <select
              className="textfield_organisation_deatils12"
              value={customerData.Department}
              name="Department"
              onChange={handleInputChange}
              style={{
                cursor: 'text', color: 'black'
              }}
            >
              <option value="">Select Department</option>
              {filteredDepartments?.map((dept) => (
                <option key={dept.id} value={dept.id}>
                  {dept.name}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              className="textfield_organisation_deatils12"
              value={selectedDepartment?.name || ''}
              name="Department"
              disabled
              style={{
                cursor: 'not-allowed', color: '#A5A5A5'
              }}
            />
          )} */}
                    <input
                      className="textfield_organisation_deatils12"
                      value={departmentName}
                      onChange={handleInputChange}
                      name="DepartmentName"
                      readOnly
                    />
                    {errors.Department && (
                      <span className="error-message">
                        Please select Department.
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <div className="master-label">Department Head</div>
                  <div>
                    {/* {isEditing ? (
            <select
              className="textfield_organisation_deatils12"
              value={customerData.HOD}
              name="HOD"
              onChange={handleInputChange}
              style={{
                cursor: 'text', color: 'black'
              }}
            >
              <option value="">Select Department Head</option>
              {filteredDepartments?.map((dept) => (
                <option key={dept.id} value={dept.id}>
                  {dept.owner_EM_ID}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              className="textfield_organisation_deatils12"
              value={departmentHead}
              name="HOD"
              disabled
              style={{
                cursor: 'not-allowed', color: '#A5A5A5'
              }}
            />
          )} */}
                    <input
                      id="HOD"
                      name="HODName"
                      value={HodName}
                      // value={customerData.Department ? customerData.Department : displayValues.HODName}
                      // value={displayValues.HODName}
                      onChange={handleInputChange}
                      className="textfield_organisation_deatils12"
                      readOnly
                    />
                    {errors.HOD && (
                      <span className="error-message">
                        Please select Department Head.
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                <div>
                  <div className="master-label">View Account Owner</div>
                  <div class="w-fill h-hug  gap-8">
                    <input
                      type="text"
                      placeholder="Enter View Account Owner"
                      className="textfield_organisation_deatils12"
                      value={customerData.OwnerId}
                      name="OwnerId"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                  {errors.OwnerId && errors && (
                    <span className="error-message">
                      Please enter Account Owner.
                    </span>
                  )}
                </div>
                <div>
                  <div className="master-label">View Team Lead</div>
                  <div>
                    <input
                      type="text"
                      placeholder="Enter View Team Lead"
                      className="textfield_organisation_deatils12"
                      value={customerData.LeadId}
                      name="LeadId"
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      style={{
                        cursor: isEditing ? "text" : "not-allowed",
                        color: isEditing ? "black" : "#A5A5A5",
                      }}
                    />
                  </div>
                  {errors.LeadId && errors && (
                    <span className="error-message">
                      Please enter View Team Lead .
                    </span>
                  )}
                </div>
                {!isEditing ? (
                  <div>
                    <div className="master-label">
                      Synergy Accounting Responsible
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="Akshata"
                        className="textfield_organisation_deatils12"
                        value={getUserFirstName(customerData.SubOwner)}
                        name="SubOwner"
                        disabled
                        style={{ cursor: "not-allowed", color: "#A5A5A5" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="master-label">
                      Synergy Accounting Responsible
                    </div>
                    <div class="w-fill h-hug  gap-8">
                      <select
                        className="textfield_organisation_deatils12"
                        value={customerData.SubOwner}
                        name="SubOwner"
                        onChange={handleInputChange}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          cursor: "pointer",
                          color: isEditing ? "black" : "#A5A5A5",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value="">
                          Select Synergy Accounting Consultant
                        </option>
                        {usersList?.map((data, index) => (
                          <option key={data.EM_ID + index} value={data.EM_ID}>
                            {data.EM_FirstName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.SubOwner && errors && (
                      <span className="error-message">
                        Please select Synergy Accounting Responsible.
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="flex gap-4">
                <div>
                  <div className="master-label">
                    Synergy Accounting Consultant
                  </div>
                  <div className="gap-8 w-fill h-hug">
                    {isEditing ? (
                      <select
                        className="textfield_organisation_deatils12"
                        value={customerData.SubQC}
                        name="SubQC"
                        onChange={handleInputChange}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          cursor: "pointer",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value="">
                          Select Synergy Accounting Consultant
                        </option>
                        {usersList?.map((data, index) => (
                          <option key={data.EM_ID + index} value={data.EM_ID}>
                            {data.EM_FirstName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        placeholder="Krishna"
                        className="textfield_organisation_deatils12"
                        value={getUserFirstName(customerData.SubQC)}
                        name="SubQC"
                        disabled
                        style={{ cursor: "not-allowed", color: "#A5A5A5" }}
                      />
                    )}
                  </div>
                  {errors.SubQC && (
                    <span className="error-message">
                      Please select Synergy Accounting Consultant
                    </span>
                  )}
                </div>

                <div>
                  <div className="master-label">Synergy Process Lead</div>
                  <div>
                    {isEditing ? (
                      <select
                        className="textfield_organisation_deatils12"
                        value={customerData.SubProcLead}
                        name="SubProcLead"
                        onChange={handleInputChange}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          cursor: "pointer",
                          paddingRight: "24px",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value="">Select Synergy Process Lead</option>
                        {usersList?.map((data, index) => (
                          <option key={data.EM_ID + index} value={data.EM_ID}>
                            {data.EM_FirstName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        placeholder="Joy"
                        className="textfield_organisation_deatils12"
                        value={getUserFirstName(customerData.SubProcLead)}
                        name="SubProcLead"
                        disabled
                        style={{ cursor: "not-allowed", color: "#A5A5A5" }}
                      />
                    )}
                  </div>
                  {errors.SubProcLead && (
                    <span className="error-message">
                      Please select Synergy Process Lead
                    </span>
                  )}
                </div>

                <div>
                  <div className="master-label">Synergy Department Lead</div>
                  <div>
                    {isEditing ? (
                      <select
                        className="textfield_organisation_deatils12"
                        value={customerData.SubLead}
                        name="SubLead"
                        onChange={handleInputChange}
                        style={{
                          cursor: isEditing ? "text" : "not-allowed",
                          color: isEditing ? "black" : "#A5A5A5",
                          appearance:
                            "none" /* This is to hide the default arrow */,
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E")` /* Custom caret-down icon */,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 8px top 45%",
                          paddingRight: "24px",
                          cursor: "pointer",
                          backgroundSize: "42px" /* Space for the icon */,
                        }}
                      >
                        <option value="">Synergy Department Lead</option>
                        {usersList?.map((data, index) => (
                          <option key={data.EM_ID + index} value={data.EM_ID}>
                            {data.EM_FirstName}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        placeholder="Param"
                        className="textfield_organisation_deatils12"
                        value={getUserFirstName(customerData.SubLead)}
                        name="SubLead"
                        disabled
                        style={{ cursor: "not-allowed", color: "#A5A5A5" }}
                      />
                    )}
                  </div>
                  {errors.SubLead && (
                    <span className="error-message">
                      Please select Synergy Department Lead
                    </span>
                  )}
                </div>
              </div>
            </form>
            <div className="flex float-right gap-1">
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={() => handleTabClick(0)}
                >
                  BACK
                </button>
              </div>
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={handleNextButtonClick}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <form className="flex flex-col w-full h-auto gap-4 p-4 mx-auto mb-4 bg-white rounded-lg">
              <div className="masterd-title">
                TIME ESTIMATION OF VOUCHERS (MIN)
              </div>
              <div className="flex flex-wrap gap-4">
                {customerData?.standardActivitiesTime
                  ?.filter((timeData) =>
                    activityData?.some(
                      (item) =>
                        item.AL_Type === "Vouchers" &&
                        item?.AL_Status === "1" &&
                        timeData.SATActivity === parseInt(item.AL_ID)
                    )
                  )
                  ?.map((timeData) => {
                    const activity = activityData.find(
                      (item) => item.AL_ID === String(timeData.SATActivity)
                    );
                    const errorKey = `SATTime${timeData.SATActivity}`;
                    return (
                      <div
                        key={timeData.SATActivity}
                        className="flex flex-wrap gap-4"
                      >
                        <div>
                          <div className="master-label">
                            {activity?.AL_Name}
                          </div>
                          <div className="gap-8 w-fill h-hug">
                            <input
                              type="text"
                              className="textfield_organisation_deatils1"
                              value={timeData.SATTime || ""}
                              name={errorKey}
                              onChange={(e) =>
                                handleInputChangeVoucher(
                                  e,
                                  timeData.SATActivity
                                )
                              }
                              disabled={!isEditing}
                              style={{
                                cursor: isEditing ? "text" : "not-allowed",
                                color: isEditing ? "black" : "#A5A5A5",
                              }}
                            />
                            {errors[errorKey] && (
                              <div className="error-message">
                                {errors[errorKey]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </form>
            <div className="flex float-right gap-1">
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={() => handleTabClick(1)}
                >
                  Back
                </button>
              </div>
              <div className="modal_button_fieldmd23">
                <button
                  className="common_btnmd"
                  onClick={handleNextButtonClick}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        )}

        {activeTab === 3 && (
          <div>
            <form className="flex flex-col w-full h-auto gap-4 p-4 mx-auto mb-4 bg-white rounded-lg">
              <div className="masterd-title">
                TIME ESTIMATION OF REPORTING ACTIVITIES (MIN)
              </div>
              <div className="flex flex-wrap gap-4">
                {customerData?.standardActivitiesTime
                  ?.filter((timeData) =>
                    activityData.some(
                      (item) =>
                        item.AL_Type === "Reporting Activites" &&
                        item?.AL_Status === "1" &&
                        timeData.SATActivity === parseInt(item.AL_ID)
                    )
                  )
                  ?.map((timeData) => {
                    const activity = activityData.find(
                      (item) => item.AL_ID === String(timeData.SATActivity)
                    );
                    const errorKey = `SATTime${timeData.SATActivity}`;
                    return (
                      <div
                        key={timeData.SATActivity}
                        className="flex flex-wrap gap-4"
                      >
                        <div>
                          <div className="master-label">
                            {activity?.AL_Name}
                          </div>
                          <div className="gap-8 w-fill h-hug">
                            <input
                              type="text"
                              className="textfield_organisation_deatils1"
                              value={timeData.SATTime || ""}
                              name={errorKey}
                              onChange={(e) =>
                                handleInputChangeVoucher(
                                  e,
                                  timeData.SATActivity
                                )
                              }
                              disabled={!isEditing}
                              style={{
                                cursor: isEditing ? "text" : "not-allowed",
                                color: isEditing ? "black" : "#A5A5A5",
                              }}
                            />
                            {errors[errorKey] && (
                              <div className="error-message">
                                {errors[errorKey]}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </form>
            <div className="flex float-right gap-2">
              <div>
                <button
                  className="common_btnmd"
                  onClick={() => handleTabClick(2)}
                >
                  Back
                </button>
              </div>
              <div>
                <button className="common_btnmd" onClick={handleUpdate}>
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default CustomerMasterTabs;
