import React from "react";
import "../Styles/Dashboard_graph7.css";
import { useState, useContext, useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { Select } from "@mui/material";
import "../Styles/Dashboard.css";
import { MenuItem } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import "react-circular-progressbar/dist/styles.css";
import i1 from "../Images/Rectangle 104 (1).png";
import i2 from "../Images/Rectangle 105.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";

function Dashboard_graph_7() {
  const { token } = useContext(AuthContext);
  const { teamsData, usersList } = useContext(UserContext);
  const [filteredEmployeeList, setFilteredEmployeeList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [statusData, setStatusData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString());
  const navigate = useNavigate();
  const getAllActivityTaskStatus = async (monthString) => {
    const [year, month] = monthString.split("-");

    const lastDay = new Date(year, month, 0).getDate();
    const firstDay = "01";

    const formattedDateRange1 = `${year}-${month}-${firstDay}`;
    const formattedDateRange2 = `${year}-${month}-${lastDay
      .toString()
      .padStart(2, "0")}`;

    try {
      const response = await fetch(
        `${taskApiUrl}/GetAllActivityTaskStatus?DateRange1=${formattedDateRange1}&DateRange2=${formattedDateRange2}&AssginedToEmids=${filteredEmployeeList}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.status === 401) {
        navigate("/");
      }
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setStatusData(data);
    } catch (error) {
      console.error("Error fetching status data:", error);
    }
  };

  useEffect(() => {
    getAllActivityTaskStatus(selectedMonth);
  }, [token, filteredEmployeeList, selectedMonth]);



  const { pendingActivities, completedActivities } = statusData
  .filter((item) => item.ActivityType === "Vouchers")
  .reduce(
    (acc, item) => {
      if (item.Status === "P") {
        acc.pendingActivities += parseInt(item.StatusCount, 10);
      } else if (item.Status === "C") {
        acc.completedActivities += parseInt(item.StatusCount, 10);
      }
      return acc;
    },
    { pendingActivities: 0, completedActivities: 0 }
  );

const totalActivities = pendingActivities + completedActivities;
const percentage =
  totalActivities > 0
    ? Math.round((completedActivities / totalActivities) * 100)
    : 0;
const rem = 100 - percentage;  // No need to use Math.round as percentage is already an integer

console.log({
  totalActivities,
  pendingActivities,
  completedActivities,
  percentage,
  rem
});

  function handleMonthChange(monthString) {
    setSelectedMonth(monthString);
  }

  const handleTeamChange = (e) => {
    const value = e.target.value;
    setSelectedTeam(value);
    //console.log(value);
  };

  useEffect(() => {
    const filteredEmployees = usersList?.filter(
      (user) => user?.STM_Team == selectedTeam
    );

    setFilteredEmployeeList(
      filteredEmployees?.map((item) => `'${item["EM_ID"]}'`).join(",")
    );
  }, [selectedTeam]);

  const customStyles = {
    text: {
      fill: "orange",
      fontSize: "9px",
      dominantBaseline: "middle",
      textAnchor: "middle",
    },
    path: {
      strokeWidth: "8px",
      stroke: "url(#gradient)",
    },
  };
  return (
    <div className="graph-last-main">
      <div className="graph5-card-heading">
        <div className="g5-heading">Voucher Review</div>
        <div className="g5-dropdowns">
          {/* <input
            type="month"
            value={selectedMonth}
            onChange={(e) => handleMonthChange(e.target.value)}
            className="calstyle-for-dashboard"
          /> */}
          <input
            type="month"
            value={selectedMonth.slice(0, 7)}
            onChange={(e) => handleMonthChange(e.target.value)}
            className="calstyle-for-dashboard"
          />

          <div>
            <Select
              fullWidth
              value={selectedTeam}
              onChange={handleTeamChange}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Teams
              </MenuItem>
              {teamsData[0]
                ?.filter((team) => team?.status === true && team?.type === "T")
                ?.map((data) => (
                  <MenuItem key={data.id} value={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="g5-subhead">
        Live update of pending and completed activity
      </div>
      <div className="g5-graph">
        <div className="c-p-g">
          <svg width="0" height="0">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop
                  offset="0%"
                  style={{ stopColor: "#50195A", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#FFFFFF", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgressbar
            value={percentage}
            text={`${totalActivities} Total Activities`}
            styles={customStyles}
          />
        </div>
        <div className="g5-graph-sidetexts">
          <div className="g5-side-1">
            <img src={i2} alt="Completed Activities" className="g5-i1" />
            Completed Activities {percentage}%
          </div>
          <div className="g5-side-2">
            <img src={i1} alt="Pending Activities" className="g5-i1" />
            Pending Activities {rem}%
          </div>
        </div>
      </div>

      <div className="g-last-dash-footer">
        <div className="g-last-dash-footer-data">
          Out of {totalActivities} activities{" "}
          <span className="g5-dash-footer-spam">
            {completedActivities} activities are completed
          </span>
        </div>
      </div>
    </div>
  );
}

export default Dashboard_graph_7;
