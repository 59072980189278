import React from 'react'
import "../Styles/Commingsoon.css";
import NavMain from '../components/NavMain';
import NavSub from '../components/NavSub';

function CommingSoon() {
  return (

    <div >
    
            <NavSub />

        <div className='title-comming'>
            <div className='title-comming-style'>Under Construction ..........</div>
        </div>

        

      
    </div>
  )
}

export default CommingSoon
