import React, { useState } from 'react'
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../Styles/Organisation.css"
import "../Styles/Roles.css"
function Organisation() {
    const [status, setStatus] = useState(10);
    const handleChange = (event) => {
        setStatus(event.target.value);
      };
  return (
    <div>
          <div className='Roles_layout'>
        <div className='Roles_row_fiels'>
            <div className='Roles_row'>
                <div className='Roles_row__label'>Quality Consultant</div>
                <div className='select_box'>
                <input
            type="text"
            name="name"
            className='textfield_organisation'
            placeholder='Choose Designation'
            required
          />
                </div>
            </div>
            <div className='Roles_row'>
                <div  className='Roles_row__label'>Process Lead</div>
                <div className='select_box'>
                <input
            type="text"
            name="name"
            className='textfield_organisation'
            placeholder='Choose Role'
            required
          />
                </div>
            </div>
        </div>
        <div className='Roles_row_fiels'>
        <div className='Roles_row'>
                <div  className='Roles_row__label'>Department lead</div>
                <div className='select_box'>
                <Box sx={{ minWidth:  600}}>
                    <FormControl fullWidth >
                      <Select
                        style={{ background: "white" }}
                        value={status}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Choose Team</MenuItem>
                        <MenuItem value={20}>Scheduled</MenuItem>
                        <MenuItem value={30}>Active</MenuItem>
                        <MenuItem value={40}>Expired</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
            </div>
            <div className='Roles_row'>
                <div  className='Roles_row__label'>Manager</div>
                <div className='select_box'>
                <Box sx={{ minWidth:  600}}>
                    <FormControl fullWidth >
                      <Select
                        style={{ background: "white" }}
                        value={status}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Choose Sub Team</MenuItem>
                        <MenuItem value={20}>Scheduled</MenuItem>
                        <MenuItem value={30}>Active</MenuItem>
                        <MenuItem value={40}>Expired</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Organisation