function TimeCodeCard({
  months,
  Title,
  closeTitle,
  reopenTitle,
  activityType,
  monthlyData,
  onCheckboxChange,
}) {
  const handleChange = (month, isChecked) => {
    onCheckboxChange(activityType, month, !isChecked);
  };

  return (
    <div className="time_card_container">
      <div className="time_card_head">
        <div>{Title}</div>

        <div className="time_card_head_reopen" style={{ width: "60%" }}>
          {months?.map((month, index) => (
            <div key={index}>{month}</div>
          ))}
        </div>
      </div>
      <div className="time_card_close">
        <div className="Close_card_firld">
          <div>{closeTitle}</div>
          <div className="month_fields" style={{ width: "60%" }}>
            {months.map((month, index) => (
              <input
                key={index}
                type="checkbox"
                name={`close_${month}`}
                checked={!monthlyData[activityType][month]}
                onChange={(e) => handleChange(month, !e.target.checked)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="time_card_reopen">
        <div className="time_card_head_reopen">
          <div>{reopenTitle}</div>
          <div className="month_fields" style={{ width: "60%" }}>
            {months.map((month, index) => (
              <input
                key={index}
                type="checkbox"
                name={`reopen_${month}`}
                checked={monthlyData[activityType][month]}
                onChange={(e) => handleChange(month, e.target.checked)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimeCodeCard;
