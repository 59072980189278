import React, { useContext, useEffect, useState, useRef } from "react";
import NavSub from "../components/NavSub";
import "../Styles/WeeklyTimesheet.css";
import { AuthContext } from "../context/AuthContext";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import ToastComponent from "../components/ToastComponent";
import * as XLSX from "xlsx";
import { UserContext } from "../context/UserContext";
import { CaretDown, CaretUp, FileArrowUp } from "phosphor-react";

const StandardvsActualTimeReport = () => {
  const { usersList } = useContext(UserContext);
  const toastRef = useRef();
  const [clients, setClients] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [viewForce, setViewForce] = useState([]);
  const { userData } = useContext(AuthContext);
  const [selectedUser, setSelectedUser] = useState(userData?.[0]?.EM_ID);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [weeklyReport, setWeeklyReport] = useState([]);
  const { token, logout } = useContext(AuthContext);

  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingViewForce, setLoadingViewForce] = useState(false);
  const [loadingWeeklyReport, setLoadingWeeklyReport] = useState(false);

  useEffect(() => {
    if (selectedDate) {
      const currentDate = new Date(selectedDate);
      const firstDayOfWeek = currentDate.getDate() - currentDate.getDay() + 1;
      const lastDayOfWeek = firstDayOfWeek + 5; // Saturday is the 6th day

      const start = new Date(currentDate.setDate(firstDayOfWeek));
      const end = new Date(currentDate.setDate(lastDayOfWeek));

      const formatDate = (date) => date.toISOString().split("T")[0];

      setStartDate(formatDate(start));
      setEndDate(formatDate(end));
    }
  }, [selectedDate]);

  // const fetchActivityList = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://fn-hampi-clientsvc-dev.azurewebsites.net/api/GetAllActivity`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     if (response.status === 401) {
  //       toastRef.current.showToast(
  //         "Unauthorised access. Please login again.",
  //         "error"
  //       );
  //       await logout();
  //       return;
  //     }

  //     if (response.ok) {
  //       const data = await response.json();
  //       setActivityList([
  //         ...new Set(data?.map((code) => code.AL_SalseforceCode)),
  //       ]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchActivityList = async () => {
    setLoadingActivities(true); // Start loading
    try {
      const response = await fetch(`${clientApiUrl}/GetAllActivity`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setActivityList([
          ...new Set(
            data
              ?.filter((item) => item?.AL_Status == "1")
              ?.map((code) => code.AL_SalseforceCode)
          ),
        ]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingActivities(false); // End loading
    }
  };
  const fetchViewForce = async () => {
    try {
      const response = await fetch(
        `${taskApiUrl}/GetDataByDate?StartDate=${startDate}&EndDate=${endDate}&EMId=${selectedUser}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      if (response.ok) {
        const data = await response.json();
        setViewForce(data);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // const fetchWeekly = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://fn-hampi-taskmanager.azurewebsites.net/api/GetAllTaskClientWiseReport?DateRange1=${startDate}&DateRange2=${endDate}&AssginedToEmids='${selectedUser}'`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response.status === 401) {
  //       toastRef.current.showToast(
  //         "Unauthorised access. Please login again.",
  //         "error"
  //       );
  //       await logout();
  //       return;
  //     }
  //     const data = await response.json();
  //     setWeeklyReport(data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const fetchWeekly = async () => {
    setLoadingWeeklyReport(true);
    try {
      const response = await fetch(
        `${taskApiUrl}/GetAllTaskClientWiseReport?DateRange1=${startDate}&DateRange2=${endDate}&AssginedToEmids='${selectedUser}'`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401) {
        toastRef.current.showToast(
          "Unauthorised access. Please login again.",
          "error"
        );
        await logout();
        return;
      }
      const data = await response.json();
      setWeeklyReport(data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingWeeklyReport(false); // End loading
    }
  };

  useEffect(() => {
    if (userData && startDate) {
      fetchViewForce();
      fetchWeekly();
    }
  }, [userData, startDate, selectedUser]);

  useEffect(() => {
    fetchActivityList();
  }, []);

  const handleActivityChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedActivities(typeof value === "string" ? value.split(",") : value);
  };
  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const downloadExcel = () => {
    // Generate table data
    const { header, data } = generateReportTable(
      activityList,
      weeklyReport,
      viewForce
    );

    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert the table data to a worksheet
    const worksheetData = [
      header,
      ...data.map((row) =>
        header.map((col) => (row[col] !== undefined ? row[col] : 0))
      ),
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `WeeklyReport_${selectedUser?.EM_FirstName}`
    );

    // Write the workbook to a binary string
    const workbookBinary = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const buffer = new ArrayBuffer(workbookBinary.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < workbookBinary.length; i++) {
      view[i] = workbookBinary.charCodeAt(i) & 0xff;
    }
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    // Create a link element and trigger a download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `WeeklyReport_${selectedUser?.EM_FirstName}.xslx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Usage example: Add a button to trigger the download
  const filteredActivities =
    selectedActivities.length > 0 ? selectedActivities : activityList;
  const uniqueActivities = [...new Set(filteredActivities)];

  const tableHeader = [
    "Client",
    ...uniqueActivities.map((a) => `${a} StandardTime`),
    "Total StandardTime",
    ...uniqueActivities,
    "Total Time",
    ...uniqueActivities.map((a) => `${a} Difference`),
    "Volumes",
    "Transaction Time",
    "Average Time",
    "Difference",
  ];

  function generateReportTable(activityList, weeklyReport, viewForce) {
    const filteredActivities =
      selectedActivities.length > 0 ? selectedActivities : activityList;
    const uniqueActivities = [...new Set(filteredActivities)];

    const tableHeader = [
      "Client",
      ...uniqueActivities.map((a) => `${a} StandardTime`),
      "Total StandardTime",
      ...uniqueActivities,
      "Total Time",
      ...uniqueActivities.map((a) => `${a} Difference`),
      "Volumes",
      "Transaction Time",
      "Average Time",
      "Difference",
    ];

    const clientData = weeklyReport.reduce((acc, report) => {
      if (!acc[report.ClientName]) {
        acc[report.ClientName] = {
          clientName: report.ClientName,
          clientId: report.ClientId,
        };
      }
      return acc;
    }, {});

    const clientNames = Object.keys(clientData);

    const tableData = clientNames.map((clientName) => {
      const { clientId } = clientData[clientName];

      const clientRow = {
        clientName: clientName,
        clientId: clientId,
        activitiesTotalTime: {},
        activitiesStandardTime: {},
        activitiesDifference: {},
        volumes: 0,
        transactionTime: 0,
        averageTime: 0,
        averageDifference: 0,
        totalStandardTime: 0,
        totalTime: 0,
      };

      uniqueActivities.forEach((activity) => {
        clientRow.activitiesTotalTime[activity] = 0;
        clientRow.activitiesStandardTime[activity] = 0;
        clientRow.activitiesDifference[activity] = 0;
      });

      weeklyReport.forEach((report) => {
        if (report.ClientName === clientName) {
          // clientRow.volumes += 1;

          if (
            clientRow.activitiesTotalTime[report.SalesforceCode] !== undefined
          ) {
            clientRow.activitiesTotalTime[report.SalesforceCode] += parseFloat(
              report.TotalTime
            );
            clientRow.activitiesStandardTime[report.SalesforceCode] +=
              parseFloat(report.TotalStd);
            clientRow.totalTime += parseFloat(report.TotalTime);
            clientRow.averageTime = report.Average || 0;
            clientRow.volumes = report.TotalSalesforceCode;
          }
        }
      });

      uniqueActivities.forEach((activity) => {
        clientRow.activitiesDifference[activity] =
          clientRow.activitiesTotalTime[activity] -
          clientRow.activitiesStandardTime[activity];
      });

      clientRow.transactionTime = clientRow.totalTime/clientRow.volumes;
      clientRow.averageDifference =
        clientRow.transactionTime - clientRow.averageTime;

      clientRow.totalStandardTime = uniqueActivities.reduce(
        (sum, activity) => sum + clientRow.activitiesStandardTime[activity],
        0
      );

      return clientRow;
    });

    return {
      header: tableHeader,
      data: tableData.map((clientRow) => {
        const row = {
          Client: clientRow.clientName,
        };
        uniqueActivities.forEach((activity) => {
          row[`${activity} StandardTime`] =
            clientRow.activitiesStandardTime[activity];
          row[activity] = clientRow.activitiesTotalTime[activity];
          row[`${activity} Difference`] =
            clientRow.activitiesDifference[activity];
        });
        row["Total StandardTime"] = clientRow.totalStandardTime;
        row["Total Time"] = clientRow.totalTime;
        row["Volumes"] = clientRow.volumes;
        row["Transaction Time"] = clientRow.transactionTime;
        row["Average Time"] = clientRow.averageTime;
        row["Difference"] = clientRow.averageDifference;

        return row;
      }),
    };
  }

  const { header, data } = generateReportTable(
    activityList,
    weeklyReport,
    viewForce
  );

  const menuItemStyle = {
    height: "30px",
  };

  const getRowStyle = (index) => ({
    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "white",
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false); // State to track dropdown open/close

  const handleDropdownOpen = () => {
    setIsOpen(true);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <NavSub />
      <div className="weekly_time_sheet_head">
        <div className="space-weekly-review">
          <ToastComponent ref={toastRef} timeout={4000} />

          {/* <Select
            className="change-font"
            style={{ width: "auto" }}
            value={selectedUser}
            onChange={handleUserChange}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <div className="title-weekly-design-review">
                    SELECT USER{" "}
                    <CaretDown
                      size={16}
                      weight="fill"
                      className="icon-select-weekly"
                    />
                  </div>
                );
              }
              const selectedUserObject = usersList.find(
                (u) => u.EM_ID === selected
              );
              return selectedUserObject ? (
                selectedUserObject.EM_FirstName
              ) : (
                <div>
                  Select User{" "}
                  <CaretDown
                    size={16}
                    weight="fill"
                    className="icon-select-weekly"
                  />
                </div>
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  top: 200,
                },
              },
            }}
            IconComponent={null}
          >
        
            {usersList?.map((u) => (
              <MenuItem key={u.EM_ID} value={u.EM_ID}>
                <ListItemText primary={u.EM_FirstName} />
              </MenuItem>
            ))}
          </Select> */}

          <Select
            className="change-font"
            style={{ width: "auto" }}
            value={selectedUser}
            onChange={handleUserChange}
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return (
                  <div className="title-weekly-design-review">
                    SELECT USER{" "}
                    {isOpen ? (
                      <CaretUp
                        size={16}
                        weight="fill"
                        className="icon-select-weekly"
                      />
                    ) : (
                      <CaretDown
                        size={16}
                        weight="fill"
                        className="icon-select-weekly"
                      />
                    )}
                  </div>
                );
              }
              const selectedUserObject = usersList.find(
                (u) => u.EM_ID === selected
              );
              return selectedUserObject ? (
                <div className="user-name-standard">
                  {selectedUserObject.EM_FirstName}{" "}
                  {isOpen ? (
                    <CaretUp
                      size={16}
                      weight="fill"
                      className="icon-select-weekly"
                    />
                  ) : (
                    <CaretDown
                      size={16}
                      weight="fill"
                      className="icon-select-weekly"
                    />
                  )}
                </div>
              ) : (
                <div>
                  Select User{" "}
                  {isOpen ? (
                    <CaretUp
                      size={16}
                      weight="fill"
                      className="icon-select-weekly"
                    />
                  ) : (
                    <CaretDown
                      size={16}
                      weight="fill"
                      className="icon-select-weekly"
                    />
                  )}
                </div>
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                  top: 200,
                },
              },
            }}
            IconComponent={null} // Remove default icon
            onOpen={handleDropdownOpen} // Update state when dropdown opens
            onClose={handleDropdownClose} // Update state when dropdown closes
          >
            {usersList?.map((u) => (
              <MenuItem key={u.EM_ID} value={u.EM_ID}>
                <ListItemText primary={u.EM_FirstName} />
              </MenuItem>
            ))}
          </Select>

          <Select
            className="change-font"
            multiple
            value={selectedActivities}
            onChange={handleActivityChange}
            displayEmpty
            onOpen={handleOpen}
            onClose={handleClose}
            IconComponent={() => null}
            renderValue={(selected) => {
              return (
                <button className="title-weekly-design">
                  SELECT ACTIVITY
                  {open ? (
                    <CaretUp
                      size={16}
                      color="#3d025f"
                      weight="fill"
                      className="icon-select-weekly"
                    />
                  ) : (
                    <CaretDown
                      size={16}
                      color="#3d025f"
                      weight="fill"
                      className="icon-select-weekly"
                    />
                  )}
                </button>
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {activityList?.map((activity) => (
              <MenuItem key={activity} value={activity} sx={menuItemStyle}>
                <Checkbox checked={selectedActivities.indexOf(activity) > -1} />
                <ListItemText primary={activity} />
              </MenuItem>
            ))}
          </Select>

          <input
            type="date"
            className="date-design-weekly"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            label="Select Date"
          />
        </div>

        <div className="Nav-btn-user">
          <FileArrowUp size={25} color="#3d025f" weight="fill" />
          <button className="Nav-Sub-btn" onClick={downloadExcel}>
            DOWNLOAD FILES
          </button>
        </div>
      </div>

      {/* <div className="report-table-cont">
        <table className="report-table">
          <thead>
            <tr className="super-header">
              <th className="client-header">CLIENT</th>
              <th
                className="standard-time-header"
                colSpan={uniqueActivities.length + 1}
              >
                STANDARD TIME
              </th>
              <th
                className="total-time-header"
                colSpan={uniqueActivities.length + 1}
              >
                TOTAL TIME
              </th>
              <th
                className="difference-header"
                colSpan={uniqueActivities.length}
              >
                DIFFERENCE
              </th>
              <th className="transaction-time-header" colSpan={2}>
                TRANSACTION TIME
              </th>
              <th className="average-time-header" colSpan={2}>
                AVERAGE TIME
              </th>
            </tr>
            <tr className="activity-headers">
              {tableHeader?.map((head, index) => (
                <th
                  className="activity-header table-title-weekly"
                  key={`header-${index}`}
                >
                  {head}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr className="client-row" key={index} style={getRowStyle(index)}>
                <td className="client-name content-user">{row.Client}</td>
                {tableHeader?.slice(1)?.map((activity, activityIndex) => (
                  <td
                    className="activity-cell content-user"
                    key={`activity-${index}-${activityIndex}`}
                  >
                    <div className="activity-cell-value">{row[activity]}</div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

      <div className="report-table-cont">
        {loadingWeeklyReport ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px",
            }}
          >
            <CircularProgress style={{ color: "purple" }} width={40} />
          </div>
        ) : (
          <table className="report-table">
            <thead>
              <tr className="super-header">
                <th className="client-header">CLIENT</th>
                <th
                  className="standard-time-header"
                  colSpan={uniqueActivities.length + 1}
                >
                  STANDARD TIME
                </th>
                <th
                  className="total-time-header"
                  colSpan={uniqueActivities.length + 1}
                >
                  TOTAL TIME
                </th>
                <th
                  className="difference-header"
                  colSpan={uniqueActivities.length}
                >
                  DIFFERENCE
                </th>
                <th className="transaction-time-header" colSpan={2}>
                  TRANSACTION TIME
                </th>
                <th className="average-time-header" colSpan={2}>
                  AVERAGE TIME
                </th>
              </tr>
              <tr className="activity-headers">
                {tableHeader?.map((head, index) => (
                  <th
                    className="activity-header table-title-weekly"
                    key={`header-${index}`}
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr
                  className="client-row"
                  key={index}
                  style={getRowStyle(index)}
                >
                  <td className="client-name content-user">{row.Client}</td>
                  {tableHeader?.slice(1)?.map((activity, activityIndex) => (
                    <td
                      className="activity-cell content-user"
                      key={`activity-${index}-${activityIndex}`}
                    >
                      <div className="activity-cell-value">{row[activity]}</div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default StandardvsActualTimeReport;
