import React from 'react'
import CustomerMasterNav2 from './CustomerMasterNav2'
import CustomerMasterTabs from './CustomerMasterTabs'
import UserDeatilScreen from './UserDeatilScreen'
import NavSub from '../components/NavSub'

function UserSetUpDetial() {
  return (
    <div>
              {/* <CustomerMasterNav2 /> */}
              <NavSub />
              <UserDeatilScreen />


    </div>
  )
}

export default UserSetUpDetial