import { useState ,useEffect,useRef }from 'react'
import logo123 from "../Images/NewLogo.png"
import "../Styles/CustomerMasterNav.css";
import {useNavigate} from 'react-router-dom';
import CustomerMasterData from './CustomerMasterData';
import UserSetupAndRoles from './UserSetupAndRoles';


import   { Bell,Plus ,MagnifyingGlass ,Eye , User,UsersThree, DotOutline, FileArrowUp ,PlusCircle ,CaretDown ,CaretDoubleRight, PencilSimpleLine, Download }  from "@phosphor-icons/react";

function CustomerMasterNav() {
  const [activeItem, setActiveItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPrimaryDropdownOpen, setIsPrimaryDropdownOpen] = useState(false);
  const [isSecondaryDropdownOpen, setIsSecondaryDropdownOpen] = useState(false);
  const [isthirdDropdownOpen, setIsthirdDropdownOpen] = useState(false);
  const [isfourthDropdownOpen, setIsfourthDropdownOpen] = useState(false);
  // const [isfifthDropdownOpen, setIsfifthDropdownOpen] = useState(false);
  const [isbellDropdownOpen, setIsbellDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const dropdownRef = useRef(null);
  const dropdown2Ref = useRef(null);
  const dropdown3Ref = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsPrimaryDropdownOpen(false);
      setIsSecondaryDropdownOpen(false);
    }
  };
  // ////////////////////////////////////////
  const handleClick2Outside = (event) => {
    if (dropdown2Ref.current && !dropdown2Ref.current.contains(event.target)) {
      setIsbellDropdownOpen(false);
      // setIsSecondaryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick2Outside);
    return () => {
      document.removeEventListener('mousedown', handleClick2Outside);
    };
  }, []);
  // /////////////////////////////////////////

  const handleClick3Outside = (event) => {
    if (dropdown3Ref.current && !dropdown3Ref.current.contains(event.target)) {
      setIsthirdDropdownOpen(false);
      // setIsSecondaryDropdownOpen(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClick3Outside);
    return () => {
      document.removeEventListener('mousedown', handleClick3Outside);
    };
  }, []);
  // //////////////////////////////////////

  const togglePrimaryDropdown = () => {
    setIsPrimaryDropdownOpen(!isPrimaryDropdownOpen);
    setIsSecondaryDropdownOpen(false); // Close secondary dropdown when primary is toggled
  };

  const toggleSecondaryDropdown = () => {
    setIsSecondaryDropdownOpen(!isSecondaryDropdownOpen);
  };

  // togglethirdDropdown

  const togglethirdDropdown = () => {
    setIsthirdDropdownOpen(!isthirdDropdownOpen);
  };
  const togglefourthDropdown = () => {
    setIsfourthDropdownOpen(!isfourthDropdownOpen);
  };
  const togglebellDropdown = () => {
    setIsbellDropdownOpen(!isbellDropdownOpen);
  };

  // const togglefifthDropdown = () => {
  //   setIsfifthDropdownOpen(!isfifthDropdownOpen);
  // };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
   
  };
  return (
    <main className='main-Nav'>

    <nav className='flex justify-between px-8 items-center  h-22 nav-color '>
        <section className='flex items-center gap-5 relative'>
 <img className=' w-16 h-8 ' loading="lazy" src={logo123} alt="" />

          <div  className='flex justify-between gap-11  items-center py-6 '>

            <div className='Customer-nav'>

            <div className=' text-purple-950  font-bold text-l  font-poppins sm:text-lg '>CUSTOMER MASTER DATA  </div>
           
           <button className='' onClick={togglePrimaryDropdown}>
             <CaretDown size={16} color="#3d025f" weight="fill" />
           </button> 

            </div>
           </div>
          {isPrimaryDropdownOpen && (
            <div ref={dropdownRef} className='absolute top-3/4 left-72  mt-1 drop1 bg-white rounded-lg shadow-lg with-divider '>
              <ul className='nav-ul'>

              <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap dropdown ${activeItem === 'Voucher Processing' ? 'active1' : ''}`} onClick={() => handleItemClick('Voucher Processing')}>Voucher  Processing</li>
                <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap dropdown ${activeItem === 'Reporting Activities' ? 'active1' : ''}`} onClick={() => handleItemClick('Reporting Activities')}>Reporting Activities</li>
                <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap dropdown ${activeItem === 'Annual Accounts And Tax Reports' ? 'active1' : ''}`} onClick={() => handleItemClick('Annual Accounts And Tax Reports')}>Annual Accounts And Tax Reports</li>
                <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap dropdown ${activeItem === 'Time Report' ? 'active1' : ''}`} onClick={() => handleItemClick('Time Report')}>Time Report</li>
                <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap dropdown ${activeItem === 'Quality Control Framework' ? 'active1' : ''}`} onClick={() => handleItemClick('Quality Control Framework')}>Quality Control Framework</li>
                <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap dropdown ${activeItem === 'Management Reports' ? 'active1' : ''}`} onClick={() => handleItemClick('Management Reports')}>Management Reports</li>
                <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap flex dropdown ${activeItem === 'Admin Activities' ? 'active1' : ''}`} onClick={() => {
                  toggleSecondaryDropdown(); 
                  handleItemClick('Admin Activities');
                }}>Admin Activities  </li>
                <li className={`px-4 pt-0 cursor-pointer w-full whitespace-no-wrap dropdown ${activeItem === 'Log Out' ? 'active1' : ''}`} onClick={() => handleItemClick('Log Out')}>Log Out</li>
              </ul>

           {/* <div className='caret_d'> <CaretDoubleRight size={15} /></div> */}
             

              {isSecondaryDropdownOpen && (
                      <ul id='secondary-dropdown' className='Second-Cd'>
                        <li className='px-4 pt-0  cursor-pointer w-full whitespace-no-wrap' onClick={()=>{navigate('/CustomerMasterData')}}>Customer Master Data</li>
                        <li className='px-4 pt-0  cursor-pointer w-full whitespace-no-wrap'>View Force Data Import</li>
                        <li className='px-4 pt-0  cursor-pointer w-full whitespace-no-wrap' onClick={()=>{navigate('/UserSetupAndRoles')}}>User Setup and Roles</li>
                        {/* <li className='px-4 py-2cursor-pointer w-full whitespace-no-wrap'>Activities And Tasks</li> */}
                        <li className='px-4 pt-0  cursor-pointer w-full whitespace-no-wrap'>Budget And Forecast</li>
                        <li className='px-4 pt-0  cursor-pointer w-full whitespace-no-wrap'>Period Open/Close</li>
                        {/* <li className='px-4 py-2cursor-pointer w-full whitespace-no-wrap'>Month Roll Forward</li> */}
                        <li className='px-4 pt-0  cursor-pointer w-full whitespace-no-wrap' onClick={()=>{navigate('/LookUpPage')}}>Lookup Information</li>
                      </ul>
                    )}
            </div>
          )}
           </section>
    <section className='flex items-center '>
      <div className='rr'>
        <Bell size={18} className='bell1' color="#3d025f" weight="fill" />
        <DotOutline size={52} className='be' color="#0fdf0c"  weight="fill" />
        <button onClick={togglebellDropdown}>
              <CaretDown size={16} color="#3d025f" weight="fill" />
          </button>  

          {isbellDropdownOpen && (
        <div ref={dropdown2Ref}  className="bell-cd">
          
          <ul>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
          </ul>
        </div>
      )}

        </div>
       
{/*         
        {isbellDropdownOpen && (
        <div ref={dropdown2Ref}  className="bell-cd">
          
          <ul>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
            <li className="py-2 px-4 hover:bg-gray-100">null</li>
          </ul>
        </div>
      )} */}
 <div className='flex justify-between gap-2 pl-8 items-center py-6'>
          <User size={20} color="#3d025f" weight="fill" />
          <div className=' h-6  text-purple-950 text-l font-semibold font-poppins'>Param Reddy</div>
     <button onClick={togglethirdDropdown}>
              <CaretDown size={16} color="#3d025f" weight="fill" />
              {isthirdDropdownOpen && (
        <div  ref={dropdown3Ref} className="third-cd">
          <ul>
            <li className="py-2 px-4 hover:bg-gray-100">Notification</li>
            <li className="py-2 px-4 hover:bg-gray-100">Settings</li>
            <li className="py-2 px-4 hover:bg-gray-100">Log Out</li>
          </ul>
        </div>
      )}
          </button>    
          </div>
   </section>  
    </nav>
    <nav className='  flex justify-between px-8 pb-4 items-center  h-22 nav-color border-b shadow-lg nav-color1'>

        <section className='flex items-center gap-6'>
            <div className='titel11c'>
                <MagnifyingGlass size={20} color="#ada4a4" weight="fill" />
                <input type="text" className='title111' id="searchInput" placeholder= "Search by Customer Id , Customer name" />
            </div>
         {/* <div className='btn-edit'>
                <PencilSimpleLine size={20} color="#3d025f" weight="fill" />
               <button className='btn-e'>EDIT</button>
            </div> */}
        </section>
          <section className='flex items-center gap-4'>
              <div className='btn-edit1'>
                {/* <PencilSimpleLine size={20} color="#3d025f" weight="fill" /> */}
                <Download size={35} color="#3d025f" weight="fill" />
               <button className='btn-e12'>IMPORT FILES</button>
            </div>
           <div>
            <button  className='font-poppins w-44 h-9 flex items-center text-center justify-center mx-auto text-white rounded-lg bg-purple-950'>
          <PlusCircle size={20} color="#FFFFFF" weight="fill" className="mr-2" /> 
          <span className=" aaa1" onClick={() => navigate('/AddClient')}>ADD NEW CUSTOMER</span>
        </button>  
     </div>
          </section>
    </nav>
</main>
    
  )
}
export default CustomerMasterNav;








// import React from 'react';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

// // CustomerData component - Replace this with your actual CustomerData component
// const CustomerData = () => {
//   return <h1>Customer Data Page</h1>;
// };

// // Navigation component
// const Navigation = () => {
//   return (
//     <Router>
//       <div>
//         <ul className='Second-Cd'>
//           <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>
//             <Link to="/customer-data">Customer Master Data</Link>
//           </li>
//           <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>
//             <Link to="/view-force-data-import">View Force Data Import</Link>
//           </li>
//           <li className='px-4 py-2 hover:bg-gray-100 cursor-pointer w-full whitespace-no-wrap'>
//             <Link to="/user-setup-and-roles">User Setup and Roles</Link>
//           </li>
//           {/* Add more list items as needed */}
//         </ul>

//         <hr />

//         <Route path="/customer-data" component={CustomerData} />
//         {/* Define routes for other pages similarly */}
//       </div>
//     </Router>
//   );
// };

// export default Navigation;



