import React from 'react'
import "../Styles/ConnectActivity.css";


import Userwise1 from '../components/Userwise1';
import Userwise2 from '../components/Userwise2';
import Userwise3 from '../components/Userwise3';

function Connectuser() {
  return (
    <div className='merge-activity'>

     <Userwise1 />
      <Userwise2 />
      <Userwise3 />
        
        

      
    </div>
  )
}

export default Connectuser;