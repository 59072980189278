import React, { useContext, useEffect, useState, useRef } from "react";
import "../Styles/WeekelyTimesheet2.css";
import "../Styles/WeekelyTimesheet1.css";
import "../Styles/ContentWeek.css";
import { UserContext } from "../context/UserContext";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "./ToastComponent";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function Weeklytimesheet2({ selectedYear, selectedMonth }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [latestData, setLatestData] = useState();
  const [error, setError] = useState(null);
  const { token, logout } = useContext(AuthContext);
  const toastRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      const startDate = `${selectedYear}-${selectedMonth
        .toString()
        .padStart(2, "0")}-01`;

      const endDate = new Date(selectedYear, selectedMonth, 0); // The 0th day of the next month gives the last day of the current month
      const endDateString = `${selectedYear}-${selectedMonth
        .toString()
        .padStart(2, "0")}-${endDate.getDate()}`;
      try {
        setLoading(true);
        const response = await fetch(
          `${taskApiUrl}/GetDataByDate?StartDate=${startDate}&EndDate=${endDateString}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        setData(
          json.map((item) => ({
            details: {
              label1: item.costCenter || "",
              label2: item.activity || "",
              label3: item.fullName || "",
              label4: item.timeId || "",
              label5: item.weekNumber.toString() || "",
              label6: item.dateIncurred || "",
              label7: item.registeredTime.toString() || "",
              label8: item.billableTime.toString() || "",
              label9: item.mainPriceAgreement || "",
              label10: item.activityPriceAgreement || "",
              label11: item.mainActivity || "",
              label12: item.description || "",
              label13: item.status || "",
              label14: calculateControlCheck(item),
              label15: checkSTR(item.activity, item.mainPriceAgreement),
              label17: check10Hrs(item.registeredTime, item.billableTime),
              label18: checkHourlyRate(
                item.activityPriceAgreement,
                item.activity,
                item.registeredTime,
                item.billableTime
              ),
              label19: checkRTBT(item.registeredTime, item.billableTime),
              label20: checkMainActivity(item.mainActivity),
              label21: checkComments(item.activity, item.description),
              label22: checkApproval(item.dateIncurred, item.status),
              label16: checkAdhoc(item.projectTaskName, item.accountName),
            },
          }))
        );
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    const fetchLatestData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${taskApiUrl}/GetLatestViewForceData`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        const formattedLatestData = json.map((item) => ({
          details: {
            label1: item.costCenter,
            label2: item.activity,
            label3: item.fullName,
            label4: item.timeId,
            label5: item.weekNumber.toString(),
            label6: item.dateIncurred,
            label7: item.registeredTime.toString(),
            label8: item.billableTime.toString(),
            label9: item.mainPriceAgreement,
            label10: item.activityPriceAgreement,
            label11: item.mainActivity,
            label12: item.description,
            label13: item.status,
            label14: calculateControlCheck(item),
            label15: checkSTR(item.activity, item.mainPriceAgreement),
            label17: check10Hrs(item.registeredTime, item.billableTime),
            label18: checkHourlyRate(
              item.mainPriceAgreement,
              item.activity,
              item.registeredTime,
              item.billableTime
            ),
            label19: checkRTBT(item.registeredTime, item.billableTime),
            label20: checkMainActivity(item.mainActivity),
            label21: checkComments(item.activity, item.description),
            label22: checkApproval(item.dateIncurred, item.status),
            label16: checkAdhoc(item.projectTaskName, item.accountName),
          },
        }));
        setLatestData(formattedLatestData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching latest data:", error);
        setError(error);
        setLoading(false);
      }
    };

    if (selectedYear && selectedMonth) {
      fetchData();
    } else {
      fetchLatestData();
    }
  }, [selectedYear, selectedMonth]);
  const calculateControlCheck = (item) => {
    const isOk =
      checkSTR(item.activity, item.mainPriceAgreement) === "Ok" &&
      check10Hrs(item.registeredTime, item.billableTime) === "Ok" &&
      checkHourlyRate(
        item.activityPriceAgreement,
        item.activity,
        item.registeredTime,
        item.billableTime
      ) === "Ok" &&
      checkRTBT(item.registeredTime, item.billableTime) === "Ok" &&
      checkMainActivity(item.mainActivity) === "Ok" &&
      checkComments(item.activity, item.description) === "Ok" &&
      checkApproval(item.dateIncurred, item.status) === "Ok" &&
      checkAdhoc(item.projectTaskName, item.accountName) === "Ok";
    return isOk ? "Ok" : "Check";
  };
  const checkSTR = (activity, mainPriceAgreement) => {
    const targetActivity = "0801 Financial statement and tax report";
    const targetPriceAgreement = "Fixed Price";
    return activity === targetActivity &&
      mainPriceAgreement !== targetPriceAgreement
      ? "Check"
      : "Ok";
  };
  const check10Hrs = (registeredTime, billableTime) => {
    if (
      registeredTime > 10 ||
      billableTime > 10 ||
      registeredTime < 0 ||
      billableTime < 0
    ) {
      return "Check";
    } else {
      return "Ok";
    }
  };
  const checkHourlyRate = (
    activityPriceAgreement,
    activity,
    registeredTime,
    billableTime
  ) => {
    if (
      activityPriceAgreement === "Hourly Rate" &&
      !activity.includes("0801 Financial statement and tax report") &&
      registeredTime === 0 &&
      billableTime !== 0
    ) {
      return "Check";
    } else {
      return "Ok";
    }
  };
  const checkRTBT = (registeredTime, billableTime) => {
    if (registeredTime === 0 && billableTime !== 0) {
      return "Check";
    } else {
      return "Ok";
    }
  };
  const checkMainActivity = (mainActivity) => {
    if (!mainActivity) {
      return "Ok";
    } else if (mainActivity === "Outsourcing" || mainActivity === "Internal") {
      return "Ok";
    } else {
      return "Check";
    }
  };
  const checkComments = (activity, description) => {
    if (
      (activity === "0406 Consulting - Financial" &&
        description.trim() === "") ||
      (activity === "0501 Consulting - Administration" &&
        description.trim() === "")
    ) {
      return "Check";
    } else {
      return "Ok";
    }
  };
  const checkApproval = (dateIncurred, status) => {
    if (!dateIncurred) {
      return "Ok";
    } else if (
      dateIncurred &&
      (status === "Approved" || status === "Invoiced")
    ) {
      return "Ok";
    } else {
      return "Check";
    }
  };

  const checkAdhoc = (projectTaskName, accountName) => {
    if (projectTaskName && accountName) {
      return "Ok";
    }
    return "Check";
  };
  return (
    <div className="main-newcontent">
      <div className="Content-week22">
        <ToastComponent ref={toastRef} timeout={4000} />
        <div className="slider-container22">
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel112">COST CENTRE</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">ACTIVITY</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">FULL NAME</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">TIME ID</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">WEEK NO</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">DATE INCURRED</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">REGISTERED TIME</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">BILLABLE TIME</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">MAIN PRICE AGREEMENT</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">ACTIVITY PRICE AGREEMENT</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">MAIN ACTIVITY</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">DESCRIPTION</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">STATUS</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">CONTROL CHECK</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">STR CHECK 1</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">10 HRS CHECK 2</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">HOURLY RATE CHECK 3</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">RT-BT CHECK 4</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">MAIN ACTIVITY CHECK 5</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">COMMENTS CHECK 6</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">APPROVAL CHECK 7</div>
          </div>
          <div className="Content-w21" style={{ minWidth: "330px" }}>
            <div className="Content-weeklabel11">ADHOC CHECK 8</div>
          </div>
        </div>
        {selectedYear || selectedMonth
          ? data?.map((item, index) => (
              <div
                key={index}
                className={
                  index % 2 === 0 ? "Content2-week2" : "Content2-week22"
                }
              >
                <div className="slider2-container">
                  {Object.entries(item.details).map(([key, value]) => (
                    <div
                      className="Content2-w21"
                      key={key}
                      style={{ minWidth: "330px" }}
                    >
                      <div
                        style={{
                          color:
                            value === "Ok"
                              ? "green"
                              : value === "Check"
                              ? "orange"
                              : "",
                        }}
                        className="Content2-weeklabel11"
                      >
                        {value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          : latestData.map((item, index) => (
              <div
                key={index}
                className={
                  index % 2 === 0 ? "Content2-week2" : "Content2-week22"
                }
              >
                <div className="slider2-container">
                  {Object.entries(item.details).map(([key, value]) => (
                    <div
                      className="Content2-w21"
                      key={key}
                      style={{ minWidth: "330px" }}
                    >
                      <div
                        style={{
                          color:
                            value === "Ok"
                              ? "green"
                              : value === "Check"
                              ? "orange"
                              : "",
                        }}
                        className="Content2-weeklabel11"
                      >
                        {value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}
export default Weeklytimesheet2;
