import React, { useState, useEffect, useContext, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import ToastComponent from "./ToastComponent";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function WeekActivityHistory({ setSelectedHistoryDetailData }) {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token, logout } = useContext(AuthContext);
  const [error, setError] = useState(null);

  const toastRef = useRef();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${taskApiUrl}/GetViewForceDataHistory`, {
          headers: {
            Authorization: `Bearer ${token}`, // Assuming token is defined or passed in
          },
        });
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (!response.ok) {
          throw new Error("Failed to fetch history data");
        }
        const data = await response.json();
        setHistoryData(data);
        setLoading(false); // Update loading state after data is fetched
      } catch (error) {
        console.error("Error fetching history data:", error);
        setLoading(false); // Make sure to set loading to false on error
      }
    };

    fetchData();
  }, []);

  const generateEmptyRows = (numRows) => {
    const emptyRows = [];
    for (let i = 0; i < numRows; i++) {
      emptyRows.push({
        projectTaskName: "",
        accountName: "",
        org_Number: "",
      });
    }
    return emptyRows;
  };

  const numRowsToAdd = 19;
  const emptyRows = generateEmptyRows(numRowsToAdd);

  if (loading) {
    return <CircularProgress />; // Show loading indicator while fetching data
  }
  if (error) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="main-newcontent11">
      <div className="Content-week2211">
        <ToastComponent ref={toastRef} timeout={4000} />
        <div className="slider-container2211">
          <div className="Content-w21">
            <div className="Content-weeklabel123">PROJECT NAME</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel1213">ACCOUNT NAME</div>
          </div>
          <div className="Content-w21">
            <div className="Content-weeklabel1213">ORG NUMBER</div>
          </div>
        </div>
        {historyData.map((item, index) => (
          <div
            className={index % 2 === 0 ? "Content2-week2" : "Content2-week22"}
            key={index}
          >
            <div className="slider2-container">
              <div className="Content2-w21">
                <div className="Content2-weeklabel123">
                  {item.projectTaskName}
                </div>
              </div>
              <div className="Content2-w21">
                <div className="Content2-weeklabel111">{item.accountName}</div>
              </div>
              <div className="Content2-w21">
                <div className="Content2-weeklabel111">{item.org_Number}</div>
              </div>
            </div>
          </div>
        ))}
        {emptyRows.map((item, index) => (
          <div
            className={index % 2 === 0 ? "Content2-week2" : "Content2-week22"}
            key={index}
          >
            <div className="slider2-container">
              <div className="Content2-w21">
                <div className="Content2-weeklabel123">
                  {item.projectTaskName}
                </div>
              </div>
              <div className="Content2-w21">
                <div className="Content2-weeklabel111">{item.accountName}</div>
              </div>
              <div className="Content2-w21">
                <div className="Content2-weeklabel111">{item.org_Number}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WeekActivityHistory;
