import {useState} from 'react';
import { Line } from 'react-chartjs-2';
import {Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement, Filler} from 'chart.js';
ChartJS.register(
  Title, Tooltip, LineElement, Legend,
  CategoryScale, LinearScale, PointElement, Filler
)

function GraphPage() {
  const [data, setData]= useState({
    labels:["Jan","Feb", "March", "April", "May", "June", "July"],
    datasets:[
      {
        label:"Data",
        data:[40, 120, 30, 82, 151, 82, 31],
        backgroundColor: 'rgba(80, 20, 90, 0.7)', 
        borderColor:'rgba(80, 20, 90, 0.7)',
        tension:0.4,
        fill:true,
        pointStyle:'none',
        pointBorderColor:'rgba(80, 20, 90, 0.7)',
        pointBackgroundColor:'rgba(80, 20, 90, 0.7)',
        showLine:true
      }
    ]
  })
  const options = {
    scales: {
      x: {
        grid: {
          display: false 
        }
      },
      y: {
        grid: {
          display: false 
        },
        min: 20, 
        max: 160, 
        ticks: {
          stepSize: 20 
        }
      }
    }
  };
  const [profile, setProfile] = useState(false);
  return (
    <div className="App" style={{width:'800px', margin:'50px', height:'800px',backgroundColor: 'transparent'}}>
      <Line data={data} options={options}></Line>
    </div>
    // <>
    // <div style={{background:'red', width:'100%', height:'700px', display:'flex'}}>
    //     <div style={{width:'100%', minHeight:'500px', background:'green'}}>
            
    //     </div>
    //     {profile&&<div style={{width:'300px', minHeight:'500px', background:'blue'}}></div>}
    // </div>
    //     <button onClick={()=>{setProfile(!profile)}}>Toggle</button>
    // </>
  );
}

export default GraphPage;