import React, { useEffect, useState, useContext, useRef } from "react";
import CustomerMasterTabs from "../pages/CustomerMasterTabs";
import { AuthContext } from "../context/AuthContext";
import "../Styles/CustomerData2.css";
// import CustomerMasterNav2 from './CustomerMasterNav2';
import { useParams } from "react-router-dom";
import NavSub from "../components/NavSub";
import ToastComponent from "../components/ToastComponent";
import {
  clientApiUrl,
  taskApiUrl,
  notificationApiUrl,
  userApiUrl,
} from "../utils/GetUrl";
function CustomerData2() {
  const toastRef = useRef();
  const { id } = useParams();
  const [customerData, setCustomerData] = useState(null);
  const { token, logout } = useContext(AuthContext);
  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await fetch(`${clientApiUrl}/GetAllClient?id=${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 401) {
          toastRef.current.showToast(
            "Unauthorised access. Please login again.",
            "error"
          );
          await logout();
          return;
        }
        if (response.ok) {
          const data = await response.json();
          //console.log(data);
          //console.log(id);
          setCustomerData(data);
        } else {
          console.error("Failed to fetch customer data");
        }
      } catch (error) {
        //console.log(error);
      }
    };
    fetchCustomerData();
  }, [id]);

  return (
    <div className="customer-page2">
      <NavSub />
      <CustomerMasterTabs customerData={customerData} id={id} />
    </div>
  );
}

export default CustomerData2;
